import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

export const InputSuffix = styled.span`
  align-self: center;
  padding-left: 4px;
`;

export const SetPointHeader = styled(Header)`
  text-wrap: pretty;
`;

export const InputWrapper = styled.span`
  min-width: 100px;
`;

export const DateWrapper = styled.span`
  width: 140px;
`;

export const FieldInputWrapper = styled.div`
  display: flex;
  flex: 2;
  height: 100%;
  align-items: center;
  gap: 12px;
`;

export const FieldLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-inline: 15px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;

  > span {
    text-wrap: balance;
    font-size: 12px;
    text-align: center;
    width: min-content;
  }
`;

export const ScheduleRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr 1fr 1fr 1fr;
  gap: 16px;
  align-items: center;
`;

export const LoadControllerInputGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;
