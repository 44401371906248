import { useContext } from 'react';
import { AuthContext } from '../../../../contexts';
import { EquipmentDto, EquipmentResponse, EquipmentService } from '../../../../services/equipment-service';

const useEquipmentService = () => {
  const { atkaToken } = useContext(AuthContext);

  return {
    addEquipment: async (equipment: EquipmentDto): Promise<EquipmentResponse> => {
      const addedEquipment = await EquipmentService.addEquipment({
        equipment,
        token: atkaToken,
      });

      return addedEquipment;
    },
  };
};

export default useEquipmentService;
