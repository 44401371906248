import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Header, Responsive } from 'semantic-ui-react';
import { LocationSchedule } from 'services/schedule-service';
import styled from 'styled-components';

import { useScheduleService } from './hooks/useScheduleService';
import { ContentWrapper, DropdownWrapper } from './index';
import { OccupiedHoursWeekday } from './occupied-hours-weekday';
import {
    FieldLabelWrapper,
    FieldWrapper,
    InputContainerBody,
    ListLabelWrapper,
    ListTextWrapper,
} from './styles';

export interface updatedSchedule {
    startTime: string;
    endTime: string;
    state: string;
    entityUUID: string;
    entityType: string;
    type: string;
    day: number;
    metadata: {};
    timezone: string;
    companyUUID: string;
}

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: baseline;
    gap: 20px;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-self: stretch;
    }
`;

const OccupiedHoursFormContainer = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px 40px 15px 40px;
    gap: 40px;
    justify-content: space-evenly;
    margin-bottom: 15px;
`;

const SCHEDULE_LENGTH = 7;

export const OccupiedHoursForm = ({
    companyUuid,
    locationSchedules,
    monitoringLocationUuid,
}: {
    companyUuid: string;
    locationSchedules: LocationSchedule;
    monitoringLocationUuid: string;
}) => {
    const [mondayStartTime, setMondayStartTime] = useState('closed');
    const [mondayEndTime, setMondayEndTime] = useState('closed');
    const [tuesdayStartTime, setTuesdayStartTime] = useState('closed');
    const [tuesdayEndTime, setTuesdayEndTime] = useState('closed');
    const [wednesdayStartTime, setWednesdayStartTime] = useState('closed');
    const [wednesdayEndTime, setWednesdayEndTime] = useState('closed');
    const [thursdayStartTime, setThursdayStartTime] = useState('closed');
    const [thursdayEndTime, setThursdayEndTime] = useState('closed');
    const [fridayStartTime, setFridayStartTime] = useState('closed');
    const [fridayEndTime, setFridayEndTime] = useState('closed');
    const [saturdayStartTime, setSaturdayStartTime] = useState('closed');
    const [saturdayEndTime, setSaturdayEndTime] = useState('closed');
    const [sundayStartTime, setSundayStartTime] = useState('closed');
    const [sundayEndTime, setSundayEndTime] = useState('closed');

    const [mondayUUID, setMondayUUID] = useState('');
    const [tuesdayUUID, setTuesdayUUID] = useState('');
    const [wednesdayUUID, setWednesdayUUID] = useState('');
    const [thursdayUUID, setThursdayUUID] = useState('');
    const [fridayUUID, setFridayUUID] = useState('');
    const [saturdayUUID, setSaturdayUUID] = useState('');
    const [sundayUUID, setSundayUUID] = useState('');

    /*
    The following state variables will essentially be used as flags to stop
    the schedule from being reset if it runs into a different schedule for the
    same day. For example, if initialschedules[0].day === 1 AND initialschedules[1].day === 1, 
    then initialSchedules[1] will be the schedule that gets set which is NOT what
    we want since initialSchedules[0] will always be the more recent schedule. With the current
    implementation, this should never be the case because unused schedules get deleted/updated 
    when we don't use them, but since there is potential in the future for the schedule
    service to be used in different ways, this will safeguard against using the wrong schedule
    for a given day.
  */

    const [mondayUpdated, setMondayUpdated] = useState(false);
    const [tuesdayUpdated, setTuesdayUpdated] = useState(false);
    const [wednesdayUpdated, setWednesdayUpdated] = useState(false);
    const [thursdayUpdated, setThursdayUpdated] = useState(false);
    const [fridayUpdated, setFridayUpdated] = useState(false);
    const [saturdayUpdated, setSaturdayUpdated] = useState(false);
    const [sundayUpdated, setSundayUpdated] = useState(false);

    const [timeZone, setTimeZone] = useState('');
    const scheduleService = useScheduleService();

    const timezones = useMemo(
        () =>
            moment.tz
                .names()
                .filter(
                    (tzName) =>
                        tzName.includes('US') || tzName.includes('Canada'),
                )
                .map((tzName) => ({
                    text: `(GMT${moment
                        .tz(tzName)
                        .format('Z')}) ${tzName} (${moment
                        .tz(tzName)
                        .zoneAbbr()})`,
                    value: tzName,
                })),
        [],
    );

    /*
    This function sets the initial state of the page 
    with the existing data from the back end (locationSchedules). 
    The function only loops through the first 7 schedules 
    received from the back end, as those will always be the most 
    recently updated schedules and there are only 7 days in a 
    week. 
  */

    const setInitialSchedule = (locationSchedules: LocationSchedule[]) => {
        if (locationSchedules.length >= 1) {
            // Only execute if locationSchedules has already been populated

            for (let i = 0; i < SCHEDULE_LENGTH; i++) {
                if (!locationSchedules[i]) {
                    //If there are fewer than 7 schedules, quit the loop
                    break;
                } else if (locationSchedules[i].day === 1 && !mondayUpdated) {
                    setMondayStartTime(locationSchedules[i].startTime);
                    setMondayEndTime(locationSchedules[i].endTime);
                    setMondayUUID(locationSchedules[i].uuid);
                    setTimeZone(locationSchedules[i].timezone);
                    setMondayUpdated(true);
                } else if (locationSchedules[i].day === 2 && !tuesdayUpdated) {
                    setTuesdayStartTime(locationSchedules[i].startTime);
                    setTuesdayEndTime(locationSchedules[i].endTime);
                    setTuesdayUUID(locationSchedules[i].uuid);
                    setTimeZone(locationSchedules[i].timezone);
                    setTuesdayUpdated(true);
                } else if (
                    locationSchedules[i].day === 3 &&
                    !wednesdayUpdated
                ) {
                    setWednesdayStartTime(locationSchedules[i].startTime);
                    setWednesdayEndTime(locationSchedules[i].endTime);
                    setWednesdayUUID(locationSchedules[i].uuid);
                    setTimeZone(locationSchedules[i].timezone);
                    setWednesdayUpdated(true);
                } else if (locationSchedules[i].day === 4 && !thursdayUpdated) {
                    setThursdayStartTime(locationSchedules[i].startTime);
                    setThursdayEndTime(locationSchedules[i].endTime);
                    setThursdayUUID(locationSchedules[i].uuid);
                    setTimeZone(locationSchedules[i].timezone);
                    setThursdayUpdated(true);
                } else if (locationSchedules[i].day === 5 && !fridayUpdated) {
                    setFridayStartTime(locationSchedules[i].startTime);
                    setFridayEndTime(locationSchedules[i].endTime);
                    setFridayUUID(locationSchedules[i].uuid);
                    setTimeZone(locationSchedules[i].timezone);
                    setFridayUpdated(true);
                } else if (locationSchedules[i].day === 6 && !saturdayUpdated) {
                    setSaturdayStartTime(locationSchedules[i].startTime);
                    setSaturdayEndTime(locationSchedules[i].endTime);
                    setSaturdayUUID(locationSchedules[i].uuid);
                    setTimeZone(locationSchedules[i].timezone);
                    setSaturdayUpdated(true);
                } else if (locationSchedules[i].day === 7 && !sundayUpdated) {
                    setSundayStartTime(locationSchedules[i].startTime);
                    setSundayEndTime(locationSchedules[i].endTime);
                    setSundayUUID(locationSchedules[i].uuid);
                    setTimeZone(locationSchedules[i].timezone);
                    setSundayUpdated(true);
                }
            }
        }
    };

    const onSetSchedule = async () => {
        const scheduleUpdateSuccess = (message: string) =>
            toast.success(message, { autoClose: 5000, position: 'top-center' });

        const scheduleUpdateFail = (message: string) =>
            toast.warn(message, { autoClose: 5000, position: 'top-center' });

        /*
      These schedules are templates for what we will pass
      to the schedule enpoints to create/update schedules.
      Each schedule corresponds to a different day of the
      week (1 = monday, 2 = tuesday, 3 = wednesday, etc...).
    */

        const COMMON_SCHEDULE_OPTIONS = {
            companyUUID: companyUuid,
            entityType: 'location',
            entityUUID: monitoringLocationUuid,
            metadata: {},
            state: 'occupied',
            timezone: timeZone,
            type: 'weekly',
        };

        const schedules = [
            {
                ...COMMON_SCHEDULE_OPTIONS,
                day: 1,
                endTime: mondayEndTime,
                startTime: mondayStartTime,
            },
            {
                ...COMMON_SCHEDULE_OPTIONS,
                day: 2,
                endTime: tuesdayEndTime,
                startTime: tuesdayStartTime,
            },
            {
                ...COMMON_SCHEDULE_OPTIONS,
                day: 3,
                endTime: wednesdayEndTime,
                startTime: wednesdayStartTime,
            },
            {
                ...COMMON_SCHEDULE_OPTIONS,
                day: 4,
                endTime: thursdayEndTime,
                startTime: thursdayStartTime,
            },
            {
                ...COMMON_SCHEDULE_OPTIONS,
                day: 5,
                endTime: fridayEndTime,
                startTime: fridayStartTime,
            },
            {
                ...COMMON_SCHEDULE_OPTIONS,
                day: 6,
                endTime: saturdayEndTime,
                startTime: saturdayStartTime,
            },
            {
                ...COMMON_SCHEDULE_OPTIONS,
                day: 7,
                endTime: sundayEndTime,
                startTime: sundayStartTime,
            },
        ];

        /*
      In this loop, if the start time is closed AND a UUID
      exists for that day (meaning it hasn't been deleted yet),
      then we delete the schedule for that day.

      If the start time is NOT closed AND there is already a UUID
      for that day, then we update that day's schedule.

      If the start time is NOT closed AND there is NOT a UUID
      for that day, then we post a new schedule for that day
      and update the UUID.
    */

        for (const schedule of schedules) {
            if (schedule.startTime === 'closed') {
                switch (schedule.day) {
                    case 1: {
                        if (mondayUUID !== '') {
                            scheduleService.deleteSchedule(mondayUUID);
                            setMondayUUID('');
                        }
                        break;
                    }

                    case 2: {
                        if (tuesdayUUID !== '') {
                            scheduleService.deleteSchedule(tuesdayUUID);
                            setTuesdayUUID('');
                        }
                        break;
                    }

                    case 3: {
                        if (wednesdayUUID !== '') {
                            scheduleService.deleteSchedule(wednesdayUUID);
                            setWednesdayUUID('');
                        }
                        break;
                    }

                    case 4: {
                        if (thursdayUUID !== '') {
                            scheduleService.deleteSchedule(thursdayUUID);
                            setThursdayUUID('');
                        }
                        break;
                    }

                    case 5: {
                        if (fridayUUID !== '') {
                            scheduleService.deleteSchedule(fridayUUID);
                            setFridayUUID('');
                        }
                        break;
                    }

                    case 6: {
                        if (saturdayUUID !== '') {
                            scheduleService.deleteSchedule(saturdayUUID);
                            setSaturdayUUID('');
                        }
                        break;
                    }

                    case 7: {
                        if (sundayUUID !== '') {
                            scheduleService.deleteSchedule(sundayUUID);
                            setSundayUUID('');
                        }
                        break;
                    }
                }
            } else if (schedule.startTime !== 'closed') {
                switch (schedule.day) {
                    case 1: {
                        if (mondayUUID !== '') {
                            scheduleService.updateSchedule(
                                schedule,
                                mondayUUID,
                            );
                            break;
                        } else if (mondayUUID === '') {
                            const { data } = await scheduleService.addSchedule(
                                schedule,
                            );
                            setMondayUUID(data.data.uuid);
                            break;
                        }
                    }
                    case 2: {
                        if (tuesdayUUID !== '') {
                            scheduleService.updateSchedule(
                                schedule,
                                tuesdayUUID,
                            );
                            break;
                        } else if (tuesdayUUID === '') {
                            const { data } = await scheduleService.addSchedule(
                                schedule,
                            );
                            setTuesdayUUID(data.data.uuid);
                            break;
                        }
                    }
                    case 3: {
                        if (wednesdayUUID !== '') {
                            scheduleService.updateSchedule(
                                schedule,
                                wednesdayUUID,
                            );
                            break;
                        } else if (wednesdayUUID === '') {
                            const { data } = await scheduleService.addSchedule(
                                schedule,
                            );
                            setWednesdayUUID(data.data.uuid);
                            break;
                        }
                    }
                    case 4: {
                        if (thursdayUUID !== '') {
                            scheduleService.updateSchedule(
                                schedule,
                                thursdayUUID,
                            );
                            break;
                        } else if (thursdayUUID === '') {
                            const { data } = await scheduleService.addSchedule(
                                schedule,
                            );
                            setThursdayUUID(data.data.uuid);
                            break;
                        }
                    }
                    case 5: {
                        if (fridayUUID !== '') {
                            scheduleService.updateSchedule(
                                schedule,
                                fridayUUID,
                            );
                            break;
                        } else if (fridayUUID === '') {
                            const { data } = await scheduleService.addSchedule(
                                schedule,
                            );
                            setFridayUUID(data.data.uuid);
                            break;
                        }
                    }
                    case 6: {
                        if (saturdayUUID !== '') {
                            scheduleService.updateSchedule(
                                schedule,
                                saturdayUUID,
                            );
                            break;
                        } else if (saturdayUUID === '') {
                            const { data } = await scheduleService.addSchedule(
                                schedule,
                            );
                            setSaturdayUUID(data.data.uuid);
                            break;
                        }
                    }
                    case 7: {
                        if (sundayUUID !== '') {
                            scheduleService.updateSchedule(
                                schedule,
                                sundayUUID,
                            );
                            break;
                        } else if (sundayUUID === '') {
                            const { data } = await scheduleService.addSchedule(
                                schedule,
                            );
                            setSundayUUID(data.data.uuid);
                            break;
                        }
                    }
                }
            }
        }

        if (timeZone === '') {
            scheduleUpdateFail('Please select a time zone');
        } else {
            scheduleUpdateSuccess('Schedule successfully updated');
        }
    };

    const handleOnChangeWith = (handlerFn) => {
        return (e, data) => {
            if (data.value) {
                handlerFn(data.value);
            }
        };
    };

    useMemo(() => {
        setInitialSchedule(locationSchedules);
    }, [locationSchedules]);

    return (
        <>
            <OccupiedHoursFormContainer>
                <ContentWrapper>
                    <ListLabelWrapper>
                        <ListTextWrapper>
                            <Header size="tiny">Occupied hours</Header>
                        </ListTextWrapper>
                    </ListLabelWrapper>
                    <InputContainer>
                        <InputContainerBody>
                            <FieldWrapper>
                                <FieldLabelWrapper>
                                    <Header as="h5">Time zone:</Header>
                                </FieldLabelWrapper>
                                <DropdownWrapper>
                                    <Dropdown
                                        options={timezones.map(
                                            ({ text, value }) => ({
                                                key: value,
                                                text,
                                                value,
                                            }),
                                        )}
                                        search
                                        clearable
                                        required
                                        placeholder="Select Time Zone"
                                        name="time zone"
                                        selection
                                        fluid
                                        value={timeZone}
                                        onChange={handleOnChangeWith(
                                            setTimeZone,
                                        )}
                                    />
                                </DropdownWrapper>
                            </FieldWrapper>
                            <OccupiedHoursWeekday
                                setStartTime={setMondayStartTime}
                                setEndTime={setMondayEndTime}
                                header={'Monday'}
                                startTime={mondayStartTime}
                                endTime={mondayEndTime}
                                headerMargin={'34px'}
                            />
                            <OccupiedHoursWeekday
                                setStartTime={setTuesdayStartTime}
                                setEndTime={setTuesdayEndTime}
                                header={'Tuesday'}
                                startTime={tuesdayStartTime}
                                endTime={tuesdayEndTime}
                                headerMargin={'34px'}
                            />
                            <OccupiedHoursWeekday
                                setStartTime={setWednesdayStartTime}
                                setEndTime={setWednesdayEndTime}
                                header={'Wednesday'}
                                startTime={wednesdayStartTime}
                                endTime={wednesdayEndTime}
                                headerMargin={'5px'}
                            />
                            <OccupiedHoursWeekday
                                setStartTime={setThursdayStartTime}
                                setEndTime={setThursdayEndTime}
                                header={'Thursday'}
                                startTime={thursdayStartTime}
                                endTime={thursdayEndTime}
                                headerMargin={'25px'}
                            />
                            <OccupiedHoursWeekday
                                setStartTime={setFridayStartTime}
                                setEndTime={setFridayEndTime}
                                header={'Friday'}
                                startTime={fridayStartTime}
                                endTime={fridayEndTime}
                                headerMargin={'50px'}
                            />
                            <OccupiedHoursWeekday
                                setStartTime={setSaturdayStartTime}
                                setEndTime={setSaturdayEndTime}
                                header={'Saturday'}
                                startTime={saturdayStartTime}
                                endTime={saturdayEndTime}
                                headerMargin={'25px'}
                            />
                            <OccupiedHoursWeekday
                                setStartTime={setSundayStartTime}
                                setEndTime={setSundayEndTime}
                                header={'Sunday'}
                                startTime={sundayStartTime}
                                endTime={sundayEndTime}
                                headerMargin={'40px'}
                            />
                        </InputContainerBody>
                        <div style={{ display: 'flex', gap: '35px' }}>
                            <Button fluid color="green" onClick={onSetSchedule}>
                                Save
                            </Button>
                        </div>
                    </InputContainer>
                </ContentWrapper>
            </OccupiedHoursFormContainer>
            <br />
        </>
    );
};
