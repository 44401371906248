import React, { FunctionComponent } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

interface DeleteModalProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    text: string;
}

export const DeleteModal: FunctionComponent<DeleteModalProps> = ({
    open,
    onCancel,
    onConfirm,
    text,
}) => {
    return (
        <Modal open={open}>
            <Modal.Content>
                <Modal.Description>
                    <Header>{text}</Header>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    content="Confirm"
                    labelPosition="right"
                    icon="trash"
                    onClick={onConfirm}
                    negative
                />
            </Modal.Actions>
        </Modal>
    );
};
