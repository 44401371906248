import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import { AuthContext } from '../contexts';
import { UserService } from '../services';
import { StoreContext } from './store-context';

const UserSchema = new schema.Entity(
    'user',
    {},
    {
        idAttribute: 'uuid',
    },
);

const UserListSchema = [UserSchema];

export function useUserService() {
    const { dispatch } = useContext(StoreContext);
    const { token } = useContext(AuthContext);

    async function browseUsers(): Promise<void> {
        const users = await UserService.browse(token);
        const normalized = normalize(users, UserListSchema);
        dispatch({
            data: normalized,
            type: 'user:set',
        });
    }

    async function addUser(email: string): Promise<void> {
        const user = await UserService.add(email, token);
        const normalized = normalize([user], UserListSchema);
        dispatch({
            data: normalized,
            type: 'user:add',
        });
    }

    async function deleteUser(uuid: string): Promise<void> {
        const data = await UserService.delete(uuid, token);
        dispatch({
            data: uuid,
            type: 'user:delete',
        });
        return data;
    }

    return {
        browseUsers,
        addUser,
        deleteUser,
    };
}
