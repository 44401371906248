import { useEffect, useState, useMemo } from 'react';
import { SetPointMetadata } from 'services/energy-device-service';

import { EnergyLocation } from '../../../../services/energy-location-service';
import useEnergyDeviceService, { EnergyDevice } from './useEnergyDeviceService';

const NUMBER_OF_RETRIES = 5;

export const useEnergyDevices = (energyLocation: EnergyLocation | null) => {
    const { getDevicesByEnergyLocation } = useEnergyDeviceService();

    const [energyDevices, setEnergyDevices] = useState<EnergyDevice[] | null>(
        null,
    );
    const [energyDevicesLoading, setEnergyDevicesLoading] = useState<boolean>(
        false,
    );

    const getEnergyDevices = async (energyLocationUUID: string) => {
        setEnergyDevicesLoading(true);
        let devices = null;

        for (let i = 0; i < NUMBER_OF_RETRIES; i++) {
            const backoff = 1000 * 2 ** i;

            try {
                devices = await getDevicesByEnergyLocation(energyLocationUUID);
            } catch (error) {
                console.log(
                    `Error fetching devices, retrying in ${backoff /
                        1000} seconds`,
                );
            }

            if (devices) {
                break;
            }

            await new Promise((res) => setTimeout(res, backoff));
        }

        setEnergyDevices(devices);
        setEnergyDevicesLoading(false);
    };

    useEffect(() => {
        if (!energyLocation) {
            return;
        }
        const { uuid } = energyLocation;
        getEnergyDevices(uuid);
    }, [energyLocation?.uuid]);

    return { energyDevices, loading: energyDevicesLoading, setEnergyDevices };
};
