// https://docs.google.com/spreadsheets/d/1BAVBOzHLz9ZbaKG_HJ-wgGYmAf0a13vn4FklyAPCXhQ/edit#gid=1515546505
// value = vendorId
export enum TotsEquipmentType {
    refrigerator = 'refrigerator',
    freezer = 'freezer',
}
export interface TotsDevices {
    key: string;
    text: string;
    value: string;
    equipmentType: TotsEquipmentType;
    deployment: string[];
    equipment_uuid: string;
}

export interface TotsEquipment {
    uuid: string;
    type: string;
}

export const RealTots: TotsDevices[] = [
    {
        key: 'TOTS_Test Device - Folsom Office HQ',
        text: 'TOTS_Test Device - Folsom Office HQ',
        value: 'a84041d17185b228',
        equipment_uuid: '07183172-a1c7-46f1-b649-03e365090fb1',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production', 'development'],
    },
    {
        key: 'Test Kitchen WIF TOTS',
        text: 'Test Kitchen WIF TOTS',
        value: 'a84041b9b185b22b',
        equipmentType: TotsEquipmentType.freezer,
        equipment_uuid: '07183172-a1c7-46f1-b649-03e365090fb1',
        deployment: ['production', 'development'],
    },
    {
        key: 'Test Kitchen WIC TOTS',
        text: 'Test Kitchen WIC TOTS',
        value: 'a84041a16185b22a',
        equipment_uuid: 'b75efeb8-2278-497e-a382-f7c1545bfce4',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production', 'development'],
    },
    {
        key: 'TOTS_204 - Bay Point WIF',
        text: 'TOTS_204 - Bay Point WIF',
        value: 'a8404145f185b3bb',
        equipment_uuid: '869d5d10-da8c-4dad-a9f6-12a1fb1976a5',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
    {
        key: 'TOTS_102 - Bay Point WIC',
        text: 'TOTS_102 - Bay Point WIC2',
        value: 'a8404165c185b3c1',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_101 - Contra Costa WIC',
        text: 'TOTS_101 - Contra Costa WIC',
        value: 'a840414ce182c282',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_105 - Lone Tree WIC',
        text: 'TOTS_105 - Lone Tree WIC',
        value: 'a84041dd2185b229',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_201 - Lone Tree WIF',
        text: 'TOTS_201 - Lone Tree WIF',
        value: 'a84041bd7185b3c3',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
    {
        key: 'TOTS_104 - Deer Valley WIC',
        text: 'TOTS_104 - Deer Valley WIC',
        value: 'a84041a3e185b3bf',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_202 - Deer Valley WIF',
        text: 'TOTS_202 - Deer Valley WIF',
        value: 'a8404143e185b3bc',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
    {
        key: 'TOTS_106 - Martinez WIC',
        text: 'TOTS_106 - Martinez WIC',
        value: 'a8404139d185b3c2',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_205 - Martinez WIF',
        text: 'TOTS_205 - Martinez WIF',
        value: 'a84041964185b3c7',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
    {
        key: 'TOTS_108 - Pittsburg WIC',
        text: 'TOTS_108 - Pittsburg WIC',
        equipment_uuid: '',
        value: 'a8404149d185b3c9',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_203 - Pittsburg WIF',
        text: 'TOTS_203 - Pittsburg WIF',
        equipment_uuid: '',
        value: 'a84041776185b3c4',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
    {
        key: 'TOTS_206 - Oakley WIF',
        text: 'TOTS_206 - Oakley WIF',
        value: 'a84041136185b3ba',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
    {
        key: 'TOTS_211 - Oakley WIC',
        text: 'TOTS_211 - Oakley WIC',
        value: 'a840416bd185b3c5',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_103 - Hilltop WIC',
        text: 'TOTS_103 - Hilltop WIC',
        value: 'a84041732185b3cc',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_207 - Hilltop WIF',
        text: 'TOTS_207 - Hilltop WIF',
        value: 'a84041f75185b3c8',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
    {
        key: 'TOTS_109 - San Ramon WIC',
        text: 'TOTS_109 - San Ramon WIC',
        value: 'a84041f91185b3c6',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_209 - San Ramon WIF',
        text: 'TOTS_209 - San Ramon WIF',
        value: 'a8404172e185b3b9',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
    {
        key: 'TOTS_107 - Antioch WIC',
        text: 'TOTS_107 - Antioch WIC',
        value: 'a840412e5185b3bd',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_212 - Alcosta WIC',
        text: 'TOTS_212 - Alcosta WIC',
        value: 'a84041341185b3c0',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.refrigerator,
        deployment: ['production'],
    },
    {
        key: 'TOTS_208 - Alcosta WIF',
        text: 'TOTS_208 - Alcosta WIF',
        value: 'a84041a84185b3be',
        equipment_uuid: '',
        equipmentType: TotsEquipmentType.freezer,
        deployment: ['production'],
    },
];

export const RealTotsEquipment: TotsEquipment[] = [
    {
        // equipment_uuid (WIC (refrigerator) or WIF (freezer))
        uuid: 'b75efeb8-2278-497e-a382-f7c1545bfce4',
        type: TotsEquipmentType.refrigerator,
    },
    {
        uuid: '07183172-a1c7-46f1-b649-03e365090fb1',
        type: TotsEquipmentType.freezer,
    },
];
