import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
    Button,
    Container,
    Dimmer,
    Dropdown,
    Form,
    Loader,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { StyledHeader } from '../..';
import { AuthContext } from '../../../contexts';
import {
    fetchLocationsAndCompanies,
    generateSavings,
    getSignedURL,
    saveBill,
} from '../../../services/energy-billing-service';
import {
    Bill,
    Company,
    EnergyAndMonitoringData,
    MonitoringLocation,
    RawBill,
} from './types';
import { roundToTwo } from '../../../utils/round';

const StyledContainer = styled(Container)`
    padding: 0 0 0 0;

    @media only screen and (max-width: 783px) {
        padding: 15% 10%;
    }

    @media only screen and (max-width: 558px) {
        padding: 20% 10%;
    }

    @media only screen and (max-width: 481px) {
        padding: 25% 10%;
    }
`;

const LoaderContainer = styled.div`
    min-height: 100%;
    width: 100%;
    position: relative;
`;

const successCode = 201;

export const EnergyBillingFormPage: FunctionComponent<{}> = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loaderText, setLoaderText] = useState('');

    const [companies, setCompanies] = useState<Company[]>([]);
    const [locations, setLocations] = useState<MonitoringLocation[]>([]);
    const [locationsStore, setLocationsStore] = useState<MonitoringLocation[]>(
        [],
    );
    const [key, setKey] = useState<number | string>('fileupload');
    const [disabled, setDisabled] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date | null>();
    const { idToken } = useContext(AuthContext);
    const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(
        null,
    );
    const [csvOutputUrl, setCsvOutputUrl] = useState(null);
    const { register, handleSubmit, reset, setValue } = useForm<Bill>();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const splitFileName = file.name.split('.');
            const fileExtension = splitFileName[splitFileName.length - 1];
            if (fileExtension.toLowerCase() === 'csv') {
                setFile(file);
                setDisabled(true);
            } else {
                alert('Please upload a CSV file');
                setFile(null);
                setDisabled(false);
                setKey(Date.now());
            }
        }
    };
    const handleFileRemove = () => {
        setFile(null);
        setDisabled(false);
    };

    useEffect(() => {
        const getCompanies = async () => {
            setLoaderText('Loading companies and locations');
            setIsLoading(true);
            const length = 0;
            const result = await fetchLocationsAndCompanies(idToken);
            // Ensuring each location has a company
            const allLocations = result.filter(
                (location: EnergyAndMonitoringData) =>
                    location.monitoringLocation &&
                    location.monitoringLocation.company,
            );

            // Merge energy object and monitoring location object
            const locations: MonitoringLocation[] = allLocations
                .filter((location: EnergyAndMonitoringData) =>
                    location.monitoringLocation && location.energyLocation
                        ? true
                        : false,
                )
                .map((location: EnergyAndMonitoringData) => ({
                    ...location.monitoringLocation,
                    ...location.energyLocation,
                }));

            // Gets company from each location, verifies company is not undefined, and companies aren't duplicated
            const companies = allLocations
                .map((location: EnergyAndMonitoringData) => {
                    if (
                        location.monitoringLocation &&
                        location.monitoringLocation.company
                    ) {
                        return location.monitoringLocation.company;
                    }
                })
                .filter(
                    (company: Company | undefined): company is Company =>
                        !!company,
                )
                .filter((company, index, self) => {
                    return (
                        index ===
                        self.findIndex(
                            (otherCompany) => otherCompany.id === company.id,
                        )
                    );
                });

            if (companies.length > length && locations.length > length) {
                setLocationsStore(locations);
                setCompanies(companies);
                setLocations(locations);
            }
            setIsLoading(false);
        };
        getCompanies();
    }, []);

    const handleCompanyChange = (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: string },
    ) => {
        setSelectedCompany(data.value);
        const locations = locationsStore;
        const filteredLocations = locations.filter((location) => {
            return location.companyUUID === data.value;
        });
        setSelectedLocation(null);
        setLocations(filteredLocations);
    };

    const handleLocationChange = (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: string },
    ) => {
        setSelectedLocation(data.value);
    };

    const handleReset = () => {
        setSelectedDate(null);
        setSelectedLocation(null);
        setSelectedCompany(null);
        setFile(null);
        setCsvOutputUrl(null);
        reset();
    };

    const onSubmit = async (bill: RawBill) => {
        const index = 0;
        let payload;
        if (selectedLocation && selectedDate) {
            setIsLoading(true);
            setLoaderText('Saving bill');
            const billDate = selectedDate
                .toISOString()
                .substring(index, selectedDate.toISOString().indexOf('T'));

            if (file) {
                payload = {
                    locationUUID: selectedLocation,
                    billDate: billDate,
                    file: file,
                };
            } else {
                payload = {
                    actualBillAmount: parseFloat(bill.actualBillAmount),
                    actualEnergyConsumptionKwh: parseFloat(
                        bill.actualEnergyConsumptionKwh,
                    ),
                    billDate: billDate,
                    expectedBillAmount: parseFloat(bill.expectedBillAmount),
                    expectedEnergyConsumptionKwh: parseFloat(
                        bill.expectedEnergyConsumptionKwh,
                    ),
                    expectedElectricityBillAmount: parseFloat(
                        bill.expectedElectricityBillAmount,
                    ),
                    actualElectricityBillAmount: parseFloat(
                        bill.actualElectricityBillAmount,
                    ),

                    expectedDemandKW: parseFloat(bill.expectedDemandKW),
                    actualDemandKW: parseFloat(bill.actualDemandKW),

                    expectedGasConsumptionTH: parseFloat(
                        bill.expectedGasConsumptionTH,
                    ),
                    actualGasConsumptionTH: parseFloat(
                        bill.actualGasConsumptionTH,
                    ),

                    expectedGasBillAmount: parseFloat(
                        bill.expectedGasBillAmount,
                    ),
                    actualGasBillAmount: parseFloat(bill.actualGasBillAmount),
                    locationUUID: selectedLocation,
                };
            }

            const response = await saveBill(idToken, payload);
            setIsLoading(false);

            if (Array.isArray(response)) {
                toast.warn('Something went wrong with the request!');
            } else if (response.status === successCode) {
                handleReset();
                toast.success('🦄 Successfully created bill!');
            } else {
                toast.warn('Something went wrong!');
            }
        }
    };

    const handleGenerateSavings = async () => {
        const index = 0;

        if (selectedCompany && selectedLocation && selectedDate && file) {
            setIsLoading(true);
            setLoaderText('Generating savings documents');

            const billDate = selectedDate
                .toISOString()
                .substring(index, selectedDate.toISOString().indexOf('T'));

            const payload = {
                companyUUID: selectedCompany,
                locationUUID: selectedLocation,
                billDate: billDate,
                file: file,
            };

            const response = await generateSavings(idToken, payload);

            setIsLoading(false);

            if (Array.isArray(response)) {
                if (response.length) {
                    toast.warn(response[0]);
                } else {
                    toast.warn('Something went wrong with the request!');
                }
            } else if (response.status === successCode || true) {
                setFile(null);
                toast.success('🦄 Successfully processed bill!');

                setCsvOutputUrl(response.data.csvSavingsURLSigned);

                setValue(
                    'actualBillAmount',
                    roundToTwo(response.data.actualBillAmount),
                );
                setValue(
                    'expectedBillAmount',
                    roundToTwo(response.data.expectedBillAmount),
                );

                setValue(
                    'actualEnergyConsumptionKwh',
                    roundToTwo(response.data.actualEnergyConsumptionKWH),
                );
                setValue(
                    'expectedEnergyConsumptionKwh',
                    roundToTwo(response.data.expectedEnergyConsumptionKWH),
                );

                setValue(
                    'expectedElectricityBillAmount',
                    roundToTwo(response.data.expectedElectricityBillAmount),
                );
                setValue(
                    'actualElectricityBillAmount',
                    roundToTwo(response.data.actualElectricityBillAmount),
                );

                setValue(
                    'expectedDemandKW',
                    roundToTwo(response.data.expectedDemandKW),
                );
                setValue(
                    'actualDemandKW',
                    roundToTwo(response.data.actualDemandKW),
                );

                setValue(
                    'expectedGasConsumptionTH',
                    roundToTwo(response.data.expectedGasConsumptionTH),
                );
                setValue(
                    'actualGasConsumptionTH',
                    roundToTwo(response.data.actualGasConsumptionTH),
                );

                setValue(
                    'expectedGasBillAmount',
                    roundToTwo(response.data.expectedGasBillAmount),
                );
                setValue(
                    'actualGasBillAmount',
                    roundToTwo(response.data.actualGasBillAmount),
                );

                const signedSavingsUrl = await getSignedURL(
                    idToken,
                    response.data.uuid,
                );
                try {
                    const billingHtml = await httpGet(signedSavingsUrl?.data);
                    const billingDivContainer = document.getElementById(
                        'billing-container',
                    );
                    if (billingDivContainer) {
                        billingDivContainer.innerHTML = billingHtml;
                    }
                } catch (err) {
                    toast.warn('Error while displaying results document');
                }
            } else {
                toast.warn('Something went wrong!');
            }
        }
    };

    return (
        <LoaderContainer>
            {isLoading && (
                <Dimmer
                    active
                    inverted
                    style={{ position: 'absolute', zIndex: 1 }}
                >
                    <Loader>{loaderText}</Loader>
                </Dimmer>
            )}
            <StyledContainer>
                <StyledHeader as="h1">Energy Billing Form</StyledHeader>
                <Container>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Field>
                            <label>Company</label>
                            <Dropdown
                                placeholder="Select a company"
                                fluid
                                clearable
                                search
                                selection
                                required
                                value={selectedCompany}
                                options={companies.map((company) => ({
                                    key: company.uuid,
                                    text: company.name,
                                    value: company.uuid,
                                }))}
                                onChange={handleCompanyChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Location</label>
                            <Dropdown
                                placeholder="Select a location"
                                fluid
                                search
                                selection
                                required
                                value={selectedLocation}
                                options={locations.map((location) => ({
                                    key: location.uuid,
                                    text: location.name,
                                    value: location.uuid,
                                }))}
                                onChange={handleLocationChange}
                                disabled={!selectedCompany}
                            />
                        </Form.Field>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Date</label>
                                <DatePicker
                                    required
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    customInput={
                                        <input ref={register} name="date" />
                                    }
                                />
                            </Form.Field>
                        </Form.Group>

                        <hr />

                        <Form.Field>
                            <label>Upload Customer Bills Information</label>
                            <input
                                type="file"
                                key={key}
                                onChange={handleFileChange}
                                accept=".csv"
                                disabled={
                                    !selectedCompany ||
                                    !selectedLocation ||
                                    !selectedDate
                                }
                            />
                            {file && (
                                <button
                                    type="button"
                                    onClick={handleFileRemove}
                                >
                                    Remove File
                                </button>
                            )}
                        </Form.Field>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <hr style={{ flex: 1 }} />
                            <span style={{ margin: '0 10px' }}>or</span>
                            <hr style={{ flex: 1 }} />
                        </div>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Expected total bill amount ($) *</label>
                                <input
                                    required={!disabled}
                                    disabled={disabled}
                                    type="number"
                                    name="expectedBillAmount"
                                    step="0.01"
                                    ref={register}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Actual total bill amount ($) *</label>
                                <input
                                    required={!disabled}
                                    disabled={disabled}
                                    type="number"
                                    name="actualBillAmount"
                                    step="0.01"
                                    ref={register}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>
                                    Expected energy consumption (kWh) *
                                </label>
                                <input
                                    required={!disabled}
                                    disabled={disabled}
                                    type="number"
                                    name="expectedEnergyConsumptionKwh"
                                    ref={register}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Actual energy consumption (kWh) *</label>
                                <input
                                    required={!disabled}
                                    disabled={disabled}
                                    type="number"
                                    name="actualEnergyConsumptionKwh"
                                    ref={register}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>
                                    Expected Electricity Bill Amount ($) *
                                </label>
                                <input
                                    required={!disabled}
                                    disabled={disabled}
                                    type="number"
                                    step="0.01"
                                    name="expectedElectricityBillAmount"
                                    ref={register}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>
                                    Actual Electricity Bill Amount ($) *
                                </label>
                                <input
                                    required={!disabled}
                                    disabled={disabled}
                                    type="number"
                                    step="0.01"
                                    name="actualElectricityBillAmount"
                                    ref={register}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Expected Demand (kW)</label>
                                <input
                                    disabled={disabled}
                                    type="number"
                                    name="expectedDemandKW"
                                    ref={register}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Actual Demand (kW)</label>
                                <input
                                    disabled={disabled}
                                    type="number"
                                    name="actualDemandKW"
                                    ref={register}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Expected Gas Consumption (thm)</label>
                                <input
                                    disabled={disabled}
                                    type="number"
                                    name="expectedGasConsumptionTH"
                                    ref={register}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Actual Gas Consumption (thm)</label>
                                <input
                                    disabled={disabled}
                                    type="number"
                                    name="actualGasConsumptionTH"
                                    ref={register}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Expected Gas Bill Amount ($)</label>
                                <input
                                    disabled={disabled}
                                    type="number"
                                    name="expectedGasBillAmount"
                                    step="0.01"
                                    ref={register}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Actual Gas Bill Amount ($)</label>
                                <input
                                    disabled={disabled}
                                    type="number"
                                    name="actualGasBillAmount"
                                    step="0.01"
                                    ref={register}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Button type="submit" disabled={!selectedLocation}>
                            Save
                        </Button>
                        <Button type="button" onClick={handleReset}>
                            Cancel
                        </Button>

                        <Button
                            type="button"
                            disabled={
                                !selectedCompany ||
                                !selectedDate ||
                                !selectedLocation ||
                                !file
                            }
                            onClick={handleGenerateSavings}
                        >
                            Process Bill
                        </Button>

                        {csvOutputUrl && (
                            <a href={csvOutputUrl} download>
                                <Button type="button">
                                    Download Summary CSV
                                </Button>
                            </a>
                        )}
                    </Form>
                </Container>
                <div
                    style={{ paddingBottom: '40px' }}
                    id="billing-container"
                ></div>
            </StyledContainer>
        </LoaderContainer>
    );
};

// https://stackoverflow.com/a/65110844/4110257
function httpGet(theUrl: string) {
    let xmlhttp;

    if (window.XMLHttpRequest) {
        // code for IE7+, Firefox, Chrome, Opera, Safari
        xmlhttp = new XMLHttpRequest();
    } else {
        // code for IE6, IE5
        // eslint-disable-next-line no-undef
        xmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
    }

    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            return xmlhttp.responseText;
        }
    };
    xmlhttp.open('GET', theUrl, false);
    xmlhttp.send();

    return xmlhttp.response;
}
