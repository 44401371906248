import { ButtonContainer } from '@coinspect/ui';
import uniqueId from 'lodash/uniqueId';
import React, { FC, useContext } from 'react';
import {
    Button,
    Header,
    Icon,
    Modal,
    Responsive,
    SemanticCOLORS,
    SemanticICONS,
} from 'semantic-ui-react';
import styled, { createGlobalStyle } from 'styled-components';

import { ModalContext } from '../contexts';
import { StoreContext } from '../store';
interface WrappedModalProps {
    bgcolor?: string;
    modalPadding?: string;
}

export type ButtonEmphasis = 'primary' | 'secondary';

export interface BaseModalButtons {
    content: string;
    onClick?: (...args: any[]) => void | Promise<void>;
    icon?: SemanticICONS;
    color?: SemanticCOLORS;
    emphasis?: ButtonEmphasis;
}

export interface BaseModalProps {
    buttons?: BaseModalButtons[]; // array of buttons
    content?: string | JSX.Element; // content of modal
    header?: string | JSX.Element; // header of modal || else element not rendered
    subheader?: string | JSX.Element; // subheader of modal || else element not rendered
    isOpen?: boolean; // open modal
    size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
    closeIcon?: boolean;
    disableButtonsOnQuery?: string;
    padding?: 'standard' | 'none';
    hasModalActions?: boolean; // show modal buttons or not. you might have your own buttons in the content
    padSize?: string;
    fullScreenOnMobile?: boolean;
    onClose?: () => void;
}

const MobileModalStyle = createGlobalStyle`
  @media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px ){
    .ui.page.dimmer {
      padding: 0;
      .ui.modal {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        border-radius: 0;
        padding: 14px;
      }
    }
  }
`;

const ModalCloseIcon = styled(Icon)`
    && {
        @media only screen and (max-width: ${Responsive.onlyMobile
                .maxWidth}px) {
            margin-top: 25px;
        }

        position: absolute;
        right: 0;
        margin: 30px;
        cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
        color: #959ca7;
    }
`;

const WrappedModal = styled(Modal)<WrappedModalProps>`
    && {
        padding: ${({ padsize }) => padsize};
        ${({ modalpadding }) =>
            modalpadding === 'none' &&
            ' &.ui.modal, &.ui.modal > .content { padding: 0; } '}
    }
`;

const mappedButtons = (
    allButtons: BaseModalButtons[],
    disableButtonsOnQuery: string,
) => {
    const { store } = useContext(StoreContext);
    const { isQueryInProgress } = store.pages;

    return allButtons
        .map((button: BaseModalButtons) => {
            const { content, emphasis, ...rest } = button;
            const buttonProps = {
                ...rest,
                [emphasis || 'primary']: true,
            };
            return (
                <Button
                    key={uniqueId(`${content}_`)}
                    disabled={isQueryInProgress[disableButtonsOnQuery]}
                    // do not show loading for secondary buttons (i.e. cancel)
                    loading={
                        emphasis !== 'secondary' &&
                        isQueryInProgress[disableButtonsOnQuery]
                    }
                    {...buttonProps}
                >
                    {button.icon && <Icon name={button.icon} />}
                    {content}
                </Button>
            );
        })
        .reverse();
};

export const BaseModal: FC<BaseModalProps> = (props) => {
    const { hideModal } = useContext(ModalContext);
    const { store } = useContext(StoreContext);
    const { isQueryInProgress } = store.pages;
    const defaultButton = {
        content: 'OK',
        key: 'OK',
        onClick: () => {
            hideModal();
        },
    };

    const {
        buttons = [defaultButton],
        closeIcon,
        onClose = null,
        content = 'This is a default content. Can accept string or React Element',
        header = null,
        subheader = null,
        isOpen,
        padding = 'standard',
        size,
        hasModalActions = true,
        padSize,
        fullScreenOnMobile = false,
        disableButtonsOnQuery = '',
    } = props;

    const CloseIconActions = () => {
        hideModal();
        onClose && onClose();
    };

    return (
        <>
            {fullScreenOnMobile && <MobileModalStyle />}
            <WrappedModal
                open={isOpen}
                size={size}
                modalpadding={padding}
                padsize={padSize}
            >
                {closeIcon && (
                    <ModalCloseIcon
                        className="icon-close"
                        aria-hidden="true"
                        disabled={isQueryInProgress[disableButtonsOnQuery]}
                        onClick={CloseIconActions}
                    />
                )}
                {header && (
                    <Header as="h2">
                        {header}
                        {subheader && (
                            <Header.Subheader>{subheader}</Header.Subheader>
                        )}
                    </Header>
                )}
                <Modal.Content className="break-word">{content}</Modal.Content>
                {hasModalActions && (
                    <Modal.Actions>
                        <ButtonContainer>
                            {mappedButtons(buttons, disableButtonsOnQuery)}
                        </ButtonContainer>
                    </Modal.Actions>
                )}
            </WrappedModal>
        </>
    );
};
