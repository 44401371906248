/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonContainer, FormInput } from '@coinspect/ui';
import React, {
    ChangeEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import {
    Button,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownItem,
    DropdownItemProps,
    DropdownMenu,
    DropdownProps,
    Form,
    Icon,
    Input,
} from 'semantic-ui-react';
import Socket from 'simple-websocket';
import styled from 'styled-components';

import {
    LimitedContainer,
    ListContainer,
    ListItem,
    RightPaddedDiv,
    StyledHeader,
} from '..';
import { SensorDisplayList } from '../../components/devices';
import {
    AddGatewayAssetForm,
    EditGatewayAssetForm,
} from '../../components/forms';
import { ModalContext } from '../../contexts/modal-context';
import {
    availableUpdateType,
    forUpdateType,
    GatewayAssetModel,
} from '../../services';
import {
    StoreContext,
    useGatewayAssetsService,
    useUpdateGateway,
} from '../../store';
import { LazyLoad } from '../lazy-load';
import { EmptyGateway } from './empty-gateway';

const TableCell = styled(RightPaddedDiv)`
    text-align: center;
`;

const StyledButtonContainer = styled(ButtonContainer)`
    padding: 20px 0px 0px;
`;
const ToggleableIcon = styled(Icon)`
    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;
interface GatewayAssetForm {
    uuid: string;
    name: string;
    vendor: string;
    gatewayId: string;
    simId: string;
}

enum ChirpstackStatus {
    ONLINE = 'online',
    OFFLINE = 'offline',
}

export const GatewayAssetsPage: FunctionComponent<{}> = () => {
    const { store, dispatch } = useContext(StoreContext);
    const { gatewayAssets } = store.entities;
    const { showUpdateModal, showDeleteModal } = useUpdateGateway();
    const {
        addGateway,
        resetGateways,
        getAllAvailableUpdates,
        checkIfUniqueGatewayName,
        checkIfUniqueGatewayId,
        updateGatewayState,
        loadGatewaysByPage,
        searchByKeyword,
        waitForUpdatedGateways,
        getUpdatingGateways,
    } = useGatewayAssetsService();
    const [selectedGateways, setSelectedGateways] = useState<{
        [key: string]: GatewayAssetModel;
    }>({});
    const [currentTotal, setCurrentTotal] = useState(0);
    const [pageData, setPageData] = useState({ page: 0, limit: 10, total: -1 });
    const [loadingMoreData, setLoadingMoreData] = useState(false);
    const formMethods = useForm<GatewayAssetForm>({
        mode: 'onChange',
    });
    const [gatewayStatuses, setGatewayStatuses] = useState({});

    const onSubmit = formMethods.handleSubmit(async (data) => {
        const gatewayAssetModel = data as GatewayAssetModel;
        const { name, gatewayId } = data;

        const uniqueGatewayId = await checkIfUniqueGatewayId(gatewayId);
        if (!uniqueGatewayId) {
            formMethods.setError('gatewayId', 'noDuplicateID');
        }
        const uniqueName = await checkIfUniqueGatewayName(name);
        if (!uniqueName) {
            formMethods.setError('name', 'noDuplicateName');
        }
        if (!uniqueGatewayId || !uniqueName) {
            return;
        }
        try {
            await addGateway(gatewayAssetModel);
            formMethods.reset();
        } catch (err) {
            dispatch({
                type: 'OPEN_MODAL',
                dimmer: 'inverted',
                title: 'Error',
                message: `Something went wrong while adding gateway: ${err.data.message}`,
                error: true,
            });
        }
    });

    const handleCheckboxClick = (
        gatewayUuid: string,
        { checked }: CheckboxProps,
    ) => {
        if (checked) {
            const finalSelectedGateways = {
                ...selectedGateways,
                [gatewayUuid]: gatewayAssets.byUUID[gatewayUuid],
            };
            setSelectedGateways({
                ...finalSelectedGateways,
            });
            return;
        }
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            [gatewayUuid]: gatewayUuidToExclude,
            ...finalSelectedGateways
        } = selectedGateways;
        setSelectedGateways({
            ...finalSelectedGateways,
        });
    };

    const handleUpdateClick = async () => {
        const [gatewaysToUpdate, excludedGatewaysName] = await Object.values(
            selectedGateways,
        ).reduce(
            async (acc, curr) => {
                const resolvedAcc = await acc;
                if (
                    curr.availableUpdate &&
                    curr.chirpstackStatus === ChirpstackStatus.ONLINE
                ) {
                    return [
                        [
                            ...resolvedAcc[0],
                            {
                                gatewayId: curr.gatewayId,
                                updateUuid: curr.selectedUpdate,
                                uuid: curr.uuid,
                            },
                        ],
                        resolvedAcc[1],
                    ] as [forUpdateType[], string[]];
                }
                return [resolvedAcc[0], [...resolvedAcc[1], curr.name]] as [
                    forUpdateType[],
                    string[],
                ];
            },
            ([[], []] as unknown) as Promise<[forUpdateType[], string[]]>,
        );
        showUpdateModal(gatewaysToUpdate, excludedGatewaysName);
    };

    const loadMoreGateways = async (isVisible?: boolean) => {
        if (!isVisible || currentTotal === pageData.total) {
            return;
        }
        const { page, limit } = pageData;
        setLoadingMoreData(true);

        const { gateways, meta } = await loadGatewaysByPage(
            page + 1,
            limit,
            searchKeyword,
        );

        if (!gateways.length) {
            setLoadingMoreData(false);
            setPageData(meta);
            return;
        }

        const gatewaysWithPossibleUpdate = gateways.reduce((acc, curr) => {
            if (!curr.firmwareVersion || curr.firmwareVersion < '1.3.6') {
                return acc;
            }
            acc.push(curr);
            return acc;
        }, [] as Array<GatewayAssetModel>);
        await getAllAvailableUpdates(gatewaysWithPossibleUpdate);

        setCurrentTotal(currentTotal + gateways.length);
        setLoadingMoreData(false);
        setPageData(meta);
        setSearchKeyword(searchKeyword);
    };

    const { openModal } = useContext(ModalContext);

    const handleOnSelectUpdate = (
        gateway: GatewayAssetModel,
        { value: uuid }: DropdownProps,
    ) => {
        updateGatewayState({
            ...gateway,
            selectedUpdate: uuid as string,
        });
    };
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const [isResetHidden, setIsResetHidden] = useState<boolean>(true);

    const handleReset = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
    ): Promise<void> => {
        e.preventDefault();
        setSearchKeyword('');
        setIsResetHidden(true);
        setCurrentTotal(0);
        setPageData({ page: 0, limit: 10, total: -1 });
        await resetGateways();
    };
    const handleOnChange = async ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(value);
        if (!value) {
            setCurrentTotal(0);
            setPageData({ page: 0, limit: 10, total: -1 });
            await resetGateways();
        }
        setIsResetHidden(!value);
    };
    const handleSearchOnClick = async () => {
        await resetGateways();
        const { page, limit } = pageData;
        const gateways = await searchByKeyword(
            page,
            limit,
            searchKeyword.trim(),
        );
        await getAllAvailableUpdates(gateways);
        setSearchKeyword(searchKeyword);
    };

    useEffect(() => {
        (async () => {
            await resetGateways();
        })();
    }, []);

    useEffect(() => {
        let socket: Socket | undefined;
        (async () => {
            if (gatewayAssets.all.length) {
                const updatingGateways = getUpdatingGateways();
                socket = await waitForUpdatedGateways(updatingGateways);
            }
        })();
        return () => {
            if (gatewayAssets.all.length) {
                socket && socket.destroy();
            }
        };
    }, [gatewayAssets.all.length]);

    return (
        <LimitedContainer>
            <StyledHeader as="h1">
                Gateway assets
                <StyledButtonContainer left>
                    <Input
                        value={searchKeyword}
                        icon={
                            <ToggleableIcon
                                name="close"
                                className="close tiny"
                                link
                                onClick={handleReset}
                                hidden={isResetHidden}
                            />
                        }
                        placeholder="Search"
                        onChange={handleOnChange}
                    />
                    <Button
                        primary
                        disabled={!searchKeyword}
                        onClick={() => handleSearchOnClick()}
                    >
                        Search
                    </Button>
                    <Button
                        primary
                        floated="right"
                        disabled={!Object.keys(selectedGateways).length}
                        onClick={() => {
                            showDeleteModal(selectedGateways, () => {
                                setSelectedGateways({});
                            });
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        primary
                        floated="right"
                        disabled={!Object.keys(selectedGateways).length}
                        onClick={handleUpdateClick}
                    >
                        Update
                    </Button>
                    <Button
                        primary
                        floated="right"
                        onClick={() =>
                            openModal({
                                closeIcon: true,
                                size: 'large',
                                content: (
                                    <AddGatewayAssetForm
                                        name={gatewayAssets.name}
                                        vendor={gatewayAssets.vendor}
                                        gatewayid={gatewayAssets.gatewayId}
                                        sim={gatewayAssets.sim}
                                        uuid={gatewayAssets.uuid}
                                    />
                                ),
                                header: 'Add Gateway Asset',
                                hasModalActions: false,
                            })
                        }
                    >
                        Add
                    </Button>
                </StyledButtonContainer>
            </StyledHeader>
            {gatewayAssets.all.length == 0 && <EmptyGateway />}
            <ListContainer>
                {gatewayAssets.all.length > 0 && (
                    <ListItem key={1} bottom={0 !== gatewayAssets.length - 1}>
                        <TableCell className="bold flex-1">
                            <Checkbox
                                disabled={!gatewayAssets.all.length}
                                name="selectAll"
                                onClick={(event, { checked }) => {
                                    setSelectedGateways(
                                        checked ? gatewayAssets.byUUID : {},
                                    );
                                }}
                                className="toggleAll"
                            />
                        </TableCell>
                        <TableCell className="bold flex-1">
                            Gateway ID
                        </TableCell>
                        <TableCell className="bold flex-1">Name</TableCell>
                        <TableCell className="bold flex-1">Company</TableCell>
                        <TableCell className="bold flex-1">Location</TableCell>
                        <TableCell className="bold flex-1">Vendor</TableCell>
                        <TableCell className="bold flex-1">
                            Firmware Version
                        </TableCell>
                        <TableCell className="bold flex-1">
                            Available Update
                        </TableCell>
                        <TableCell className="bold flex-1">
                            Update Status
                        </TableCell>
                        <TableCell className="bold flex-1">
                            Chirpstack
                        </TableCell>
                        <TableCell className="bold flex-1">
                            Chirpstack Last Seen
                        </TableCell>
                        <TableCell className="bold flex-1">Hologram</TableCell>
                        <TableCell className="bold flex-1">
                            SIM Status
                        </TableCell>
                        <TableCell>
                            <Dropdown icon="" />
                        </TableCell>
                    </ListItem>
                )}
                {gatewayAssets.all.length > 0 &&
                    gatewayAssets.all.map(
                        (gatewayUuid: string, idx: number) => {
                            const {
                                gatewayId,
                                uuid,
                                name,
                                vendor,
                                firmwareVersion,
                                availableUpdate,
                                updateStatus,
                                sim,
                                hologramStatus,
                                hologramDeviceId,
                                devices,
                                company_name,
                                location_name,
                                chirpstackStatus,
                                companyUuid,
                            } = gatewayAssets.byUUID[
                                gatewayUuid
                            ] as GatewayAssetModel;
                            const availableVersions: DropdownItemProps[] = [];
                            availableUpdate?.map(
                                (update: availableUpdateType) => {
                                    availableVersions.push({
                                        text: update.toFirmwareVersion,
                                        value: update.uuid,
                                    });
                                },
                            );

                            const listOfSensors: Array<Record<string, undefined>> = [];
                            devices?.map((devices?: Record<string, undefined>) => {
                                listOfSensors.push(devices);
                            });

                            return (
                                <ListItem
                                    key={gatewayId}
                                    bottom={idx !== gatewayAssets.length - 1}
                                >
                                    <TableCell className="flex-1">
                                        <Checkbox
                                            name={'checkbox-'.concat(gatewayId)}
                                            className="gateway-checkbox"
                                            id={gatewayId}
                                            checked={!!selectedGateways[uuid]}
                                            onClick={(event, data) => {
                                                handleCheckboxClick(uuid, data);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className="flex-1"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            openModal({
                                                closeIcon: true,
                                                size: 'large',
                                                content: (
                                                    <SensorDisplayList
                                                        sensors={listOfSensors}
                                                    />
                                                ),
                                                header: `Sensors connected to ${gatewayId}`,
                                                hasModalActions: false,
                                            })
                                        }
                                    >
                                        <a>{gatewayId}</a>
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {name}
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {company_name}
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {location_name}
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {vendor}
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {firmwareVersion ??
                                            'Flash new firmware'}
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {availableVersions.length ? (
                                            <Dropdown
                                                defaultValue={
                                                    availableVersions[0]?.value
                                                }
                                                name={'all-available-firmware-dropdown-'.concat(
                                                    gatewayId,
                                                )}
                                                className="available-firmware-dropdown"
                                                options={
                                                    availableVersions ?? 'None'
                                                }
                                                onChange={(event, data) => {
                                                    handleOnSelectUpdate(
                                                        gatewayAssets.byUUID[
                                                            uuid
                                                        ],
                                                        data,
                                                    );
                                                }}
                                            />
                                        ) : firmwareVersion &&
                                          firmwareVersion < '1.3.6' ? (
                                            'Updating firmwares below 1.3.6 is not supported'
                                        ) : (
                                            'No available update'
                                        )}
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {updateStatus === 'updating' ? (
                                            <div
                                                className={
                                                    'ui active inline loader'
                                                }
                                            ></div>
                                        ) : (
                                            updateStatus
                                        )}
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        <a
                                            href={
                                                `${process.env.THERMALINK_CHIRPSTACK_GATEWAY_BASE_URL}/#/${process.env.THERMALINK_CHIRPSTACK_URL_ENDPOINT}` +
                                                gatewayId
                                            }
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            Chirpstack
                                        </a>
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {chirpstackStatus}
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        <a
                                            href={`${process.env.THERMALINK_HOLOGRAM_BASE_URL}${hologramDeviceId}/status?page=1`}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            Hologram
                                        </a>
                                    </TableCell>
                                    <TableCell className="flex-1">
                                        {hologramStatus}
                                    </TableCell>
                                    <TableCell>
                                        <Dropdown icon="vertical ellipsis">
                                            <DropdownMenu
                                                gatewayid={gatewayId}
                                                sim={sim}
                                            >
                                                <DropdownItem
                                                    text="Edit"
                                                    gatewayid={gatewayId}
                                                    sim={sim}
                                                    onClick={() =>
                                                        openModal({
                                                            closeIcon: true,
                                                            size: 'large',
                                                            content: (
                                                                <EditGatewayAssetForm
                                                                    name={name}
                                                                    vendor={
                                                                        vendor
                                                                    }
                                                                    gatewayid={
                                                                        gatewayId
                                                                    }
                                                                    sim={sim}
                                                                    uuid={uuid}
                                                                    companyUuid={
                                                                        companyUuid
                                                                    }
                                                                    companyName={
                                                                        company_name
                                                                    }
                                                                />
                                                            ),
                                                            header: `Edit gateway asset ${gatewayId}`,
                                                            hasModalActions: false,
                                                        })
                                                    }
                                                />
                                                <DropdownItem text="Deploy" />
                                                <DropdownItem text="Unlink" />
                                            </DropdownMenu>
                                        </Dropdown>
                                    </TableCell>
                                </ListItem>
                            );
                        },
                    )}
                {gatewayAssets.length > 0 &&
                    pageData.total > -1 &&
                    !loadingMoreData && (
                        <div className="page-data">{`Displaying ${gatewayAssets.length} out of ${pageData.total}`}</div>
                    )}
                {currentTotal !== pageData.total && (
                    <LazyLoad onVisible={loadMoreGateways}>
                        <div className="not-visible">load more</div>
                    </LazyLoad>
                )}
            </ListContainer>
            {pageData.total > -1 && loadingMoreData && (
                <div className="loading-more-data">Loading more gateways</div>
            )}
        </LimitedContainer>
    );
};
