import React, { FunctionComponent } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

interface WarningModalProps {
    open: boolean;
    onCancel: () => void;
    text: string;
}

export const WarningModal: FunctionComponent<WarningModalProps> = ({
    open,
    onCancel,
    text,
}) => {
    return (
        <Modal open={open}>
            <Modal.Content>
                <Modal.Description>
                    <Header>{text}</Header>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onCancel}>
                    OK
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
