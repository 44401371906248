import axios, { AxiosInstance } from 'axios';
import { GatewayModel } from './gateway-service';
class Axios {
    static cancelTokenSources = axios.CancelToken.source();
    static request(baseURL = '') {
        return axios.create({
            baseURL:
                baseURL ||
                process.env.THERMALINK_API_HOST ||
                'http://localhost:9026',
            cancelToken: this.cancelTokenSources.token,
        });
    }

    static cancellAlRequests() {
        this.cancelTokenSources.cancel();
        this.cancelTokenSources = axios.CancelToken.source();
    }

    static requestGateway() {
        return axios.create({
            baseURL: `${process.env.GATEWAY_API_HOST ||
                'http://localhost:9029'}/api/gateway`,
            cancelToken: this.cancelTokenSources.token,
        });
    }

    public static loadGateways(): AxiosInstance {
        return axios.create({
            baseURL: `${process.env.GATEWAY_API_HOST ||
                'http://localhost:9026'}/gateway`,
            cancelToken: this.cancelTokenSources.token,
        });
    }
}

export { Axios };
