import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import firebase from 'firebase/app';
import React, {
    createContext,
    FunctionComponent,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useHistory } from 'react-router';

import { AuthService, Axios, FirebaseService, Role } from '../services';
import { StoreContext } from '../store';
export interface UserAuthContext {
    token: string;
    idToken: string;
    hasUser: boolean;
    firebaseLogin: () => void;
    logout: () => Promise<void>;
    isInitializing: boolean;
    atkaToken: string;
}

const instance: AxiosInstance = axios.create({
    baseURL: `${process.env.THERMA_API_HOST || 'http://localhost:8999'}/api`,
});

export const atkaRequest = instance;

export default instance;

export const AuthContext = createContext<UserAuthContext>({
    token: '',
    idToken: '',
    hasUser: false,
    firebaseLogin: () => undefined,
    logout: async () => undefined,
    isInitializing: true,
    atkaToken: '',
});

export const AuthProvider: FunctionComponent = ({ children }) => {
    const [token, setToken] = useState('');
    const [idToken, setIdToken] = useState('');
    const [hasUser, setHasUser] = useState<boolean>(false);
    const [isInitializing, setIsInitializing] = useState<boolean>(true);
    const firebaseApp = new FirebaseService().app;
    const { dispatch } = useContext(StoreContext);
    const history = useHistory();
    const atkaToken = useRef<string | null>(null);
    const lastRequest = useRef<AxiosRequestConfig | null>(null);
    const backendAuth = async (idToken: string) => {
        try {
            const {
                token: apiToken,
                atkaToken: atkaTokenInit,
            } = await AuthService.authenticateFirebase(idToken);
            setToken(apiToken);
            setIdToken(idToken);
            setHasUser(true);
            atkaToken.current = atkaTokenInit;
        } catch (e) {
            setToken('');
            setIdToken('');
            setHasUser(false);
            setIsInitializing(false);
            atkaToken.current = null;
        }
    };

    const firebaseLogin = async () => {
        const { user } = await firebase
            .auth()
            .signInWithPopup(new firebase.auth.GoogleAuthProvider());

        if (!user) {
            throw new Error('no user in firebase auth');
        }

        const idToken = await firebaseApp.auth().currentUser?.getIdToken();

        if (!idToken) {
            throw new Error('no idToken in firebase auth');
        }

        await backendAuth(idToken);
    };

    const logout = async () => {
        await firebaseApp.auth().signOut();
        Axios.cancellAlRequests();
        setHasUser(false);
        setToken('');
        setIdToken('');
        dispatch({
            type: 'clearall',
        });
        return history.push('/');
    };

    useEffect(() => {
        firebaseApp
            .auth()
            .onAuthStateChanged(async (profile: firebase.User | null) => {
                if (!profile) {
                    setIsInitializing(false);
                    return;
                }
                const { currentUser } = firebase.auth();
                if (!currentUser) {
                    setIsInitializing(false);
                    return;
                }

                const idToken = await currentUser.getIdToken();

                await backendAuth(idToken);
                setIsInitializing(false);
            });
    }, []);

    useEffect(() => {
        instance.interceptors.request.use(
            async (config: AxiosRequestConfig) => {
                if (atkaToken.current) {
                    config.headers = {
                        Authorization: atkaToken.current,
                    };
                }
                lastRequest.current = config;
                return config;
            },
            (err) => {
                throw new Error(err.response.data.message);
            },
        );

        instance.interceptors.response.use(
            async (response: AxiosResponse) => {
                if (response.headers.authorization) {
                    atkaToken.current = response.headers.authorization;
                }
                return response;
            },
            async (err: AxiosError) => {
                const { response } = err;
                if (!response) {
                    throw err;
                }

                if (response.status <= 400 || response.status >= 500) {
                    return Promise.reject(err);
                }
                if (response.status === 401) {
                    const { currentUser } = firebase.auth();
                    if (!currentUser) {
                        setIsInitializing(false);
                        return;
                    }
                    const idToken = await currentUser.getIdToken();
                    await backendAuth(idToken);
                    const currentRequest = lastRequest.current;
                    if (currentRequest && currentRequest.headers) {
                        currentRequest.headers[
                            'Authorization'
                        ] = atkaToken.current as string;
                        return instance.request(currentRequest);
                    }
                    setIsInitializing(false);
                }
                if (response.headers.authorization) {
                    atkaToken.current = response.headers.authorization;
                } else {
                    atkaToken.current = null;
                }
            },
        );
    }, [atkaToken.current]);

    return (
        <AuthContext.Provider
            value={{
                token,
                idToken,
                hasUser,
                firebaseLogin,
                logout,
                isInitializing,
                atkaToken: atkaToken.current || '',
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
