import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

type Props = {
  closeModal: () => void;
  clearFormPartially: () => void;
  clearForm: () => void;
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const EnergyDeviceAddModal = ({
  closeModal,
  clearFormPartially,
  clearForm,
}: Props) => {
  const handleCopyForm = () => {
    clearFormPartially();
    closeModal();
  };
  const handleEmptyForm = () => {
    clearForm();
    closeModal();
  };
  const noForm = () => {
    clearForm();
    closeModal();
  };
  return (
    <ModalContainer>
      <Button color="green" onClick={handleCopyForm}>
        Yes - copy configurations from previous thermostat
      </Button>
      <Button color="green" onClick={handleEmptyForm}>
        Yes - do not copy configurations from previous thermostat
      </Button>
      <Button color="red" onClick={noForm}>
        No
      </Button>
    </ModalContainer>
  );
};

export default EnergyDeviceAddModal;
