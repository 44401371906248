import axios, { AxiosInstance } from 'axios';

import { atkaRequest } from '../contexts';
import { Axios } from '.';
import { BaseModel } from './';

export interface GatewayModel extends BaseModel {
    gatewayId: string;
    serialNumber: string;
    gatewayName: string;
    lastSeen?: string;
    status?: string;
    count?: number;
}

export interface availableUpdateType {
    uuid: string;
    toFirmwareVersion: string;
}
export interface GatewayAssetModel extends BaseModel {
    uuid: string;
    name: string;
    companyUuid?: string;
    locationUuid?: string;
    chirpstackGatewayId?: string;
    hologramDeviceId?: number;
    firmwareVersion?: string;
    chirpstackStatus?: string;
    hologramStatus?: string;
    vendor: string;
    model: string;
    sim: string;
    gatewayId: string;
    updateStatus: 'updated' | 'updating' | 'updatable';
    availableUpdate?: availableUpdateType[];
    selectedUpdate?: string;
}

export class GatewayService {
    public static async browseConfigured(token: string) {
        const { data: gateways } = await Axios.request().get(
            `/gateway/configured`,
            {
                headers: { authorization: token },
            },
        );
        return gateways.data;
    }

    public static async add(
        gatewayId: string,
        token: string,
    ): Promise<GatewayModel> {
        const { data: gateway } = await Axios.request().post(
            `/gateway`,
            {
                gatewayId,
            },
            {
                headers: { authorization: token },
            },
        );

        return gateway.data;
    }

    public static async delete(uuid: string, token: string) {
        const { data: gateway } = await Axios.request().delete(
            `/gateway/${uuid}`,
            {
                headers: { authorization: token },
            },
        );
        return gateway.data;
    }

    public static async addGateway(gatewayAssetModel: GatewayAssetModel) {
        console.log('addGateway data', gatewayAssetModel);
        const { data: gateway } = await atkaRequest.post('/gateway/', {
            gateway: gatewayAssetModel,
        });

        return gateway.data;
    }

    public static async checkIfUniqueGatewayName(name: string) {
        try {
            const { data: gateway } = await atkaRequest.get(
                `/gateway/gatewayName/${name}`,
            );
            return gateway.data;
        } catch (err) {
            if (err.response && err.response.status === 404) {
                return null;
            }
        }
    }

    public static async checkIfUniqueGatewayId(gatewayId: string) {
        try {
            const { data: gateway } = await atkaRequest.get(
                `/gateway/gatewayId/${gatewayId}`,
            );
            return gateway.data;
        } catch (err) {
            if (err.response && err.response.status === 404) {
                return null;
            }
        }
    }

    public static async updateGateway(gatewayAssetModel: GatewayAssetModel) {
        const { data: gateway } = await atkaRequest.put(
            `/gateway/${gatewayAssetModel.uuid}`,
            {
                gateway: gatewayAssetModel,
            },
        );

        return gateway.data;
    }

    public static async deleteGateway(uuid: string) {
        const { data: gateway } = await atkaRequest.delete(`/gateway/${uuid}`);

        return gateway;
    }

    public static async loadAllGateways() {
        try {
            const { data: response } = await atkaRequest.get('/gateway/');
            const { data } = response;

            return data.gateways;
        } catch (err) {
            console.error('Something went wrong fetching all gateways');
        }
    }

    /*
    public static async loadGatewaysByPage(page: number, perPage: number, token: string) {
        try {
            const { data: response } = await atkaRequest.get(
                `/gateway?page=${page}&limit=${perPage}`,
                {
                    headers: { authorization: token },
                },
            );
            const { data } = response;

            return data;
        } catch (err) {
            console.error('Something went wrong fetching gateways');
        }
    }
    */
    
    public static async loadGatewaysByPage(
        page: number,
        perPage: number,
        keyword: string,
        token: string,
    ): Promise<Array<Record<string, undefined>> | undefined> {
        try {
            const { data: response } = await Axios.request().get(
                `gateway?page=${page}&limit=${perPage}&keyword=${keyword}`,
                {
                    headers: {
                        authorization: `${token}`,
                    },
                },
            );
            const { data } = response;

            return data;
        } catch (err) {
            console.error('Something went wrong fetching gateways');
        }
    }

    public static async searchByKeyword(keyword: string) {
        try {
            const { data: response } = await atkaRequest.get(
                `/gateway/searchByKeyword/${keyword}`,
            );
            const { data } = response;
            return data;
        } catch (err) {
            console.error('Something went wrong fetching gateways');
        }
    }
}
