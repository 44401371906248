/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext } from 'react';

import { BaseModal, BaseModalProps } from '../components/base-modal';
import { withTransaction } from '@elastic/apm-rum-react'

export const ModalContext = createContext({
    hideModal: (): void => {},
    modalData: null,
    openModal: (payload: BaseModalProps | string | JSX.Element): void => {},
    setModalData: (payload: any): void => {},
});

class Modal extends React.Component<{}, any> {
    constructor(props: BaseModalProps) {
        super(props);
        this.state = {
            modalState: {
                isOpen: false,
                modalData: null,
            },
        };
    }

    public render() {
        const setModalState = (
            payload: BaseModalProps | string | JSX.Element,
        ) => {
            let modalState;

            if (typeof payload !== 'object') {
                modalState = {
                    content: payload,
                    isOpen: true,
                };
            } else {
                modalState = {
                    isOpen: true,
                    ...payload,
                };
            }

            this.setState({
                modalState: {
                    ...modalState,
                },
            });
        };

        const baseModalContextAPI: BaseModalContext = {
            openModal: (payload: BaseModalProps | string | JSX.Element) => {
                setModalState(payload);
            },

            hideModal: () => {
                setModalState({ isOpen: false });
            },

            modalData: this.state.modalState.modalData,

            setModalData: (payload: any) => {
                this.setState({
                    modalState: {
                        modalData: payload,
                    },
                });
            },
        };

        return (
            <ModalContext.Provider value={baseModalContextAPI}>
                <BaseModal {...this.state.modalState} />
                {this.props.children}
            </ModalContext.Provider>
        );
    }
}

export interface BaseModalContext {
    hideModal: () => void;
    modalData: any;
    openModal: (payload: BaseModalProps | string | JSX.Element) => void;
    setModalData: (payload: any) => void;
}

/* Instrument the Modal component using withTransaction
withTransaction accepts two parameters, "transaction name" and "transaction type". */
export const ModalWithTransaction = withTransaction('Modal', 'component')(Modal)
