import firebase from 'firebase/app';
import 'firebase/auth';

interface FirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

// TODO: update env variables to use therma board
const firebaseConfig = {
    apiKey: process.env.THERMALINK_FIREBASE_API_KEY || '',
    authDomain: process.env.THERMALINK_FIREBASE_AUTH_DOMAIN || '',
    databaseURL: process.env.CALIBRATION_FIREBASE_DATABASE_URL || '',
    projectId: process.env.CALIBRATION_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.CALIBRATION_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId:
        process.env.CALIBRATION_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.CALIBRATION_FIREBASE_APP_ID || '',
    measurementId: process.env.CALIBRATION_FIREBASE_MEASUREMENT_ID || '',
};

export class FirebaseService {
    public app: firebase.app.App;

    constructor(config: FirebaseConfig = firebaseConfig) {
        this.app = this.getApp(config);
    }

    private getApp(config: FirebaseConfig) {
        let app;
        try {
            app = firebase.app();
        } catch (e) {
            app = firebase.initializeApp(config);
        }
        return app;
    }
}
