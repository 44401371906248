import { useContext } from 'react';

import { AuthContext } from '../../../contexts';
import {
    ControlDevice,
    EnergyDeviceService,
} from '../../../services/energy-device-service';

const useEnergyDeviceService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        getDevicesByVendorIds: (vendorIds: string[]) =>
            EnergyDeviceService.getDevicesByVendorIds(idToken, vendorIds),
        control: (params: ControlDevice) =>
            EnergyDeviceService.control(idToken, params),
    };
};

export default useEnergyDeviceService;
