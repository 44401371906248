import React, { ChangeEvent, Dispatch, SetStateAction, SyntheticEvent, useContext, useState } from 'react';
import EnergyDeviceFormRow from './energy-device-form-row';
import EnergyDeviceInput from './energy-device-input';
import { Button, Dropdown } from 'semantic-ui-react';
import { LoadControllerInputGroup, ScheduleRowWrapper } from './styles';
import { PinType } from '../types';
import { LOAD_CONTROLLER_TYPES, WEEKDAYS } from '../constants';
import { ModalContext } from '../../../../../contexts';
import EnergyLoadControllerCopyModal from './energy-load-controller-copy-modal';
import { EnergyLCPinType } from 'services/energy-load-controller-service';

export const EnergyDeviceLoadController = ({
  pins,
  setPins
}:{
  pins: PinType[],
  setPins: Dispatch<SetStateAction<PinType[]>>
}) => {
  const [showScheduleArr, setShowScheduleArr] = useState(Array(8).fill(false));
  const handleChange = (newPin: PinType) => {
    setPins((prevPins) => {
      return prevPins.map((pin) => pin.pin === newPin.pin ? newPin : pin);
    });
  };
  const handleCopy = (whichPins: boolean[], schedule: EnergyLCPinType['schedule']) => {
    setPins((prevPins) => {
      return prevPins.map((pin, i) => whichPins[i] ? { ...pin, schedule } : pin);
    });
    setShowScheduleArr((prev) => {
      return prev.map((isOpen,i) => isOpen || whichPins[i]);
    });
  };
  const setShowSchedule = (pinIndex: number) => () => {
    setShowScheduleArr((prev) => {
      return prev.map((isOpen, i) => i === pinIndex ? true : isOpen);
    });
  };
  return (
      <>
        {pins.map((pin, index) => (
          <EnergyDeviceLoadControllerRow
            key={index}
            handleChange={handleChange}
            pin={pin}
            pins={pins}
            showSchedule={showScheduleArr[index]}
            handleCopy={handleCopy}
            setShowSchedule={setShowSchedule(index)}
          />
        ))}
      </>
  );
};

const EnergyDeviceLoadControllerRow = ({
  pin,
  pins,
  showSchedule,
  handleChange,
  handleCopy,
  setShowSchedule,
}:{
  pin: PinType;
  pins: PinType[];
  showSchedule: boolean;
  handleChange: (pin: PinType) => void;
  handleCopy: (whichPins: boolean[], schedule: EnergyLCPinType['schedule']) => void;
  setShowSchedule: () => void;
}) => {
  const { openModal } = useContext(ModalContext);

  const handleNameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    handleChange({ ...pin, name: value });
  }

  const handleTimeChange = (field: string, day: string) => ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    handleChange({
      ...pin,
      schedule: {
        ...pin.schedule,
        [day]: { ...pin.schedule[day], [field]: value }
      }
    });
  }

  const handleShowSchedule = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowSchedule();
  };

  return (
    <>
    <EnergyDeviceFormRow label={`${pin.pin}. Equipment name:`}>
      <LoadControllerInputGroup>
        <EnergyDeviceInput
          title=""
          value={pin.name}
          handleChange={handleNameChange}
          />
        <div style={{width: '100px'}}>
          <Dropdown
            clearable
            required
            placeholder="Select Equipment"
            options={LOAD_CONTROLLER_TYPES}
            name="loadControllerType"
            selection
            disabled
            fluid
            value={LOAD_CONTROLLER_TYPES[0].value}
          />
        </div>
        <Button color="grey" onClick={handleShowSchedule}>Set</Button>
      </LoadControllerInputGroup>
    </EnergyDeviceFormRow>
    <EnergyDeviceFormRow label="">
      {showSchedule && (
        <div>
          {Object.keys(pin.schedule).map((day, index) => {
            const daySchedule = pin.schedule[day];
            return (
              <ScheduleRowWrapper key={index}>
                <label>{WEEKDAYS[Number(day)]}</label>
                <label>On</label>
                <input
                  type="time"
                  value={daySchedule.on}
                  onChange={handleTimeChange('on', day)}
                />
                <label>Off</label>
                <input
                  type="time"
                  value={daySchedule.off}
                  onChange={handleTimeChange('off', day)}
                />
              </ScheduleRowWrapper>
          )})}
          <Button color="grey" onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            openModal({
              closeIcon: true,
              size: 'mini',
              content: (
                <EnergyLoadControllerCopyModal pins={pins} handleCopy={handleCopy} pinToCopy={pin} />
              ),
              header: 'Copy Schedule',
              hasModalActions: false,
            })
            }}>
            Copy
          </Button>
        </div>
      )}
    </EnergyDeviceFormRow>
    </>
  );
};

export default EnergyDeviceLoadController;