import { useContext } from 'react';

import { AuthContext } from '../../../../contexts';
import { EnergyLoadControllerService, EnergyLoadControllerType } from '../../../../services/energy-load-controller-service';

const useEnergyLoadControllerService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        getAvailableLoadControllers: () =>
            EnergyLoadControllerService.getAvailableLoadControllers(
                idToken,
            ),
        getLoadControllersByMonitoringLocation: (monitoringLocationUUID: string) =>
            EnergyLoadControllerService.getLoadControllersByMonitoringLocation(
                idToken,
                monitoringLocationUUID,
            ),
        commissionLoadController: (loadController: EnergyLoadControllerType) =>
            EnergyLoadControllerService.commissionLoadController(
                idToken,
                loadController,
            ),
        deleteLoadController: (loadControllerUUID: string) =>
            EnergyLoadControllerService.deleteLoadController(
                idToken,
                loadControllerUUID,
            ),
        updateLoadController: (loadController: EnergyLoadControllerType, loadControllerUUID: string) =>
            EnergyLoadControllerService.updateLoadController(idToken, loadController, loadControllerUUID),
    };
};

export default useEnergyLoadControllerService;
