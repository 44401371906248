/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ButtonContainer,
    FormDropdown,
    FormInput,
    LimitedContainer,
} from '@coinspect/ui';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form, Header } from 'semantic-ui-react';
import { isEmail } from 'validator';

import { StoreContext, useUserService } from '../../store';
import { Card } from '../card';
import { ListContainer } from '../list';
import { UserList } from '../users';

interface UserForm {
    email: string;
}

export const UserPage: FunctionComponent<{}> = () => {
    const { store } = useContext(StoreContext);
    const { users } = store.entities;
    const { addUser, browseUsers } = useUserService();
    const formMethods = useForm<UserForm>({
        mode: 'onChange',
    });

    useEffect(() => {
        (async () => {
            await browseUsers();
        })();
    }, []);

    const onSubmit = formMethods.handleSubmit(async (data) => {
        const { email } = data;
        try {
            await addUser(email);
            formMethods.reset({ email: '' });
        } catch (err) {
            const { status } = err.response;
            if (status === 409) {
                formMethods.setError('email', 'noDuplicatedEmail');
            }
        }
    });

    return (
        <LimitedContainer>
            <Card>
                <FormContext {...formMethods}>
                    <Form onSubmit={onSubmit} noValidate>
                        <FormInput
                            label="Email:"
                            name="email"
                            type="email"
                            required
                            customErrors={{
                                required: 'Email is required.',
                                noDuplicatedEmail: 'User already exist',
                            }}
                            validation={{
                                email: (val) => isEmail(val),
                            }}
                        />

                        <ButtonContainer right>
                            <Button
                                primary
                                type="submit"
                                disabled={formMethods.formState.isSubmitting}
                                loading={formMethods.formState.isSubmitting}
                            >
                                Submit
                            </Button>
                        </ButtonContainer>
                    </Form>
                </FormContext>
            </Card>

            <Header as="h1">Users</Header>
            <ListContainer>
                <UserList userUuids={users.all} />
            </ListContainer>
        </LimitedContainer>
    );
};
