import React, { FunctionComponent } from 'react';
import {
    Button,
    Dropdown,
    Header,
    Loader,
    Responsive,
} from 'semantic-ui-react';
import styled from 'styled-components';

import {
    EnergyLocation,
    EnergyMeter,
} from '../../../services/energy-location-service';
import {
    FieldInputWrapper,
    ListLabelWrapper,
    ListNumberWrapper,
    ListTextWrapper,
} from './styles';

const LocationUtilityAPIFormContainer = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 15px 40px 15px 40px;
    gap: 40px;
    justify-content: space-evenly;
    margin-bottom: 15px;
    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-items: stretch;
        gap: 20px;
    }
`;

const UtilityMeterButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    gap: 35px;
    flex: 1;

    @media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
        flex-direction: column;
        align-items: stretch;
        gap: 15px;
    }
`;

const UtilityMeterInputButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    flex: 1;
`;

export interface EnergyUtilityMeterFormProps {
    energyLocation: EnergyLocation | null;
    selectedMeter: string;
    handleMeterChange: (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: string },
    ) => void;
    handleDeleteMeter: () => Promise<void>;
    meters: EnergyMeter[];
    handleMeterUpdate: () => void;
    metersLoading: boolean;
    updateMeterLoading: boolean;
}

export const EnergyUtilityMeterForm: FunctionComponent<EnergyUtilityMeterFormProps> = ({
    energyLocation,
    selectedMeter,
    handleMeterChange,
    handleDeleteMeter,
    meters,
    handleMeterUpdate,
    metersLoading,
    updateMeterLoading,
}) => {
    return (
        <LocationUtilityAPIFormContainer>
            <ListLabelWrapper>
                <ListNumberWrapper>
                    <Header size="tiny">{'1a.'}</Header>
                </ListNumberWrapper>
                <ListTextWrapper>
                    <Header size="tiny">
                        {
                            'If applicable, assign a utilityAPI Meter ID to this location.'
                        }
                    </Header>
                </ListTextWrapper>
            </ListLabelWrapper>
            <UtilityMeterInputButtonWrapper>
                <FieldInputWrapper>
                    {!metersLoading ? (
                        <Dropdown
                            placeholder="Select Meter ID"
                            fluid
                            required
                            selection
                            value={selectedMeter}
                            options={meters.map((meter: EnergyMeter) => ({
                                key: meter.uid,
                                text: `Meter: ${meter.uid} Location: ${meter.service_address} Contact: ${meter.billing_contact}`,
                                value: meter.uid,
                            }))}
                            onChange={handleMeterChange}
                            disabled={updateMeterLoading}
                            search={(
                                options: { text: string }[],
                                query: string,
                            ) =>
                                options.filter((option) =>
                                    option.text
                                        .toLowerCase()
                                        .split(
                                            /meter:\s|\slocation:\s|\scontact:\s/,
                                        )
                                        .filter((str) => str)
                                        .some((str) =>
                                            str.startsWith(query.toLowerCase()),
                                        ),
                                )
                            }
                        />
                    ) : (
                        <Loader
                            active
                            size="tiny"
                            style={{
                                position: 'relative',
                            }}
                        />
                    )}
                </FieldInputWrapper>
                <UtilityMeterButtonsWrapper>
                    <Button
                        onClick={handleMeterUpdate}
                        fluid
                        color="green"
                        disabled={
                            !selectedMeter ||
                            selectedMeter ===
                                energyLocation?.utilityProviderId ||
                            updateMeterLoading
                        }
                    >
                        Assign Meter
                    </Button>
                    <Button
                        onClick={handleDeleteMeter}
                        fluid
                        color="red"
                        disabled={
                            energyLocation?.utilityProviderId === 'n/a' ||
                            !selectedMeter ||
                            metersLoading ||
                            updateMeterLoading
                        }
                    >
                        Remove Meter
                    </Button>
                </UtilityMeterButtonsWrapper>
            </UtilityMeterInputButtonWrapper>
        </LocationUtilityAPIFormContainer>
    );
};
