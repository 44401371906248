import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { toast } from 'react-toastify';

import { ModalContext } from '../../../../contexts';
import {
  ECOBEE_VENDOR,
  ENGINKO_VENDOR,
  EquipmentType,
  MCCI_VENDOR,
  MEKI_VENDOR,
  MILESIGHT_VENDOR,
  RawEnergyDeviceType,
  SHELLY_VENDOR,
  VendorDeviceType,
} from '../../../../services/energy-device-service';
import { EnergyLocation } from '../../../../services/energy-location-service';
import useEnergyDeviceService, {
  capitalizeFirstLetter,
  EnergyDevice,
  formatEquipmentType,
} from '../hooks/useEnergyDeviceService';
import useEquipmentService from '../hooks/useEquipmentService';
import EnergyDeviceAddModal from './components/energy-device-add-modal';
import {
  HVAC_EQUIPMENT_TYPE,
  MILESIGHT_LOAD_CONTROLLER_VALUES,
  MILESIGHT_POWER_METER_VALUES,
  MILESIGHT_THERMOSTAT_VALUES,
  SINGLE_PHASE_METER_KEY,
  WEEKDAYS,
} from './constants';
import { FormDataType, PinType } from './types';
import { getEnergyDeviceTypeData } from './utils';
import { useEnergyLoadControllers } from '../hooks/useEnergyLoadControllers';
import { EnergyLoadControllerType } from 'services/energy-load-controller-service';
import useEnergyLoadControllerService from '../hooks/useEnergyLoadControllerService';
import { EquipmentDto } from 'services/equipment-service';

export const useEnergyDeviceForm = ({
  equipments,
  energyDevices,
  energyLocation,
  onDeviceCreated,
  vendorDevices,
  companyUUID,
}: {
  equipments: EquipmentType[] | null;
  energyDevices: EnergyDevice[];
  energyLocation: EnergyLocation;
  onDeviceCreated: (devices: EnergyDevice[]) => void;
  vendorDevices: VendorDeviceType[];
  companyUUID: string;
}) => {
  const { openModal, hideModal } = useContext(ModalContext);

  const getInitialFormData = (): FormDataType => ({
    selectedEnergyDeviceType: undefined,
    customName: undefined,
    occupiedHeatSetPoint: undefined,
    occupiedCoolSetPoint: undefined,
    unoccupiedHeatSetPoint: undefined,
    unoccupiedCoolSetPoint: undefined,
    corpStandOccupiedHeatSetPoint: undefined,
    corpStandOccupiedCoolSetPoint: undefined,
    corpStandUnoccupiedHeatSetPoint: undefined,
    corpStandUnoccupiedCoolSetPoint: undefined,
    hvacMode: undefined,
    selectedVendorDevice: undefined,
    selectedEquipment: undefined,
    fanModeOccupied: undefined,
    fanModeUnoccupied: undefined,
    timeZone: undefined,
    occupiedHoursStart: undefined,
    occupiedHoursStop: undefined,
    customByDay: false,
    customByDayValues: Array.from({ length: 7 }, (_, i) => ({
      day: i,
      start: '',
      end: '',
    })),
    autoTransition: false,
    autoTransitionStart: undefined,
    screenLock: true,
    deadband: '2',
    localOverrideDuration: '60',
    // Single phase meter fields
    meterEquipmentType: undefined,
    voltage: undefined,
    multiplier: undefined,
    equipmentName: undefined,
    newEquipment: false,
  });
  const [formData, setFormData] = useState<FormDataType>(getInitialFormData());

  const handleFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleCustomField = <T>(name: string, value: T) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCustomHoursField = (day: number, field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prev) => ({
      ...prev,
      customByDayValues: prev.customByDayValues.map((prevByDayValue) => prevByDayValue.day === day ? ({
        ...prevByDayValue,
        [field]: value,
      }): prevByDayValue),
    }));
  };

  const [energyDeviceTypes, setEnergyDeviceTypes] = useState<
    RawEnergyDeviceType[] | null
  >(null);
  const [dropdownVendorDevices, setDropdownVendorDevices] = useState<
    VendorDeviceType[]
  >([]);
  const [addDeviceLoading, setAddDeviceLoading] = useState<boolean>(false);

  const selectedUUIDs = {
    locationUUID: energyLocation.monitoringLocationUuid,
    deviceTypeUUID: formData.selectedEnergyDeviceType,
    meterUUID: energyLocation.uuid,
  };
  const selectedEnergyDeviceData = getEnergyDeviceTypeData(
    formData.selectedEnergyDeviceType,
    energyDeviceTypes,
  );
  const setInitPins = () => {
    return Array.from({ length: 8 }, (_, i) => ({
      name: '',
      pin: i+1,
      schedule: WEEKDAYS.reduce(
        (acc, _day, index) => ({
          ...acc,
          [index]: {
            on: '',
            off: '',
          },
        }),
        {},
      ),
    }))
  };

  const [pins, setPins] = useState<PinType[]>(setInitPins);

  const { loadControllers, setLoadControllers } = useEnergyLoadControllers(energyLocation.monitoringLocationUuid);

  const energyDeviceService = useEnergyDeviceService();
  const energyLoadControllerService = useEnergyLoadControllerService();
  const { addEquipment } = useEquipmentService();

  const handleLoadControllerDelete = async (uuid: string) => {
    await energyLoadControllerService.deleteLoadController(uuid);
    setLoadControllers(loadControllers.filter((lc) => lc.uuid !== uuid));
    toast.success('Load controller successfully deleted!');
  };

  const handleLoadControllerEdit = async (newLoadController: EnergyLoadControllerType) => {
    await energyLoadControllerService.updateLoadController(newLoadController, newLoadController.uuid || '');
    setLoadControllers(
      loadControllers
        .map((lc) => lc.identifier === newLoadController.identifier ? newLoadController : lc)
    );
    toast.success('Load controller successfully updated!');
  };

  const clearFormPartially = () => {
    setFormData((prev) => ({
      ...prev,
      customName: undefined,
      occupiedCoolSetPoint: undefined,
      occupiedHeatSetPoint: undefined,
      unoccupiedCoolSetPoint: undefined,
      unoccupiedHeatSetPoint: undefined,
      selectedEquipment: undefined,
    }));
    setAddDeviceLoading(false);
  };

  const clearSinglePhaseMeterFormData = () => {
    const defaultSinglePhaseMeterFormData = {
      meterEquipmentType: undefined,
      voltage: undefined,
      multiplier: undefined,
      equipmentName: undefined,
      newEquipment: false,
    };

    const fieldsAreEqualToDefault = Object.keys(
      defaultSinglePhaseMeterFormData,
    ).every((key) => {
      const typedKey = key as keyof typeof defaultSinglePhaseMeterFormData;
      return formData[typedKey] === defaultSinglePhaseMeterFormData[typedKey];
    });

    if (fieldsAreEqualToDefault) {
      return;
    }

    setFormData((prev) => ({
      ...prev,
      ...defaultSinglePhaseMeterFormData,
    }));
  };

  const clearForm = () => {
    //TODO: Clear all fields
    setFormData(getInitialFormData());
    setAddDeviceLoading(false);
  };

  const filterVendorDevices = (vendorString: string, vendorModel?: string) => {
    if (
      [
        SHELLY_VENDOR,
        ECOBEE_VENDOR,
        MCCI_VENDOR,
        MEKI_VENDOR,
        ENGINKO_VENDOR,
        MILESIGHT_VENDOR,
      ].includes(vendorString)
    ) {
      const dropdownVendorDevices = vendorDevices.filter(
        ({ vendor }) => vendor === vendorString,
      );
      if (vendorModel === MILESIGHT_LOAD_CONTROLLER_VALUES.vendorModel) {
        // Filter for load controllers
        setDropdownVendorDevices(dropdownVendorDevices.filter(({ type }) => type === MILESIGHT_LOAD_CONTROLLER_VALUES.type));
      } else if (vendorModel === MILESIGHT_THERMOSTAT_VALUES.vendorModel) {
        // Filter for thermostats
        setDropdownVendorDevices(dropdownVendorDevices.filter(({ type }) => type === MILESIGHT_THERMOSTAT_VALUES.type));
      } else if (vendorModel === MILESIGHT_POWER_METER_VALUES.vendorModel) {
        // Filter for thermostats
        setDropdownVendorDevices(dropdownVendorDevices.filter(({ type }) => type === MILESIGHT_POWER_METER_VALUES.type));
      } else {
        setDropdownVendorDevices(dropdownVendorDevices);
      }
    }
  };

  const getDeviceType = (
    value: string | null,
  ): {
    isThermostat: boolean;
    isMilesight: boolean;
    isSinglePhaseMeter: boolean;
    isLoadController: boolean;
  } => {
    if (!value)
      return {
        isThermostat: false,
        isMilesight: false,
        isSinglePhaseMeter: false,
        isLoadController: false,
      };

    const deviceType = getEnergyDeviceTypeData(value, energyDeviceTypes);
    if (!deviceType)
      return {
        isThermostat: false,
        isMilesight: false,
        isSinglePhaseMeter: false,
        isLoadController: false,
      };

    const isMilesight = deviceType.vendor === MILESIGHT_VENDOR;

    return {
      isThermostat: getIsThermostat(deviceType.vendor, deviceType.vendorModel),
      isMilesight,
      isSinglePhaseMeter:
        isMilesight &&
        ['ct101', 'ct103'].includes(deviceType.vendorModel.toLowerCase()),
      isLoadController:
        isMilesight && deviceType.vendorModel.toLowerCase() === 'ws558',
    };
  };

  const getIsThermostat = (vendor: string, vendorModel: string): boolean => {
    const thermostatVendors = [ECOBEE_VENDOR, MEKI_VENDOR];
    // TODO: This is a mock for the Milesight thermostat, remove when the new value is ready
    return (
      thermostatVendors.includes(vendor) ||
      (vendor === MILESIGHT_VENDOR && vendorModel.toLowerCase() === 'wt201')
    );
  };

  const handleDeviceTypeChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: { value: string },
  ) => {
    setFormData((prev) => ({
      ...prev,
      selectedEnergyDeviceType: data.value,
    }));

    if (data.value === SINGLE_PHASE_METER_KEY) {
      setFormData((prev) => ({
        ...prev,
        selectedEnergyDeviceType: data.value,
        selectedVendorDevice: undefined,
        selectedEquipment: undefined,
      }));
      filterVendorDevices(data.value);
      return;
    } else {
      clearSinglePhaseMeterFormData();
    }

    const deviceType = energyDeviceTypes?.find(
      ({ uuid }) => uuid === data.value,
    );

    if (!deviceType) {
      throw new Error(
        `Selected device type ${data.value} should exist in list of equipments!`,
      );
    }

    //TODO: Improve this logic to avoid setting form data multiple times
    if (!vendorDevices) {
      throw new Error(`Vendor devices should be populated!`);
    }
    setFormData((prev) => ({
      ...prev,
      selectedVendorDevice: undefined,
      selectedEquipment: undefined,
    }));
    if ([MEKI_VENDOR, ECOBEE_VENDOR].includes(deviceType.vendor)) {
      setFormData((prev) => ({
        ...prev,
        selectedEquipment: undefined,
      }));
    }
    filterVendorDevices(deviceType.vendor, deviceType.vendorModel);
    setFormData((prev) => ({
      ...prev,
      selectedEnergyDeviceType: data.value,
    }));
  };

  useEffect(() => {
    const getEnergyDeviceTypes = async () => {
      const deviceTypes = await energyDeviceService.getDeviceTypes();
      setEnergyDeviceTypes(deviceTypes);
    };
    getEnergyDeviceTypes();
  }, []);

  useEffect(() => {
    if (!vendorDevices || !energyDeviceTypes) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      selectedVendorDevice: undefined,
    }));

    if (selectedUUIDs.deviceTypeUUID) {
      if (!selectedEnergyDeviceData) {
        throw new Error('Device type should exist');
      }

      const selectedDeviceVendor = selectedEnergyDeviceData.vendor;

      if ([ECOBEE_VENDOR, MCCI_VENDOR].includes(selectedDeviceVendor)) {
        setFormData((prev) => ({
          ...prev,
          selectedEquipment: undefined,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          occupiedCoolSetPoint: undefined,
          occupiedHeatSetPoint: undefined,
          unoccupiedCoolSetPoint: undefined,
          unoccupiedHeatSetPoint: undefined,
        }));
      }
      filterVendorDevices(selectedDeviceVendor, selectedEnergyDeviceData.vendorModel);

      return;
    }
    setDropdownVendorDevices([]);
  }, [vendorDevices]);

  const equipmentDisabled =
    !energyDeviceTypes ||
    !formData.selectedEnergyDeviceType ||
    energyDeviceTypes.some(
      ({ uuid, vendor }) =>
        (formData.selectedEnergyDeviceType === uuid &&
          vendor === ECOBEE_VENDOR) ||
        (formData.selectedEnergyDeviceType === uuid &&
          vendor === MEKI_VENDOR) ||
        (formData.selectedEnergyDeviceType === uuid && vendor === MCCI_VENDOR),
    );

  const selectedFullEnergyDeviceType = selectedEnergyDeviceData;

  const hasRequiredSetPointMetadata =
    selectedFullEnergyDeviceType?.vendor === ECOBEE_VENDOR ||
    selectedFullEnergyDeviceType?.vendor === MEKI_VENDOR
      ? formData.occupiedCoolSetPoint &&
        formData.occupiedHeatSetPoint &&
        formData.unoccupiedCoolSetPoint &&
        formData.unoccupiedHeatSetPoint &&
        formData.hvacMode
      : true;

  const hasRequiredEquipment =
    [SHELLY_VENDOR, ENGINKO_VENDOR].includes(
      selectedFullEnergyDeviceType?.vendor || '',
    ) && formData.selectedEquipment;

  const requiredSingleMeterFields = [
    'meterEquipmentType',
    'voltage',
    'multiplier',
    'equipmentName',
    'selectedVendorDevice',
  ];
  const canSubmitSinglePhaseMeter = requiredSingleMeterFields.every(
    (field) => !!formData[field as keyof FormDataType],
  );

  const canSubmitLoadController = formData.selectedEnergyDeviceType && getDeviceType(formData.selectedEnergyDeviceType).isLoadController && formData.selectedVendorDevice;

  const canSubmitMilesightThermostat = formData.selectedEnergyDeviceType && getDeviceType(formData.selectedEnergyDeviceType).isMilesight && getDeviceType(formData.selectedEnergyDeviceType).isThermostat && formData.selectedVendorDevice;

  const canSubmit =
    canSubmitMilesightThermostat ||
    canSubmitLoadController ||
    canSubmitSinglePhaseMeter ||
    (selectedFullEnergyDeviceType &&
      formData.selectedVendorDevice &&
      ([ECOBEE_VENDOR, MEKI_VENDOR, MCCI_VENDOR].includes(
        selectedFullEnergyDeviceType.vendor,
      ) ||
        hasRequiredEquipment) &&
      hasRequiredSetPointMetadata);

  const vendorDeviceOptions = useMemo(() => {
    return dropdownVendorDevices.map((device) => ({
      key: device.id,
      text: `${device.id} - ${device.name}`,
      value: device.id,
    }));
  }, [dropdownVendorDevices]);

  const addNewMeterEquipment = () => {
    const typeOfEquipment =
      formData.meterEquipmentType?.toLowerCase() || 'other';
    const equipment: EquipmentDto = {
      name: formData.equipmentName || '',
      locationUUID: selectedUUIDs.locationUUID,
      installation: 'other',
      installationType: '',
      equipmentType: typeOfEquipment,
      otherDescription: typeOfEquipment === 'other' ? 'Other' : '',
      companyUUID,
      meta: {
        temperatureMinThreshold: null,
        temperatureMaxThreshold: -17.78, // 0 degrees Fahrenheit
        deviceSerialNumber: '',
        status: 'active',
        humidityMinThreshold: null,
        humidityMaxThreshold: null,
      },
    };
    return addEquipment(equipment);
  };

  const onSubmit = async () => {
    // Early return if the form is not filled out
    if (!formData.selectedEnergyDeviceType || !formData.selectedVendorDevice) {
      return;
    }
    //TODO: figure out what to do with new equipment
    let newEquipment;
    if (canSubmitSinglePhaseMeter && formData.newEquipment) {
      // create new equipment for attaching device
      newEquipment = await addNewMeterEquipment();
    }

    // Create device from form data
    setAddDeviceLoading(true);
    const energyDeviceUUID =
      formData.meterEquipmentType === HVAC_EQUIPMENT_TYPE &&
      !formData.newEquipment
        ? formData.selectedEquipmentUUID
        : undefined;
    const metadata = canSubmitSinglePhaseMeter
      ? {
          equipmentName: formData.equipmentName,
          referenceVoltage: formData.voltage,
          ctType: selectedEnergyDeviceData?.vendorModel?.toUpperCase(),
          model: selectedEnergyDeviceData?.vendorModel,
          currentMultiplier: Number(formData.multiplier),
          ...(energyDeviceUUID ? { energyDeviceUUID } : {}),
        }
      : {
          occupiedCoolSetPoint: Number(formData.occupiedCoolSetPoint),
          occupiedHeatSetPoint: Number(formData.occupiedHeatSetPoint),
          unoccupiedCoolSetPoint: Number(formData.unoccupiedCoolSetPoint),
          unoccupiedHeatSetPoint: Number(formData.unoccupiedHeatSetPoint),
          hvacMode: formData.hvacMode,
          name: formData.customName,
          occupiedFanMode: formData.fanModeOccupied,
          unoccupiedFanMode: formData.fanModeUnoccupied,
          timezone: formData.timeZone,
          screenLock: formData.screenLock,
          localOverrideDuration: Number(formData.localOverrideDuration),
          customOccupiedHours: formData.customByDay ?
            formData.customByDayValues :
            Array.from({ length: 7 }, (_, i) => ({
                day: i,
                start: formData.occupiedHoursStart,
                end: formData.occupiedHoursStop,
            })),
          deadband: Number(formData.deadband),
          corporateOccupiedCoolSetPoint: Number(
            formData.corpStandOccupiedCoolSetPoint,
          ),
          corporateOccupiedHeatSetPoint: Number(
            formData.corpStandOccupiedHeatSetPoint,
          ),
          corporateUnoccupiedCoolSetPoint: Number(
            formData.corpStandUnoccupiedCoolSetPoint,
          ),
          corporateUnoccupiedHeatSetPoint: Number(
            formData.corpStandUnoccupiedHeatSetPoint,
          ),
          autoTransition: formData.autoTransition,
          autoTransitionStart: formData.autoTransitionStart,
          model: selectedEnergyDeviceData?.vendorModel,
        };
    /*
    occupiedFanMode: z.nativeEnum(FanMode).optional(),
    unoccupiedFanMode: z.nativeEnum(FanMode).optional(),
    timezone: z.string().optional(),
    screenLock: z.boolean().optional(),
    deadband: z.number().optional(),
    localOverrideDuration: z.number().optional(),
    deviceId: z.string().optional(),
    zoneSensor: z.string().optional(),
    customOccupiedHours: z
        .array(
            z.object({
                day: z.number().min(0).max(6),
                start: z.string(),
                end: z.string(),
            }),
        )
        .optional(),
    */

    const selectedDeviceType = getDeviceType(formData.selectedEnergyDeviceType);
    if (!selectedUUIDs.deviceTypeUUID) {
      throw new Error('No device uuid!');
    }

    if (selectedDeviceType.isLoadController) {
      const loadController: EnergyLoadControllerType = {
        identifier: formData.selectedVendorDevice,
        locationUuid: selectedUUIDs.locationUUID,
        timezone: formData.timeZone || 'America/Los_Angeles',
        pins: pins.reduce((acc, pin) => {
          return pin.name ?{
            ...acc,
            [pin.pin]: {
              name: pin.name,
              schedule: Object.values(pin.schedule).reduce((acc2, schedule, index) => {
                return schedule.on && schedule.off ? {
                  ...acc2,
                  [index]: schedule,
                } : acc2;
              }, {}),
            },
          }: acc;
        }, {}),
        typeUuid: selectedUUIDs.deviceTypeUUID,
      };
      const response = await energyLoadControllerService.commissionLoadController(loadController);
      setLoadControllers([...loadControllers, response]);
      setAddDeviceLoading(false);
      toast.success('Load controller added!');
      setPins(setInitPins);
      return;
    }

    const device = await energyDeviceService.create({
      locationUUID: selectedUUIDs.locationUUID,
      meterUUID: selectedUUIDs.meterUUID,
      deviceTypeUUID: selectedUUIDs.deviceTypeUUID,
      vendorId: formData.selectedVendorDevice,
      //TODO: use the new equipment uuid here
      equipmentUUID:
        canSubmitSinglePhaseMeter &&
        formData.meterEquipmentType !== HVAC_EQUIPMENT_TYPE
          ? (
            newEquipment?.uuid || 
            formData.selectedEquipmentUUID)
          : formData.selectedEquipment,
      metadata:
        selectedDeviceType.isThermostat || selectedDeviceType.isSinglePhaseMeter
          ? metadata
          : {},
    });

    if (!device) {
      setAddDeviceLoading(false);
      toast.error('Could not create device!');
      return;
    }

    // Get device data for adding to the list
    const { uuid, vendorId } = device;
    const equipment = newEquipment ? newEquipment : equipments?.find(
      ({ uuid }) => uuid === (formData.selectedEquipment || formData.selectedEquipmentUUID),
    );

    const deviceType = selectedEnergyDeviceData;
    if (!deviceType) {
      throw new Error('Could not find selected device type!');
    }
    const { vendor, vendorModel } = deviceType;
    const vendorDevice = vendorDevices?.find(
      ({ id }) => id === formData.selectedVendorDevice,
    );
    if (!vendorDevice) {
      throw new Error('Could not find selected vendor device!');
    }
    const { name: deviceName } = vendorDevice;
    const isThermostat = getIsThermostat(vendor, vendorModel);
    if (!isThermostat && !canSubmitSinglePhaseMeter && !equipment) {
      throw new Error('Could not find selected equipment!');
    }

    const energyDevice: EnergyDevice = isThermostat
      ? {
          equipmentType: HVAC_EQUIPMENT_TYPE,
          deviceType: `${capitalizeFirstLetter(vendor)} ${vendorModel}`,
          deviceId: vendorId,
          uuid,
          equipmentName: deviceName,
          vendor,
          deviceName,
          metadata,
        }
      : {
          equipmentType: formatEquipmentType(equipment, vendor, vendorModel),
          deviceType: `${capitalizeFirstLetter(vendor)} ${vendorModel}`,
          deviceId: vendorId,
          uuid,
          equipmentName: equipment?.name || formData.equipmentName ||'',
          vendor,
          deviceName,
          metadata,
        };

    // Add device to list and clear form
    onDeviceCreated([energyDevice, ...energyDevices]);
    if (!isThermostat || canSubmitSinglePhaseMeter) {
      clearForm();
    }

    if (isThermostat && !canSubmitSinglePhaseMeter) {
      openModal({
        size: 'tiny',
        header: 'Add Another Thermostat?',
        hasModalActions: false,
        content: EnergyDeviceAddModal({
          closeModal: hideModal,
          clearFormPartially: clearFormPartially,
          clearForm: clearForm,
        }),
      });
    }
  };

  return {
    energyDeviceTypes,
    dropdownVendorDevices: vendorDeviceOptions,
    addDeviceLoading,
    getDeviceType,
    handleDeviceTypeChange,
    canSubmit,
    onSubmit,
    equipmentDisabled,
    pins,
    setPins,
    loadControllers,
    handleLoadControllerEdit,
    formData,
    handleFormData,
    handleCustomField,
    handleCustomHoursField,
    handleLoadControllerDelete
  };
};
