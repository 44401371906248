import { Responsive } from 'semantic-ui-react';
import styled from 'styled-components';

export const LimitedContainer = styled.div`
    padding: 0 1%;
    width: 100%;

    @media only screen and (max-width: 995px) {
        padding: 5% 1%;
    }

    @media only screen and (max-width: 783px) {
        padding: 15% 1%;
    }
    
    /*
    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        padding: 5% 10%;
    }

    @media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
        padding: 5% 2%;
    }
    */
`;
