import { Axios } from '.';

const THERMA_API_URL =
  process.env.THERMA_API_HOST ?? 'http://localhost:8999';

interface EquipmentMeta {
  temperatureMinThreshold?: number | null;
  temperatureMaxThreshold?: number;
  humidityMinThreshold?: number | null;
  humidityMaxThreshold?: number | null;
  deviceSerialNumber?: string;
  status?: string;
}

export interface EquipmentDto {
  installation: 'free_standing' | 'built_in' | 'other';
  installationType: string;
  meta: EquipmentMeta;
  locationUUID: string;
  otherDescription: string;
  name: string;
  companyUUID: string;
  equipmentType: string;
}

export interface EquipmentResponse {
    name: string;
    locationUUID: string;
    equipmentTypeInstallation: string;
    installation: string;
    equipmentType: string;
    companyUUID: string;
    meta: EquipmentMeta;
    setPoint: number | null;
    deletedAt: string | null;
    otherDescription: string;
    installationTypeOther: string;
    id: number;
    uuid: string;
    createdAt: string;
    updatedAt: string;
  }

/**
 * Used for managing equipments
 * See https://api.qa.helloatka.com/api/docs/swagger-ui.html?urls.primaryName=equipment-service
 */
export class EquipmentService {
  public static async addEquipment({
    equipment,
    token,
  }: {
    equipment: EquipmentDto;
    token: string;
  }): Promise<EquipmentResponse> {
    //https://api.qa.helloatka.com/api/v2/equipments
    const request = await Axios.request(THERMA_API_URL).post(
      `/api/v2/equipments`,
      equipment,
      {
        headers: { Authorization: token},
      }
    );

    return request.data?.data ? request.data?.data : {};
  }
}
