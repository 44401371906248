import React, { FunctionComponent, useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { StoreContext, useDeviceService } from '../../store';
import { ListItem } from '../list';

export interface DeviceListProps {
    deviceUuids: string[];
}

export const DeviceList: FunctionComponent<DeviceListProps> = (props) => {
    const { store } = useContext(StoreContext);
    const { deviceUuids } = props;
    const { devices } = store.entities;
    const { deleteDevice } = useDeviceService();
    return (
        <div>
            {deviceUuids.length > 0 &&
                deviceUuids.map((deviceUuid, idx) => (
                    <ListItem
                        key={deviceUuid}
                        bottom={idx !== deviceUuids.length - 1}
                    >
                        <div className="bold flex-1">
                            {devices.byUUID[deviceUuid].serialNumber}
                        </div>
                        <div className="bold flex-1">
                            {devices.byUUID[deviceUuid].deviceType === 'dragino'
                                ? 'Dragino'
                                : 'Therma-Beta'}
                        </div>
                        <Button
                            circular
                            icon
                            onClick={() => deleteDevice(deviceUuid)}
                        >
                            <Icon size="large" className="icon-trash" />
                        </Button>
                    </ListItem>
                ))}
        </div>
    );
};
