import { useContext } from 'react';
import { SemanticCOLORS } from 'semantic-ui-react';

import { BaseModalButtons, ButtonEmphasis } from '../components/base-modal';
import { ModalContext } from '../contexts';

export interface DialogConfig {
    header?: string;
    showCloseIcon?: boolean;
    content: string | JSX.Element;
    reverseButton?: boolean;
    onConfirm: () => void;
    onConfirmColor?: SemanticCOLORS;
    onConfirmContent?: string;
    onCancel?: () => void;
    onCancelColor?: SemanticCOLORS;
    onCancelContent?: string;
    onCancelEmphasis?: ButtonEmphasis;
    disableButtonsOnQuery?: string;
}

export const useConfirmDialog = () => {
    const { openModal, hideModal } = useContext(ModalContext);

    function openDialog({
        content,
        header = '',
        onCancel,
        onCancelColor = 'blue',
        onCancelContent = 'Cancel',
        onCancelEmphasis = 'secondary',
        onConfirm,
        onConfirmColor = 'blue',
        onConfirmContent = 'Confirm',
        reverseButton = true,
        showCloseIcon = true,
        disableButtonsOnQuery = '',
    }: DialogConfig) {
        const confirmButton: BaseModalButtons = {
            color: onConfirmColor,
            content: onConfirmContent,
            onClick: () => {
                if (onConfirm) {
                    return onConfirm();
                }
            },
        };
        const cancelButton: BaseModalButtons = {
            color: onCancelColor,
            content: onCancelContent,
            emphasis: onCancelEmphasis,
            onClick: () => {
                if (!onCancel) {
                    return hideModal();
                }
                onCancel();
            },
        };

        openModal({
            buttons: reverseButton
                ? [confirmButton, cancelButton]
                : [cancelButton, confirmButton],
            closeIcon: showCloseIcon,
            content,
            header,
            size: 'tiny', // since this is a confirmation dialog, we'll set it on a single size.
            disableButtonsOnQuery,
        });
    }

    function errorDialog({ content, header }: DialogConfig) {
        openModal({
            content,
            header,
            size: 'tiny',
        });
    }

    function closeDialog() {
        hideModal();
    }

    return {
        closeDialog,
        openDialog,
        errorDialog,
    };
};
