import { ButtonContainer, FormDropdown, FormInput } from '@coinspect/ui';
import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form } from 'semantic-ui-react';
import { transpileModule } from 'typescript';

import { AuthContext, ModalContext } from '../../contexts';
import { ChirpstackService, GatewayAssetModel } from '../../services';
import { fetchLocationsAndCompanies } from '../../services/energy-billing-service';
import HologramService from '../../services/hologram-service';
import { useGatewayAssetsService } from '../../store';
import {
    Company,
    EnergyAndMonitoringData,
    MonitoringLocation,
} from '../pages/energy-billing-form-page/types';

interface GatewayAssetForm {
    name: string;
    vendor: string;
    gatewayid: string;
    sim: string;
    uuid: string;
    companyUuid?: string;
    companyName?: string;
}

export const EditGatewayAssetForm: FunctionComponent<GatewayAssetForm> = (
    props,
) => {
    const { hideModal } = useContext(ModalContext);

    const { name, vendor, gatewayid, sim, uuid, companyName } = props;

    const {
        checkIfUniqueGatewayName,
        updateGatewayStateAndData,
        checkIfUniqueGatewayId,
    } = useGatewayAssetsService();

    const formMethods = useForm<GatewayAssetForm>({
        mode: 'onChange',
    });

    const isNameLegal = (gatewayName: string) => {
        const validCharacters = /^[A-Za-z0-9\-]$/;

        for (let i = 0; i < gatewayName.length; i++) {
            if (!validCharacters.test(gatewayName[i])) {
                return false;
            } else {
                continue;
            }
        }

        return true;
    };

    const onSubmit = formMethods.handleSubmit(async (data) => {
        const { name } = data;
        data.uuid = uuid;

        const nameUpdateSuccess = (message: string) =>
            toast.success(message, { autoClose: 5000, position: 'top-center' });
        const nameUpdateFail = (message: string) =>
            toast.error(message, { autoClose: 5000, position: 'top-center' });

        const uniqueGatewayId = await checkIfUniqueGatewayId(gatewayid);
        if (!uniqueGatewayId) {
            formMethods.setError('gatewayid', 'noDuplicateID');
        }

        const gatewayAsset = { ...data, uuid } as GatewayAssetModel;

        /**
         * HACK:
         * The unique checker for gateway name doesn't also check against the UUID
         * So we're just gonna check if the name is the same and skip the unique checking
         */
        const isEditingSelfNoChanges = name === props.name;

        let uniqueName = await checkIfUniqueGatewayName(name);
        if (!isEditingSelfNoChanges && !uniqueName) {
            formMethods.setError('name', 'noDuplicateName');
            const message = 'Gateway name already exists.';
            nameUpdateFail(message);
        }

        uniqueName = isEditingSelfNoChanges;

        const updatedGateway = await updateGatewayStateAndData(gatewayAsset);

        if (!isNameLegal(name)) {
            formMethods.setError('name', 'nameNotLegal');
            const message =
                'Failed to update device name in Chirpstack. Name may only include numbers, letters, and dashes.';
            nameUpdateFail(message);
        }

        if (updatedGateway && uniqueName && isNameLegal(name)) {
            const { name: newName, sim } = gatewayAsset;

            try {
                await ChirpstackService.updateChirpstackName(
                    gatewayid,
                    newName,
                );
                const message =
                    'Successfully updated device name in Chirpstack';
                nameUpdateSuccess(message);
            } catch (error) {
                const message = 'Failed to update device name in Chirpstack';
                nameUpdateFail(message);
            }
            try {
                const deviceId = (
                    await HologramService.getHologramDeviceId(sim)
                ).data.deviceId;
                await HologramService.updateHologramName(deviceId, newName);
                const message = 'Successfully updated device name in Hologram';
                nameUpdateSuccess(message);
                formMethods.reset();
                hideModal();
            } catch {
                formMethods.setError('sim', 'invalidSim');
                const message =
                    'Failed to update device name in Hologram. Please enter a valid SIM number.';
                nameUpdateFail(message);
            }
        }
    });

    const { idToken } = useContext(AuthContext);
    const [companies, setCompanies] = useState<Company[]>([]);

    useEffect(() => {
        const getCompanies = async () => {
            const length = 0;
            const result = await fetchLocationsAndCompanies(idToken);
            // Ensuring each location has a company
            const allLocations = result.filter(
                (location: EnergyAndMonitoringData) =>
                    location.monitoringLocation &&
                    location.monitoringLocation.company,
            );

            // Merge energy object and monitoring location object
            const locations: MonitoringLocation[] = allLocations
                .filter((location: EnergyAndMonitoringData) =>
                    location.monitoringLocation && location.energyLocation
                        ? true
                        : false,
                )
                .map((location: EnergyAndMonitoringData) => ({
                    ...location.monitoringLocation,
                    ...location.energyLocation,
                }));

            // Gets company from each location, verifies company is not undefined, and companies aren't duplicated
            const companies = allLocations
                .map((location: EnergyAndMonitoringData) => {
                    if (
                        location.monitoringLocation &&
                        location.monitoringLocation.company
                    ) {
                        return location.monitoringLocation.company;
                    }
                })
                .filter(
                    (company: Company | undefined): company is Company =>
                        !!company,
                )
                .filter((company, index, self) => {
                    return (
                        index ===
                        self.findIndex(
                            (otherCompany) => otherCompany.id === company.id,
                        )
                    );
                });

            if (companies.length && locations.length) {
                setCompanies(companies);

                const foundCompany = companies.filter(
                    ({ name }) => companyName === name,
                )[0];
                formMethods.setValue('companyUuid', foundCompany.uuid);
            }
        };
        getCompanies();
    }, []);

    return (
        <FormContext {...formMethods}>
            <Form onSubmit={onSubmit}>
                <FormDropdown
                    label="Company"
                    name="companyUuid"
                    options={companies.map((company) => ({
                        key: company.uuid,
                        text: company.name,
                        value: company.uuid,
                    }))}
                    selection
                    fluid
                    customErrors={{ required: 'Company is required.' }}
                />
                <FormInput
                    label="Name"
                    name="name"
                    type="text"
                    defaultValue={name}
                    required
                    customErrors={{
                        required: 'Gateway Name is required',
                        noDuplicateName: 'Gateway Name already exists.',
                        notUpdatedChirpstack:
                            'Gateway was not updated in Chirpstack.',
                        nameNotLegal:
                            'Name may only include letters, numbers, and dashes.',
                    }}
                />

                <FormInput
                    label="Sim"
                    name="sim"
                    type="text"
                    defaultValue={sim}
                    required
                    customErrors={{
                        required: 'Sim is required',
                        invalidSim: 'Please enter a valid SIM number.',
                    }}
                />

                <FormInput
                    label="Vendor"
                    name="vendor"
                    type="text"
                    defaultValue={vendor}
                    required
                    customErrors={{ required: 'Vendor is required' }}
                />
                <ButtonContainer right>
                    <Button
                        primary
                        type="submit"
                        disabled={formMethods.formState.isSubmitting}
                        loading={formMethods.formState.isSubmitting}
                    >
                        Submit
                    </Button>
                </ButtonContainer>
            </Form>
        </FormContext>
    );
};
