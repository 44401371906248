import React, { FunctionComponent, useMemo } from 'react';
import { Button, Dropdown, Form, Header, Loader } from 'semantic-ui-react';

import {
  EquipmentType,
  VendorDeviceType,
} from '../../../../services/energy-device-service';
import { EnergyEquipmentShiftingType } from '../../../../services/energy-equipment-shifting-service';
import { EnergyLocation } from '../../../../services/energy-location-service';
import { EnergyDevice } from '../hooks/useEnergyDeviceService';
import { ContentWrapper } from '../index';
import {
  ButtonWrapper,
  DeviceFormContainer,
  InputContainer,
  InputContainerBody,
  ListLabelWrapper,
  ListNumberWrapper,
  ListTextWrapper,
  LoaderWrapper,
  TableWrapper,
} from '../styles';
import EnergyDeviceCheckbox from './components/energy-device-checkbox';
import EnergyDeviceDropdown from './components/energy-device-dropdown';
import EnergyDeviceFormRow from './components/energy-device-form-row';
import EnergyDeviceInput from './components/energy-device-input';
import EnergyDeviceLabelDate from './components/energy-device-label-date';
import EnergyDeviceLabelInput from './components/energy-device-label-input';
import EnergyDeviceLabelTime from './components/energy-device-label-time';
import EnergyDeviceLoadController from './components/energy-device-load-controller';
import EnergyDeviceSetPoints from './components/energy-device-set-points';
import {
  EnergyDeviceTable,
  FAN_MODES,
  HVAC_MODES,
} from './components/energy-device-table';
import EnergyLoadControllerTable from './components/energy-load-controller-table';
import SinglePhaseMeterFields from './components/single-phase-meter-fields';
import { ON_OFF_OPTIONS, WEEKDAYS } from './constants';
import { useEnergyDeviceForm } from './use-energy-device-form';
import { getTimezones } from './utils';

interface EnergyDeviceFormProps {
  equipments: EquipmentType[] | null;
  energyDevices: EnergyDevice[];
  energyDevicesLoading: boolean;
  handleDeviceDeleted: (device: EnergyDevice) => Promise<void>;
  deleteDeviceLoading: boolean;
  energyLocation: EnergyLocation;
  onDeviceCreated: (devices: EnergyDevice[]) => void;
  vendorDevices: VendorDeviceType[];
  vendorDevicesLoading: boolean;
  energyEquipmentShiftingLoading: boolean;
  energyEquipmentShifting: EnergyEquipmentShiftingType[];
  onEquipmentShiftingUpdate: Function;
  onDeviceUpdated: Function;
  companyUUID: string;
}

export const EnergyDeviceForm: FunctionComponent<EnergyDeviceFormProps> = ({
  equipments,
  energyDevices,
  handleDeviceDeleted,
  deleteDeviceLoading,
  energyLocation,
  onDeviceCreated,
  vendorDevices,
  energyEquipmentShiftingLoading,
  energyEquipmentShifting,
  onEquipmentShiftingUpdate,
  onDeviceUpdated,
  companyUUID,
}) => {
  const {
    energyDeviceTypes,
    dropdownVendorDevices,
    addDeviceLoading,
    getDeviceType,
    handleDeviceTypeChange,
    canSubmit,
    onSubmit,
    equipmentDisabled,
    pins,
    setPins,
    loadControllers,
    handleLoadControllerEdit,
    formData,
    handleFormData,
    handleCustomField,
    handleCustomHoursField,
    handleLoadControllerDelete,
  } = useEnergyDeviceForm({
    equipments,
    energyDevices,
    energyLocation,
    onDeviceCreated,
    vendorDevices,
    companyUUID,
  });

  const timezones = useMemo(() => {
    const tz = getTimezones();

    return tz.map(({ text, value }) => ({
      key: value,
      text,
      value,
    }));
  }, []);

  const { isThermostat, isMilesight, isSinglePhaseMeter, isLoadController } = getDeviceType(
    formData.selectedEnergyDeviceType ?? null,
  );

  return (
    <>
      <Form onSubmit={onSubmit}>
        <DeviceFormContainer>
          <ContentWrapper>
            <ListLabelWrapper>
              <ListNumberWrapper>
                <Header size="tiny">{'2.'}</Header>
              </ListNumberWrapper>
              <ListTextWrapper>
                <Header size="tiny">
                  {
                    'Enter Therma device (Smart Thermostat or Smart Switch) details.'
                  }
                </Header>
              </ListTextWrapper>
            </ListLabelWrapper>
            <InputContainer>
              <Header as="h5">Therma Device</Header>
              <InputContainerBody>
                <EnergyDeviceFormRow label="Type:">
                  <EnergyDeviceDropdown
                    energyDeviceTypes={energyDeviceTypes}
                    selectedEnergyDeviceType={formData.selectedEnergyDeviceType}
                    handleDeviceTypeChange={handleDeviceTypeChange}
                  />
                </EnergyDeviceFormRow>
                {isSinglePhaseMeter && (
                  <SinglePhaseMeterFields
                    energyDevices={energyDevices}
                    equipments={equipments}
                    handleFormData={handleFormData}
                    handleCustomField={handleCustomField}
                    formData={formData}
                  />
                )}
                {isMilesight && isLoadController && (
                  <>
                    <EnergyDeviceFormRow label="Time Zone">
                      <Dropdown
                        options={timezones}
                        search
                        clearable
                        required
                        placeholder="Select Time Zone"
                        name="time zone"
                        selection
                        fluid
                        value={formData.timeZone}
                        onChange={(
                          _e: unknown,
                          { value }: { value: string },
                        ) => handleCustomField('timeZone', value)}
                      />
                    </EnergyDeviceFormRow>
                    <EnergyDeviceLoadController pins={pins} setPins={setPins} />
                  </>
                )}
                {isThermostat && (
                  <>
                    <EnergyDeviceInput
                      title="Custom Name:"
                      value={formData.customName}
                      name="customName"
                      handleChange={handleFormData}
                    />
                    <EnergyDeviceSetPoints
                      title={
                        isMilesight
                          ? 'Original Occ. Set Points:'
                          : 'Occupied Set Points:'
                      }
                      heatName="occupiedHeatSetPoint"
                      coolName="occupiedCoolSetPoint"
                      heatSetPoint={formData.occupiedHeatSetPoint}
                      coolSetPoint={formData.occupiedCoolSetPoint}
                      handleChange={handleFormData}
                    />
                    <EnergyDeviceSetPoints
                      title={
                        isMilesight
                          ? 'Original Unocc. Set Points:'
                          : 'Unoccupied Set Points:'
                      }
                      heatName="unoccupiedHeatSetPoint"
                      coolName="unoccupiedCoolSetPoint"
                      heatSetPoint={formData.unoccupiedHeatSetPoint}
                      coolSetPoint={formData.unoccupiedCoolSetPoint}
                      handleChange={handleFormData}
                    />

                    {isMilesight && isThermostat && (
                      <>
                        <EnergyDeviceSetPoints
                          title="Corp. Stand. Occ. Set Points:"
                          heatName="corpStandOccupiedHeatSetPoint"
                          coolName="corpStandOccupiedCoolSetPoint"
                          heatSetPoint={formData.corpStandOccupiedHeatSetPoint}
                          coolSetPoint={formData.corpStandOccupiedCoolSetPoint}
                          handleChange={handleFormData}
                        />
                        <EnergyDeviceSetPoints
                          title="Corp. Stand. Unocc. Set Points:"
                          heatName="corpStandUnoccupiedHeatSetPoint"
                          coolName="corpStandUnoccupiedCoolSetPoint"
                          heatSetPoint={
                            formData.corpStandUnoccupiedHeatSetPoint
                          }
                          coolSetPoint={
                            formData.corpStandUnoccupiedCoolSetPoint
                          }
                          handleChange={handleFormData}
                        />
                      </>
                    )}
                    <EnergyDeviceFormRow label="HVAC Mode:">
                      <Dropdown
                        clearable
                        required
                        placeholder="Select Mode"
                        options={HVAC_MODES}
                        value={formData.hvacMode}
                        onChange={(_e: unknown, { value }: { value: string }) =>
                          handleCustomField('hvacMode', value)
                        }
                        selection
                        fluid
                        disabled={!formData.selectedEnergyDeviceType}
                      />
                    </EnergyDeviceFormRow>
                    {isMilesight && isThermostat && (
                      <>
                        <EnergyDeviceFormRow label="Fan Mode">
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '8px' }}>
                            <label>Occupied:</label>
                            <Dropdown
                              clearable
                              required
                              placeholder="Mode"
                              options={FAN_MODES}
                              value={formData.fanModeOccupied}
                              onChange={(_e: unknown, { value }: { value: string }) =>
                                handleCustomField('fanModeOccupied', value)
                              }
                              selection
                              fluid
                              disabled={!formData.selectedEnergyDeviceType}
                            />
                            <label>Unoccupied:</label>
                            <Dropdown
                              clearable
                              required
                              placeholder="Mode"
                              options={FAN_MODES}
                              value={formData.fanModeUnoccupied}
                              onChange={(_e: unknown, { value }: { value: string }) =>
                                handleCustomField('fanModeUnoccupied', value)
                              }
                              selection
                              fluid
                              disabled={!formData.selectedEnergyDeviceType}
                            />
                          </div>
                        </EnergyDeviceFormRow>
                        <EnergyDeviceFormRow label="Time Zone">
                          <Dropdown
                            options={timezones}
                            search
                            clearable
                            required
                            placeholder="Select Time Zone"
                            name="time zone"
                            selection
                            fluid
                            value={formData.timeZone}
                            onChange={(
                              _e: unknown,
                              { value }: { value: string },
                            ) => handleCustomField('timeZone', value)}
                          />
                        </EnergyDeviceFormRow>
                        <EnergyDeviceFormRow label="Occupied Hours">
                          {!formData.customByDay && (
                            <>
                              <EnergyDeviceLabelTime
                                prefix="Start"
                                name="occupiedHoursStart"
                                value={formData.occupiedHoursStart}
                                handleChange={handleFormData}
                              />
                              <EnergyDeviceLabelTime
                                prefix="Stop"
                                name="occupiedHoursStop"
                                value={formData.occupiedHoursStop}
                                handleChange={handleFormData}
                              />
                            </>
                          )}
                          <EnergyDeviceCheckbox
                            prefix="Custom by Day"
                            checked={formData.customByDay}
                            onChange={(
                              _e: unknown,
                              { checked }: { checked: boolean },
                            ) => handleCustomField('customByDay', checked)}
                          />
                        </EnergyDeviceFormRow>
                          {formData.customByDay && (
                            <div>
                              {WEEKDAYS.map((day, index) => (
                                <EnergyDeviceFormRow label={day} key={day}>
                                  <EnergyDeviceLabelTime
                                    prefix="Start"
                                    name="occupiedHoursStart"
                                    value={formData.customByDayValues[index].start}
                                    handleChange={handleCustomHoursField(index, 'start')}
                                  />
                                  <EnergyDeviceLabelTime
                                    prefix="Stop"
                                    name="occupiedHoursStop"
                                    value={formData.customByDayValues[index].end}
                                    handleChange={handleCustomHoursField(index, 'end')}
                                  />
                                </EnergyDeviceFormRow>
                              ))}
                            </div>
                          )}
                        <EnergyDeviceFormRow label="Auto Transition">
                          <EnergyDeviceCheckbox
                            checked={formData.autoTransition}
                            onChange={(
                              _e: unknown,
                              { checked }: { checked: boolean },
                            ) => handleCustomField('autoTransition', checked)}
                          />
                          <EnergyDeviceLabelDate
                            prefix="Start"
                            name="autoTransitionStart"
                            value={formData.autoTransitionStart}
                            handleChange={handleFormData}
                            disabled={!formData.autoTransition}
                          />
                        </EnergyDeviceFormRow>
                        <EnergyDeviceFormRow label="Screen Lock">
                          <Dropdown
                            clearable
                            required
                            disabled
                            options={ON_OFF_OPTIONS}
                            selection
                            fluid
                            value={formData.screenLock}
                            onChange={(
                              _e: unknown,
                              { value }: { value: boolean },
                            ) => handleCustomField('screenLock', value)}
                          />
                        </EnergyDeviceFormRow>
                        {/* TODO: Add these options back once available */}
                        {/* <EnergyDeviceFormRow label="Deadband">
                          <EnergyDeviceLabelInput
                            suffix="&deg;F"
                            name="deadband"
                            value={formData.deadband}
                            handleChange={handleFormData}
                          />
                        </EnergyDeviceFormRow>
                        <EnergyDeviceFormRow label="Local Override Duration">
                          <EnergyDeviceLabelInput
                            suffix={'Minutes'}
                            name="localOverrideDuration"
                            value={formData.localOverrideDuration}
                            handleChange={handleFormData}
                          />
                        </EnergyDeviceFormRow> */}
                      </>
                    )}
                  </>
                )}
                <EnergyDeviceFormRow
                  label={
                    isMilesight || isSinglePhaseMeter
                      ? 'Device ID:'
                      : 'Vendor Device ID:'
                  }
                >
                  <Dropdown
                    clearable
                    required
                    placeholder="Select Vendor Device"
                    options={dropdownVendorDevices}
                    name="vendorDevice"
                    selection
                    fluid
                    value={formData.selectedVendorDevice}
                    onChange={(_e: unknown, { value }: { value: string }) =>
                      handleCustomField('selectedVendorDevice', value)
                    }
                    disabled={!formData.selectedEnergyDeviceType}
                    search={(options: { text: string }[], query: string) =>
                      options.filter((option) =>
                        option.text
                          .toLowerCase()
                          .split(/\s-\s/)
                          .some((str) => str.startsWith(query.toLowerCase())),
                      )
                    }
                  />
                </EnergyDeviceFormRow>
                {!isMilesight && (
                  <EnergyDeviceFormRow
                    label="Equipment:"
                  >
                    {equipments ? (
                      <Dropdown
                        clearable
                        required
                        placeholder="Select Equipment"
                        options={equipments.map((equipment) => ({
                          key: equipment.uuid,
                          text: equipment.name,
                          value: equipment.uuid,
                        }))}
                        name="equipment"
                        selection
                        fluid
                        disabled={equipmentDisabled}
                        value={formData.selectedEquipment}
                        onChange={(_e: unknown, { value }: { value: string }) =>
                          handleCustomField('selectedEquipment', value)
                        }
                      />
                    ) : (
                      <LoaderWrapper>
                        <Loader
                          active
                          size="tiny"
                          style={{
                            position: 'relative',
                          }}
                        />
                      </LoaderWrapper>
                    )}
                  </EnergyDeviceFormRow>
                )}
              </InputContainerBody>
            </InputContainer>
          </ContentWrapper>
          <ContentWrapper>
            <ListLabelWrapper>
              <ListNumberWrapper>
                <Header size="tiny">{'3.'}</Header>
              </ListNumberWrapper>
              <ListTextWrapper>
                <Header size="tiny">{'Add the Therma Device.'}</Header>
              </ListTextWrapper>
            </ListLabelWrapper>
            <ButtonWrapper>
              <Button
                fluid
                color="green"
                type="submit"
                disabled={!canSubmit || addDeviceLoading}
              >
                Add Device
              </Button>
            </ButtonWrapper>
          </ContentWrapper>
        </DeviceFormContainer>
      </Form>
      <TableWrapper>
        {energyDevices.length ? (
          <EnergyDeviceTable
            energyDevices={energyDevices}
            onDeviceDelete={handleDeviceDeleted}
            deleteLoading={deleteDeviceLoading}
            energyEquipmentShiftingLoading={energyEquipmentShiftingLoading}
            energyEquipmentShifting={energyEquipmentShifting}
            onEquipmentShiftingUpdate={onEquipmentShiftingUpdate}
            onDeviceUpdated={onDeviceUpdated}
          />
        ) : (
          <></>
        )}
      </TableWrapper>
      {!!loadControllers.length && (
        <TableWrapper>
          <EnergyLoadControllerTable
            loadControllers={loadControllers}
            handleEdit={handleLoadControllerEdit}
            handleDelete={handleLoadControllerDelete}
          />
        </TableWrapper>
      )}
    </>
  );
};
