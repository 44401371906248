import { updatedSchedule } from "components/pages/energy-system-commissioning/occupied-hours-form";
import { atkaRequest } from "../contexts";
import { AxiosResponse } from "axios";
export interface LocationSchedule {
    companyUUID: string;
    createdAt: string;
    day: Number;
    deletedAt: string; 
    endTime: string 
    entityType: string;
    entityUUID: string;
    id: Number;
    metadata: {};
    startTime: string;
    state: string;
    timezone: string;
    type: string;
    updatedAt: string;
    uuid: string;
}

export class ScheduleService {
    public static async browseSchedules(
        token: string,
        companyUUID: string,
        locationUUID: string,
    ): Promise<AxiosResponse> {
        return atkaRequest.get(
            `v2/schedule/?entityUUID=${locationUUID}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
                params: {
                    companyUUID: companyUUID,
                },
            },
        );
    }

    public static async addSchedule(
        token: string,
        schedule: updatedSchedule,
    ): Promise<AxiosResponse> {
        return atkaRequest.post(
            `v2/schedule`,
            schedule,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        )
    }

    public static async deleteSchedule(
        token: string,
        scheduleUUID: string,
    ): Promise<AxiosResponse> {
        return atkaRequest.delete(
            `v2/schedule/${scheduleUUID}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        )
    }

    public static async updateSchedule(
        token: string,
        schedule: updatedSchedule,
        scheduleUUID: string,
    ): Promise<AxiosResponse> {
        return atkaRequest.put(
            `v2/schedule/${scheduleUUID}`,
            schedule,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        )
    }
}
