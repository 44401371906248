import React, { useContext, useState } from 'react';
import { Input, Button } from 'semantic-ui-react';
import { EnergyLCPinType } from 'services/energy-load-controller-service';
import { WEEKDAYS } from '../constants';
import styled from 'styled-components';
import { ModalContext } from '../../../../../contexts';

const StyledScheduleRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 2fr;
  margin: 8px 0;
`;

export const EnergyLoadControllerEditModal = ({pin, onEditPin} : {pin: EnergyLCPinType, onEditPin: Function}) => {
  const { hideModal } = useContext(ModalContext);
  const [pinName, setPinName] = useState<string>(pin ? pin.name : '');
  const [pinSchedule, setPinSchedule] = useState<EnergyLCPinType['schedule']>(() => {
    return WEEKDAYS.reduce((acc, _day, index) => ({
      ...acc,
      [index]: pin && pin.schedule[index] || { on: '', off: '' },
    }), {});
  });

  const handleChange = (_, data) => setPinName(data.value);
  const handleDateChange = (day, field) => (_, data) => {
    setPinSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        [field]: data.value,
      },
    }));
  };
  const onSave = () => {
    onEditPin({
      name: pinName,
      schedule: Object.values(pinSchedule).reduce((acc, schedule, index) => schedule.on && schedule.off ? {
        ...acc,
        [index]: schedule,
      }: acc, {}),
    });
    hideModal();
  };

  return (
    <div>
      <label>
        Name:
        <Input
            type="text"
            fluid
            name="name"
            value={pinName}
            onChange={handleChange}
          >
          <input />
        </Input>
      </label>
      {
        WEEKDAYS.map((day, index) => {
          const schedule = pinSchedule[index];
          return (
            <StyledScheduleRow key={index}>
              <label>{day}:</label>
              <label>On:&nbsp;
                <Input
                  type="time"
                  value={schedule.on}
                  onChange={handleDateChange(index, 'on')}
                  style={{ width: '75%' }}
                />
              </label>
              <label>Off:&nbsp;
                <Input
                  type="time"
                  value={schedule.off}
                  onChange={handleDateChange(index, 'off')}
                  style={{ width: '75%' }}
                />
              </label>
            </StyledScheduleRow>
          )
        })
      }
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px', marginTop: '16px' }}>
        {/* <Button color="red" fluid>Delete</Button> */}
        <Button color="green" fluid onClick={onSave}>Save</Button>
      </div>
    </div>
  );
};

export default EnergyLoadControllerEditModal;