import { DataStoreAction, QueryInProgress } from '../../store';

export function queryReducer(
    state: QueryInProgress | {},
    action: DataStoreAction,
) {
    const { type } = action;

    // reset isQueryInProgress on route change
    if (type === 'isQueryInProgress:reset') {
        return {};
    }

    // https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6

    /**
     * Supported Types
     * REQUEST - start of a request
     * FINALLY - end of a request
     *
     * Nice to have
     * SUCCESS - determine if request was successful
     * FAILURE - determine if requeest failed
     */
    const matches = /(.*)_(REQUEST|FINALLY)/.exec(type);

    // not a *_REQUEST / *_FINALLY actions, so we ignore them
    if (!matches) {
        return state;
    }

    /**
     * [location:add_REQUEST, location:add, REQUEST]
     */
    const [, requestName, requestState] = matches;

    return {
        ...state,
        [requestName]: requestState === 'REQUEST',
    };
}
