import {
    ButtonContainer,
    darkBlue80,
    FormDropdown,
    FormInput,
    LimitedContainer,
} from '@coinspect/ui';
import React, { FunctionComponent, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { DraginoService } from '../../services';
import { Card } from '../card';

interface DeviceForm {
    name: string;
    server: number;
}

const server = [
    { text: 'production', value: 3 },
    { text: 'development', value: 7 },
    { text: 'staging', value: 4 },
    { text: 'testing', value: 6 },
];

const Group = styled.div`
    margin-bottom: 14px;
`;

const GroupLabel = styled.div`
    margin-bottom: 6px;
    font-weight: 700;
    color: ${darkBlue80};
`;

export const ToolsPage: FunctionComponent<{}> = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [result, setResult] = useState('');
    const formMethods = useForm<DeviceForm>({
        mode: 'onChange',
    });

    const onSubmit = formMethods.handleSubmit(async ({ name, server }) => {
        try {
            setResult('');
            const unixStartDate = parseInt(
                (new Date(startDate).getTime() / 1000).toFixed(0),
            );
            const unixEndDate = parseInt(
                (new Date(endDate).getTime() / 1000).toFixed(0),
            );
            const {
                tags: { firmwareVersion },
                devEUI,
            } = await DraginoService.getDevice(name, server);
            const header = firmwareVersion === '1.8.0 test' ? 'A9' : '31';
            await DraginoService.requestBacklog(
                devEUI,
                unixStartDate,
                unixEndDate,
                header,
            );
            setResult('successfully downlinked a backlog request');
        } catch (e) {
            setResult(`error: ${e.message}`);
        }
    });

    return (
        <LimitedContainer>
            <Header as="h1">Request backlog from dragino</Header>
            <Card>
                <FormContext {...formMethods}>
                    <Form onSubmit={onSubmit} noValidate>
                        <FormInput
                            type="text"
                            label="Name:"
                            name="name"
                            required
                            customErrors={{ required: 'Name is required.' }}
                        />
                        <Group>
                            <GroupLabel>Start date: *</GroupLabel>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                            />
                        </Group>
                        <Group>
                            <GroupLabel>End date: *</GroupLabel>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                            />
                        </Group>

                        <FormDropdown
                            label="Server:"
                            name="server"
                            options={server}
                            selection
                            fluid
                            required
                            disabled={formMethods.formState.isSubmitting}
                            customErrors={{ required: 'Server is required.' }}
                        />
                        <ButtonContainer right>
                            <label>{result}</label>
                            <Button
                                primary
                                loading={formMethods.formState.isSubmitting}
                                type="submit"
                            >
                                Submit
                            </Button>
                        </ButtonContainer>
                    </Form>
                </FormContext>
            </Card>
        </LimitedContainer>
    );
};
