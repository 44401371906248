import { ButtonContainer } from '@coinspect/ui';
import React, {
    FunctionComponent,
    useEffect,
    useReducer,
    useState,
} from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Form,
    FormField,
    FormInput,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Label,
    Loader,
    Message,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { ListContainer, ListItem, StyledHeader } from '../..';
import {
    RealTots,
    RealTotsEquipment,
    TotsDevices,
    TotsEquipmentType,
} from '../../../data/real-tots-devices';
import { ControlDevice } from '../../../services/energy-device-service';
import dayjs from '../../../utils/date';
import useEnergyDeviceService from './useEnergyDeviceService';
import { useTaskService } from './useTaskService';
import { initValidationEngine } from './validationService';

const MINUTE = 60000;
const StyledContainer = styled(Container)`
    padding: 0 0 0 0;

    @media only screen and (max-width: 783px) {
        padding: 15% 10%;
    }

    @media only screen and (max-width: 558px) {
        padding: 20% 10%;
    }

    @media only screen and (max-width: 481px) {
        padding: 25% 10%;
    }
`;

// TOTS modes
const PRECOOL = 'precool';
const ECO = 'eco';
const NORMAL = 'normal';

const DEPLOYMENT = process.env.THERMALINK_DEPLOYMENT;
const equipment = RealTotsEquipment;
let filteredTots: TotsDevices[];
if (DEPLOYMENT) {
    filteredTots = RealTots.filter((device) => {
        if (device.deployment.includes(DEPLOYMENT.toLocaleLowerCase())) {
            return device;
        }
    });
} else {
    filteredTots = [RealTots[0]];
}

const StyledButtonContainer = styled(ButtonContainer)`
    padding: 0px 0px 0px;
`;

const DatepickerWrapper = styled.div`
    margin-bottom: 14px;
`;

const TotsLoaderContainer = styled.span`
    display: flex;
    justify-self: center;
    align-self: center;
    padding-left: 10px;
`;

const reducer = (state, action) => ({
    ...state,
    ...action,
    type: PRECOOL,
    error: '',
});

const initialState = {
    deviceUUID: '',
    equipmentUUID: '',
    submitting: false,
    startPrecoolModeDate: null,
    startEcoModeDate: null,
    setPoint: '',
    duration: '',
    ecoModeSetUnit: '',
    precoolSetUnit: '',
    ecoModeSetPoint: '',
    precoolSetPoint: '',
    normalModeSetPoint: '',
    precoolDuration: '',
    ecoModeDuration: '',
    error: '',
    type: '',
    tasksExist: false,
};

export const TotsUiPage: FunctionComponent<{}> = () => {
    const [totsLoading, setTotsLoading] = useState(true);
    const [selectedEquip, setEquip] = useState<TotsDevices>();
    const [totsList, setTotsList] = useState<TotsDevices[]>([]);
    const [ecoModeMinDate, setEcoModeMinDate] = useState(new Date());
    const [errors, setErrors] = useState(initValidationEngine());
    const taskService = useTaskService();
    const energyDeviceService = useEnergyDeviceService();
    const [formState, dispatch] = useReducer(
        (state, action) => reducer(state, action),
        { ...initialState },
    );

    const updateValidationEngine = (uuid: string) => {
        const equipmentType = equipment.find(
            (equipment) => equipment.uuid === uuid,
        )?.type as TotsEquipmentType;
        // "updates" the validation engine with the new equipment type
        setErrors(initValidationEngine({ equipmentType }));
    };

    const getTasks = async (uuid: string) => {
        const { data } = await taskService.getTasksForDevice(uuid);

        if (data.length === 0) {
            return;
        }

        dispatch({ tasksExist: true });

        const normalModeTask = data.find(
            ({ url }) => url.split('/').pop() === NORMAL,
        );

        const ecoModeTask = data.find(
            ({ url }) => url.split('/').pop() === ECO,
        );

        const precoolTask = data.find(
            ({ url }) => url.split('/').pop() === PRECOOL,
        );

        if (normalModeTask) {
            const { body: normalModeBody } = normalModeTask;

            const normalModeBodyJson = JSON.parse(normalModeBody);
            const { temperature: normalModeTemperature } = normalModeBodyJson;

            if (normalModeTemperature === undefined) {
                throw new Error(
                    'Normal Mode task should have temperature set!',
                );
            }

            dispatch({
                normalModeSetPoint: normalModeTemperature,
            });
        }

        if (ecoModeTask && normalModeTask) {
            const {
                scheduledDateTime: normalScheduledDateString,
            } = normalModeTask;
            const {
                scheduledDateTime: ecoScheduledDateString,
                body: ecoBody,
            } = ecoModeTask;

            const ecoModeBodyJson = JSON.parse(ecoBody);
            const { temperature: ecoTemperature } = ecoModeBodyJson;

            if (ecoTemperature === undefined) {
                throw new Error('Eco task should have temperature set!');
            }

            const ecoScheduledDateTime = new Date(ecoScheduledDateString);

            const normalScheduledDateTime = new Date(normalScheduledDateString);

            const ecoModeDuration =
                (normalScheduledDateTime.getTime() -
                    ecoScheduledDateTime.getTime()) /
                MINUTE;

            dispatch({
                ecoModeDuration,
                ecoModeSetPoint: ecoTemperature,
                startEcoModeDate: ecoScheduledDateTime,
            });
        }

        if (!precoolTask || !ecoModeTask) {
            return;
        }

        const { scheduledDateTime: ecoScheduledDateString } = ecoModeTask;

        const ecoScheduledDateTime = new Date(ecoScheduledDateString);

        const {
            scheduledDateTime: precoolScheduledDateString,
            body: precoolBody,
        } = precoolTask;
        const precoolModeBodyJson = JSON.parse(precoolBody);
        const { temperature: precoolTemperature } = precoolModeBodyJson;

        if (precoolTemperature === undefined) {
            throw new Error('Precool task should have temperature set!');
        }

        const precoolScheduledDateTime = new Date(precoolScheduledDateString);

        const precoolDuration =
            (ecoScheduledDateTime.getTime() -
                precoolScheduledDateTime.getTime()) /
            MINUTE;

        dispatch({
            startPrecoolModeDate: precoolScheduledDateTime,
            precoolDuration,
            precoolSetPoint: precoolTemperature,
        });
    };

    useEffect(() => {
        // TODO: get tasks for device
        dispatch({ ...initialState, deviceUUID: formState.deviceUUID });
        if (formState.deviceUUID) {
            getTasks(formState.deviceUUID);
        }
    }, [formState.deviceUUID]);

    useEffect(() => {
        if (formState.equipmentUUID) {
            updateValidationEngine(formState.equipmentUUID);
        }
    }, [formState.equipmentUUID]);

    useEffect(() => {
        const getTotsList = async () => {
            try {
                // do a request to get the list of tots
                // TODO: GET /device/by-vendor-ids/ query is vendorIds
                // (exchanged for uuids)
                const vendorIds = filteredTots.map(({ value }) => value);
                const devices = await energyDeviceService.getDevicesByVendorIds(
                    vendorIds,
                );
                setTotsList(
                    devices.data.map((device) => ({
                        ...device,
                        ...filteredTots.find(
                            (tot) => tot.value === device.vendorId,
                        ),
                        value: device.uuid,
                    })),
                );
                setTotsLoading(false);
            } catch (err) {
                console.error('failed to load TOTS:', err);
                dispatch({ error: 'Failed to load TOTS devices' });
                setTotsLoading(false);
            }
        };

        if (totsList.length <= 0) {
            getTotsList();
        }
    }, []);

    useEffect(() => {
        const {
            startPrecoolModeDate,
            precoolDuration,
            startEcoModeDate,
        } = formState;

        if (isNaN(precoolDuration) || precoolDuration === '') {
            if (startEcoModeDate) {
                dispatch({ startEcoModeDate: null });
            }
            return;
        }

        // Precool date/time will help dictate what ecomode date/time should be
        if (startPrecoolModeDate && precoolDuration) {
            const duration = Number(precoolDuration);
            const min = dayjs(
                typeof startPrecoolModeDate == 'object'
                    ? startPrecoolModeDate
                    : new Date(),
            )
                .add(duration, 'minute')
                .toDate();
            setEcoModeMinDate(min);
            dispatch({ startEcoModeDate: min });
        }
    }, [formState.startPrecoolModeDate, formState.precoolDuration]);

    const handleStartAutomation = async () => {
        if (formState.submitting || formState.tasksExist) {
            return;
        }
        const {
            deviceUUID,
            startPrecoolModeDate,
            precoolSetPoint,
            precoolDuration,
            ecoModeDuration,
            ecoModeSetPoint,
            startEcoModeDate,
            normalModeSetPoint,
        } = formState;
        dispatch({ submitting: true });
        const promises = [];
        let hasErrors = false;

        // Check if any errors exist
        for (const key in errors) {
            if (errors[key] && 'error' in errors[key] && errors[key].error) {
                hasErrors = true;
                break;
            }
        }

        if (hasErrors) {
            dispatch({ submitting: false });
            toast.warning('Please fix errors.');
            return;
        }

        // Check if any required fields are missing
        if (
            !deviceUUID ||
            !startEcoModeDate ||
            !ecoModeDuration ||
            !ecoModeSetPoint ||
            !normalModeSetPoint
        ) {
            dispatch({ submitting: false });
            toast.warning('Missing required fields');
            return;
        }

        if (startPrecoolModeDate) {
            promises.push(
                taskService.start({
                    name: `Set device ${deviceUUID} mode to precool`,
                    setPoint: precoolSetPoint,
                    mode: PRECOOL,
                    startDate: startPrecoolModeDate,
                    deviceUUID,
                }),
            );
        }
        if (startEcoModeDate) {
            promises.push(
                taskService.start({
                    name: `Set device ${deviceUUID} mode to eco`,
                    setPoint: ecoModeSetPoint,
                    mode: ECO,
                    startDate: startEcoModeDate,
                    deviceUUID,
                }),
            );
        }
        promises.push(
            taskService.start({
                name: `Set device ${deviceUUID} mode to normal`,
                setPoint: normalModeSetPoint,
                mode: NORMAL,
                startDate: startEcoModeDate
                    ? dayjs(startEcoModeDate)
                          .add(ecoModeDuration, 'minutes')
                          .utc()
                          .toISOString()
                    : dayjs(startPrecoolModeDate || new Date())
                          .add(precoolDuration, 'minutes')
                          .utc()
                          .toISOString(),
                deviceUUID,
            }),
        );
        await Promise.all(promises);
        dispatch({ ...initialState });
        toast.success('🦄 Successly created tasks.');
    };

    const handleTerminateAutomation = async () => {
        const { deviceUUID, submitting, normalModeSetPoint } = formState;

        if (!deviceUUID || submitting) {
            return;
        }

        if (normalModeSetPoint === undefined) {
            throw new Error(
                `Form should be prefilled with setpoint for normal mode!`,
            );
        }

        dispatch({ submitting: true });

        await taskService.terminate(deviceUUID);

        const controlParams: ControlDevice = {
            temperature: normalModeSetPoint,
            deviceUUID,
            mode: NORMAL,
        };

        await energyDeviceService.control(controlParams);

        dispatch({
            ...initialState,
            deviceUUID,
            tasksExist: false,
        });
    };

    const handleKeyPress = (e) => {
        if (!/^-$|[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <StyledContainer>
            <StyledHeader as="h1">TOTS Command Interface</StyledHeader>
            <Container>
                <Form error={!!formState.error}>
                    <Header as="h5">Select TOTS Device</Header>
                    <ListContainer>
                        <ListItem>
                            <FormInput>
                                <Dropdown
                                    clearable
                                    required
                                    placeholder="Select Device"
                                    options={totsList}
                                    value={formState.deviceUUID}
                                    name="deviceUUID"
                                    onChange={(e, data) => {
                                        const equipment = totsList.find(
                                            (tot) => tot.value === data.value,
                                        );
                                        if (equipment) {
                                            setEquip(equipment);
                                            dispatch({
                                                deviceUUID: data.value,
                                                equipmentUUID:
                                                    equipment.equipment_uuid,
                                            });
                                        }
                                    }}
                                    selection
                                />
                            </FormInput>
                            {totsLoading && (
                                <TotsLoaderContainer>
                                    <Loader
                                        active
                                        size="tiny"
                                        style={{
                                            position: 'relative',
                                        }}
                                    />
                                </TotsLoaderContainer>
                            )}
                        </ListItem>
                    </ListContainer>
                    <Divider horizontal />
                    <Header as="h5">Precool Mode</Header>
                    <ListContainer>
                        <ListItem bottom>
                            <Grid
                                stretched
                                style={{ width: '100%' }}
                                columns={3}
                            >
                                <GridColumn>
                                    <FormField
                                        style={{ flexGrow: 'unset' }}
                                        label="Precool Start Time:"
                                    ></FormField>
                                    <DatepickerWrapper>
                                        <DatePicker
                                            selected={
                                                formState.startPrecoolModeDate
                                            }
                                            onChange={(date) => {
                                                const result = errors.coolTime.rules.validDateTime(
                                                    date,
                                                );

                                                setErrors({
                                                    ...errors,
                                                    coolTime: {
                                                        ...errors.coolTime,
                                                        error: result.error,
                                                        text: result.text,
                                                    },
                                                });

                                                dispatch({
                                                    startPrecoolModeDate: date,
                                                });
                                            }}
                                            placeholderText={'Precool Date'}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            minDate={new Date()}
                                            showTimeInput
                                            readOnly={formState.tasksExist}
                                        />
                                    </DatepickerWrapper>
                                    <FormField>
                                        {errors.coolTime.error && (
                                            <Label pointing color="red">
                                                {errors.coolTime.text}
                                            </Label>
                                        )}
                                    </FormField>
                                </GridColumn>
                                <GridColumn>
                                    <FormField label="Precool Setpoint (F):"></FormField>
                                    <FormInput
                                        name="precoolSetPoint"
                                        value={formState.precoolSetPoint}
                                        onChange={(event: {
                                            target: {
                                                name: string;
                                                value: string;
                                            };
                                        }) => {
                                            const result = errors.coolSetpoint.rules.isBetween(
                                                event.target.value,
                                            );

                                            setErrors({
                                                ...errors,
                                                coolSetpoint: {
                                                    ...errors.coolSetpoint,
                                                    error: result.error,
                                                    text: result.text,
                                                },
                                            });

                                            dispatch({
                                                [event.target.name]:
                                                    event.target.value,
                                            });
                                        }}
                                        placeholder="Setpoint"
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        readOnly={formState.tasksExist}
                                    />
                                    <FormField>
                                        {errors.coolSetpoint.error && (
                                            <Label pointing color="red">
                                                {errors.coolSetpoint.text}
                                            </Label>
                                        )}
                                    </FormField>
                                </GridColumn>
                                <GridColumn>
                                    <FormField label="Precool Duration (mins):"></FormField>
                                    <FormInput
                                        name="precoolDuration"
                                        value={formState.precoolDuration}
                                        onChange={(event: {
                                            target: {
                                                name: string;
                                                value: string;
                                            };
                                        }) => {
                                            const result = errors.coolDuration.rules.isGreaterThanZero(
                                                event.target.value,
                                            );

                                            setErrors({
                                                ...errors,
                                                coolDuration: {
                                                    ...errors.coolDuration,
                                                    error: result.error,
                                                    text: result.text,
                                                },
                                            });

                                            dispatch({
                                                [event.target.name]:
                                                    event.target.value,
                                            });
                                        }}
                                        placeholder="Duration"
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        readOnly={formState.tasksExist}
                                    />
                                    <FormField>
                                        {errors.coolDuration.error && (
                                            <Label pointing color="red">
                                                {errors.coolDuration.text}
                                            </Label>
                                        )}
                                    </FormField>
                                </GridColumn>
                            </Grid>
                        </ListItem>
                    </ListContainer>

                    <Divider horizontal />

                    <Header as="h5">Eco-Mode</Header>
                    <ListContainer>
                        <ListItem bottom>
                            <Grid
                                stretched
                                style={{ width: '100%' }}
                                columns={3}
                            >
                                <GridColumn>
                                    <FormField
                                        required
                                        style={{ flexGrow: 'unset' }}
                                        label="Eco-Mode Start Time:"
                                    ></FormField>
                                    <DatepickerWrapper>
                                        <DatePicker
                                            required
                                            selected={
                                                formState.startEcoModeDate
                                            }
                                            onChange={(date) => {
                                                const result = errors.ecoTime.rules.validDateTime(
                                                    date,
                                                );

                                                setErrors({
                                                    ...errors,
                                                    ecoTime: {
                                                        ...errors.ecoTime,
                                                        error: result.error,
                                                        text: result.text,
                                                    },
                                                });
                                                dispatch({
                                                    startEcoModeDate: date,
                                                });
                                            }}
                                            timeInputLabel="Time:"
                                            placeholderText={'Eco Date'}
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            minDate={ecoModeMinDate}
                                            showTimeInput
                                            readOnly={formState.tasksExist}
                                        />
                                    </DatepickerWrapper>
                                    <FormField>
                                        {errors.ecoTime.error && (
                                            <Label pointing color="red">
                                                {errors.ecoTime.text}
                                            </Label>
                                        )}
                                    </FormField>
                                </GridColumn>
                                <GridColumn>
                                    <FormField
                                        required
                                        label="Eco-Mode Setpoint (F):"
                                    ></FormField>
                                    <FormInput
                                        required
                                        value={formState.ecoModeSetPoint}
                                        name="ecoModeSetPoint"
                                        onChange={(event: {
                                            target: {
                                                name: string;
                                                value: string;
                                            };
                                        }) => {
                                            const result = errors.ecoSetpoint.rules.isBetween(
                                                event.target.value,
                                            );

                                            setErrors({
                                                ...errors,
                                                ecoSetpoint: {
                                                    ...errors.ecoSetpoint,
                                                    error: result.error,
                                                    text: result.text,
                                                },
                                            });

                                            dispatch({
                                                [event.target.name]:
                                                    event.target.value,
                                            });
                                        }}
                                        placeholder="Setpoint"
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        readOnly={formState.tasksExist}
                                    />
                                    <FormField>
                                        {errors.ecoSetpoint.error && (
                                            <Label pointing color="red">
                                                {errors.ecoSetpoint.text}
                                            </Label>
                                        )}
                                    </FormField>
                                </GridColumn>
                                <GridColumn>
                                    <FormField
                                        required
                                        label="Eco-Mode Duration (mins):"
                                    ></FormField>
                                    <FormInput
                                        required
                                        name="ecoModeDuration"
                                        value={formState.ecoModeDuration}
                                        onChange={(event: {
                                            target: {
                                                name: string;
                                                value: string;
                                            };
                                        }) => {
                                            const result = errors.ecoDuration.rules.isGreaterThanZero(
                                                event.target.value,
                                            );

                                            setErrors({
                                                ...errors,
                                                ecoDuration: {
                                                    ...errors.ecoDuration,
                                                    error: result.error,
                                                    text: result.text,
                                                },
                                            });
                                            dispatch({
                                                [event.target.name]:
                                                    event.target.value,
                                            });
                                        }}
                                        placeholder="Duration"
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        readOnly={formState.tasksExist}
                                    />
                                    <FormField>
                                        {errors.ecoDuration.error && (
                                            <Label pointing color="red">
                                                {errors.ecoDuration.text}
                                            </Label>
                                        )}
                                    </FormField>
                                </GridColumn>
                            </Grid>
                        </ListItem>
                    </ListContainer>
                    <Divider horizontal />
                    <Header as="h5">Normal Mode</Header>
                    <ListContainer>
                        <ListItem bottom>
                            <Grid style={{ width: '100%' }} columns={2}>
                                <GridRow>
                                    <GridColumn>
                                        <FormField
                                            required
                                            label="Normal Mode Setpoint (F):"
                                        />
                                        <FormInput
                                            required
                                            style={{ maxWidth: 266 }}
                                            name="normalModeSetPoint"
                                            value={formState.normalModeSetPoint}
                                            onChange={(event: {
                                                target: {
                                                    name: string;
                                                    value: string;
                                                };
                                            }) => {
                                                const equipmentType =
                                                    selectedEquip &&
                                                    selectedEquip.equipmentType
                                                        ? selectedEquip.equipmentType
                                                        : null;
                                                const result = errors.normalSetpoint.rules.isBetween(
                                                    equipmentType,
                                                    event.target.value,
                                                );

                                                setErrors({
                                                    ...errors,
                                                    normalSetpoint: {
                                                        ...errors.normalSetpoint,
                                                        error: result.error,
                                                        text: result.text,
                                                    },
                                                });
                                                dispatch({
                                                    [event.target.name]:
                                                        event.target.value,
                                                });
                                            }}
                                            placeholder="Setpoint"
                                            onKeyPress={(e) =>
                                                handleKeyPress(e)
                                            }
                                            readOnly={formState.tasksExist}
                                        />
                                        <FormField>
                                            {errors.normalSetpoint.error && (
                                                <Label pointing color="red">
                                                    {errors.normalSetpoint.text}
                                                </Label>
                                            )}
                                        </FormField>
                                    </GridColumn>
                                    <GridColumn>
                                        <Message
                                            error
                                            header="Invalid TOTS configuration"
                                            content={formState.error}
                                        />
                                    </GridColumn>
                                </GridRow>
                                <GridRow style={{ paddingTop: 0 }}>
                                    <StyledButtonContainer left>
                                        <Button
                                            onClick={() =>
                                                dispatch({ ...initialState })
                                            }
                                            color="black"
                                            style={{ marginLeft: 16 }}
                                            size="mini"
                                            disabled={formState.tasksExist}
                                        >
                                            Clear Form
                                        </Button>
                                        <Button
                                            onClick={handleTerminateAutomation}
                                            color="red"
                                            size="mini"
                                            disabled={!formState.tasksExist}
                                        >
                                            Terminate Automation
                                        </Button>
                                        <Button
                                            onClick={handleStartAutomation}
                                            color="green"
                                            size="mini"
                                            disabled={formState.tasksExist}
                                        >
                                            Start Automation
                                        </Button>
                                    </StyledButtonContainer>
                                </GridRow>
                            </Grid>
                        </ListItem>
                    </ListContainer>
                </Form>
            </Container>
        </StyledContainer>
    );
};
