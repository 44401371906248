import { ButtonContainer, FormInput } from '@coinspect/ui';
import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Icon,
    Loader,
    Table,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { ModalContext } from '../../../../../contexts/modal-context';
import {
    HVACMode,
    MCCI_VENDOR,
    SetPointMetadata,
} from '../../../../../services/energy-device-service';
import { EnergyEquipmentShiftingType } from '../../../../../services/energy-equipment-shifting-service';
import useEnergyDeviceService, {
    EnergyDevice,
} from '../../hooks/useEnergyDeviceService';
import useEnergyEquipmentShiftingService from '../../hooks/useEnergyEquipmentShiftingService';
import { getIsMilesightEnergyMeterType } from '../utils';

interface EnergyDeviceTableProps {
    energyDevices: EnergyDevice[];
    onDeviceDelete: (device: EnergyDevice) => Promise<void>;
    deleteLoading: boolean;
    energyEquipmentShiftingLoading: boolean;
    energyEquipmentShifting: EnergyEquipmentShiftingType[];
    onEquipmentShiftingUpdate: Function;
    onDeviceUpdated: Function;
}

type ShiftingStateType = { [key: string]: boolean };
type HvacModeStateType = { [key: string]: string };

const TableButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const EditHvacButton = styled.div`
    color: #0000ee;
    cursor: pointer;
    &:hover {
        color: #551a8b;
    }
    display: inline;
    padding-left: 5px;
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex: 2;
    height: 100%;
`;

export const HVAC_MODES = [
    { key: 'auto', text: 'Auto', value: 'auto' },
    { key: 'heat', text: 'Heat', value: 'heat' },
    { key: 'cool', text: 'Cool', value: 'cool' },
];

export const FAN_MODES = [
    { key: 'auto', text: 'Auto', value: 'Auto' },
    { key: 'on', text: 'On', value: 'On' },
    { key: 'circulate', text: 'Circulate', value: 'Circulate' },
];

export const EnergyDeviceTable: FunctionComponent<EnergyDeviceTableProps> = ({
    energyDevices,
    onDeviceDelete,
    deleteLoading,
    energyEquipmentShiftingLoading,
    energyEquipmentShifting,
    onEquipmentShiftingUpdate,
    onDeviceUpdated,
}) => {
    const [disableShiftingState, setDisableShiftingState] = useState<
        ShiftingStateType
    >({});
    const [hvacModeState, setHvacModeState] = useState<HvacModeStateType>({});
    const {
        addEquipmentShiftingEntry,
        removeEquipmentShiftingEntry,
    } = useEnergyEquipmentShiftingService();
    const { updateDeviceSetpoints } = useEnergyDeviceService();

    const { openModal } = useContext(ModalContext);

    useEffect(() => {
        if (!energyEquipmentShiftingLoading) {
            setDisableShiftingState(
                energyDevices.reduce((acc, device) => {
                    const uuid = device.uuid;
                    const shiftingEntry = energyEquipmentShifting.find(
                        ({ entityUUID }: { entityUUID: string }) =>
                            uuid === entityUUID,
                    );
                    acc[uuid] = !!shiftingEntry;
                    return acc;
                }, {} as ShiftingStateType),
            );
            setHvacModeState(
                energyDevices.reduce((acc, device) => {
                    const uuid = device.uuid;
                    acc[uuid] = device?.metadata.hvacMode || '';
                    return acc;
                }, {} as HvacModeStateType),
            );
        }
    }, [energyEquipmentShiftingLoading, energyEquipmentShifting]);

    const formatSetpoint = (value?: number): string => {
        const VALID_ZERO = 0;

        return value || value === VALID_ZERO ? `${value}\u00B0F` : '--';
    };

    const handleSubmit = async () => {
        const newEnergyEquipmentShifting: EnergyEquipmentShiftingType[] = [];
        const promises: Promise<EnergyEquipmentShiftingType | string>[] = [];
        const metadataPromises: Promise<SetPointMetadata>[] = [];
        const newEnergyDevices: EnergyDevice[] = [];

        Object.keys(disableShiftingState).forEach((energyDeviceId) => {
            const shiftingEntry = energyEquipmentShifting.find(
                ({ entityUUID }: { entityUUID: string }) =>
                    energyDeviceId === entityUUID,
            );
            const shouldNotAllowShifting = disableShiftingState[energyDeviceId];

            // we only need to make a service call if the value changed
            if (shiftingEntry && !!shiftingEntry !== shouldNotAllowShifting) {
                promises.push(
                    shouldNotAllowShifting
                        ? addEquipmentShiftingEntry(energyDeviceId)
                        : removeEquipmentShiftingEntry(shiftingEntry.uuid),
                );
            } else if (!!shiftingEntry) {
                // keep the state the same
                newEnergyEquipmentShifting.push(shiftingEntry);
            }
        });

        energyDevices.forEach((energyDevice) => {
            if (energyDevice.equipmentType.toLowerCase() === 'hvac') {
                const hvacMode = hvacModeState[energyDevice.uuid] as HVACMode;
                if (hvacMode && hvacMode !== energyDevice.metadata.hvacMode) {
                    const updatedMetadata: SetPointMetadata = {
                        ...energyDevice.metadata,
                        hvacMode,
                    };
                    metadataPromises.push(
                        updateDeviceSetpoints(
                            updatedMetadata,
                            energyDevice.uuid,
                        ),
                    );
                    newEnergyDevices.push({
                        ...energyDevice,
                        metadata: updatedMetadata,
                    });
                } else {
                    newEnergyDevices.push(energyDevice);
                }
            } else {
                newEnergyDevices.push(energyDevice);
            }
        });

        if (metadataPromises.length) {
            try {
                // await results of hvac mode promises
                await Promise.all(metadataPromises);
                onDeviceUpdated(newEnergyDevices);
            } catch (e) {
                console.log(e);
            }
        }

        if (promises.length) {
            // await results of shifting promises
            const promiseResults = await Promise.all(promises);
            // send success and set top level state to merged results
            onEquipmentShiftingUpdate([
                ...newEnergyEquipmentShifting,
                ...promiseResults.filter((item) => item !== 'success'),
            ]);
        }
    };
    return (
        <>
            <Table celled stackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Equipment Type
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Device Type
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Device ID
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Equipment Name
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Occupied set points
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Unoccupied set points
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            HVAC Mode
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Enable Shifting Commands
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {energyDevices.map((energyDevice, index) => {
                        const isMilesightEnergyMeter = getIsMilesightEnergyMeterType(energyDevice.deviceType);
                        let displayType = isMilesightEnergyMeter
                        ? `Energy meter attached to ${energyDevice.equipmentType}`
                        : energyDevice.equipmentType;
                      if (
                        isMilesightEnergyMeter &&
                        energyDevice.equipmentType === 'Energy meter' &&
                        energyDevice?.metadata?.energyDeviceUUID
                      ) {
                        const energyDeviceUUID = energyDevice.metadata.energyDeviceUUID;
                        const energyDeviceForUUID = energyDevices.find(
                          (ed) => ed.uuid === energyDeviceUUID,
                        );
                        displayType = `Energy meter attached to ${energyDeviceForUUID?.equipmentType}`;
                      }
                        return (
                            <Table.Row key={index}>
                                <Table.Cell textAlign="center" width="1">
                                    <Button
                                        icon
                                        color="red"
                                        onClick={() =>
                                            onDeviceDelete(energyDevice)
                                        }
                                        disabled={deleteLoading}
                                    >
                                        <Icon name="delete" />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {displayType}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {energyDevice.deviceType}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {energyDevice.deviceId}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                     {(energyDevice.equipmentType === 'HVAC'
                                        ? energyDevice.metadata.name || energyDevice.equipmentName
                                        : energyDevice.equipmentName) ||
                                        energyDevice.metadata?.equipmentName ||
                                        ''}
                                </Table.Cell>
                                <Table.Cell
                                    verticalAlign="middle"
                                    style={{ fontSize: '12px' }}
                                >
                                    {energyDevice.equipmentType === 'HVAC' && (
                                        <>
                                            <div>
                                                Heating:{' '}
                                                {formatSetpoint(
                                                    energyDevice.metadata
                                                        .occupiedHeatSetPoint,
                                                )}
                                            </div>
                                            <div>
                                                Cooling:{' '}
                                                {formatSetpoint(
                                                    energyDevice.metadata
                                                        .occupiedCoolSetPoint,
                                                )}
                                            </div>
                                            <EditHvacButton
                                                onClick={() =>
                                                    openModal({
                                                        closeIcon: true,
                                                        size: 'large',
                                                        content: (
                                                            <EditSetpointsForm
                                                                energyDevices={
                                                                    energyDevices
                                                                }
                                                                device={
                                                                    energyDevice
                                                                }
                                                                onDeviceUpdated={
                                                                    onDeviceUpdated
                                                                }
                                                            />
                                                        ),
                                                        header: `Edit setpoints`,
                                                        hasModalActions: false,
                                                    })
                                                }
                                            >
                                                Edit
                                            </EditHvacButton>
                                        </>
                                    )}
                                </Table.Cell>
                                <Table.Cell
                                    verticalAlign="middle"
                                    style={{ fontSize: '12px' }}
                                >
                                    {energyDevice.equipmentType === 'HVAC' && (
                                        <>
                                            <div>
                                                Heating:{' '}
                                                {formatSetpoint(
                                                    energyDevice.metadata
                                                        .unoccupiedHeatSetPoint,
                                                )}
                                            </div>
                                            <div>
                                                Cooling:{' '}
                                                {formatSetpoint(
                                                    energyDevice.metadata
                                                        .unoccupiedCoolSetPoint,
                                                )}
                                            </div>
                                            <EditHvacButton
                                                onClick={() =>
                                                    openModal({
                                                        closeIcon: true,
                                                        size: 'large',
                                                        content: (
                                                            <EditSetpointsForm
                                                                energyDevices={
                                                                    energyDevices
                                                                }
                                                                device={
                                                                    energyDevice
                                                                }
                                                                onDeviceUpdated={
                                                                    onDeviceUpdated
                                                                }
                                                            />
                                                        ),
                                                        header: `Edit setpoints`,
                                                        hasModalActions: false,
                                                    })
                                                }
                                            >
                                                Edit
                                            </EditHvacButton>
                                        </>
                                    )}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {energyDevice.equipmentType.toLowerCase() ===
                                        'hvac' && (
                                        <DropdownWrapper
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'space-around',
                                            }}
                                        >
                                            <Dropdown
                                                fluid
                                                selection
                                                placeholder="Select"
                                                options={HVAC_MODES}
                                                value={
                                                    hvacModeState[
                                                        energyDevice.uuid
                                                    ]
                                                }
                                                onChange={(
                                                    _e: unknown,
                                                    {
                                                        value,
                                                    }: { value: unknown },
                                                ) => {
                                                    const newState = {
                                                        ...hvacModeState,
                                                        [energyDevice.uuid]: value as string,
                                                    };
                                                    setHvacModeState(newState);
                                                }}
                                            />
                                        </DropdownWrapper>
                                    )}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {energyEquipmentShiftingLoading ? (
                                        <Loader
                                            active
                                            size="tiny"
                                            style={{
                                                position: 'relative',
                                            }}
                                        />
                                    ) : (
                                        <Checkbox
                                            checked={
                                                !disableShiftingState[
                                                    energyDevice.uuid
                                                ] &&
                                                !(
                                                    energyDevice.vendor ===
                                                    MCCI_VENDOR
                                                ) && !isMilesightEnergyMeter
                                            }
                                            onChange={() => {
                                                const newState = {
                                                    ...disableShiftingState,
                                                    [energyDevice.uuid]: !disableShiftingState[
                                                        energyDevice.uuid
                                                    ],
                                                };
                                                setDisableShiftingState(
                                                    newState,
                                                );
                                            }}
                                            disabled={
                                                energyDevice.vendor === 'mcci' || isMilesightEnergyMeter
                                            }
                                        />
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            <TableButtonContainer>
                <Button color="green" onClick={handleSubmit}>
                    Submit
                </Button>
            </TableButtonContainer>
        </>
    );
};

const EditSetpointsForm = (props: {
    energyDevices: EnergyDevice[];
    device: EnergyDevice;
    onDeviceUpdated: Function;
}) => {
    const { device, onDeviceUpdated, energyDevices } = props;

    const formMethods = useForm();
    const { updateDeviceSetpoints } = useEnergyDeviceService();
    const { hideModal } = useContext(ModalContext);

    const onSubmit = formMethods.handleSubmit(async (data) => {
        const updatedMetadata: SetPointMetadata = {
            ...device.metadata,
            occupiedCoolSetPoint: parseInt(data.OccupiedCooling),
            occupiedHeatSetPoint: parseInt(data.OccupiedHeating),
            unoccupiedCoolSetPoint: parseInt(data.UnoccupiedCooling),
            unoccupiedHeatSetPoint: parseInt(data.UnoccupiedHeating),
            hvacMode: device.metadata.hvacMode,
        };

        try {
            await updateDeviceSetpoints(updatedMetadata, device.uuid);
            // Update all devices
            onDeviceUpdated(
                energyDevices.map((ed) =>
                    ed.uuid === device.uuid
                        ? { ...device, metadata: updatedMetadata }
                        : ed,
                ),
            );
            hideModal();
        } catch (e) {
            console.log(e);
        }
    });

    return (
        <FormContext {...formMethods}>
            <Form onSubmit={onSubmit}>
                <FormInput
                    label="Occupied heating setpoint"
                    name="OccupiedHeating"
                    type="number"
                    defaultValue={device.metadata.occupiedHeatSetPoint}
                    required
                />
                <FormInput
                    label="Occupied cooling setpoint"
                    name="OccupiedCooling"
                    type="number"
                    defaultValue={device.metadata.occupiedCoolSetPoint}
                    required
                />
                <FormInput
                    label="Unoccupied heating setpoint"
                    name="UnoccupiedHeating"
                    type="number"
                    defaultValue={device.metadata.unoccupiedHeatSetPoint}
                    required
                />
                <FormInput
                    label="Unoccupied cooling setpoint"
                    name="UnoccupiedCooling"
                    type="number"
                    defaultValue={device.metadata.unoccupiedCoolSetPoint}
                    required
                />
                <ButtonContainer right>
                    <Button
                        primary
                        type="submit"
                        disabled={formMethods.formState.isSubmitting}
                        loading={formMethods.formState.isSubmitting}
                    >
                        Submit
                    </Button>
                </ButtonContainer>
            </Form>
        </FormContext>
    );
};
