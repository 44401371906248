import { useContext } from 'react';

import { AuthContext } from '../../../../contexts';
import * as EnergyUtilityService from '../../../../services/energy-utility-service';

const useEnergyDeviceService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        getAvailableMeters: (currentLocationMeterUid: string | undefined) =>
            EnergyUtilityService.getAvailableMeters(
                idToken,
                currentLocationMeterUid,
            ),
        getAvailableLeapMeters: (currentLocationMeterUid: string | undefined) =>
            EnergyUtilityService.getAvailableLeapMeters(
                idToken,
                currentLocationMeterUid,
            ),
    };
};

export default useEnergyDeviceService;
