import React, { FunctionComponent } from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const LoadingPageWrapper = styled.div`
    height: calc(100vh - 72px); /* height of navbar */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoaderContainer = styled.div`
    margin-top: 75px;
    position: relative;
`;

const LoadingPage: FunctionComponent = () => {
    return (
        <LoadingPageWrapper>
            <div>
                <LoaderContainer>
                    <Loader active content="Loading" />
                </LoaderContainer>
            </div>
        </LoadingPageWrapper>
    );
};

export { LoadingPage };
