import { Axios } from '.';

export interface DeviceConfigurationModel {
    devEUI: string;
    serialNumber: string;
    temperature?: number;
    humidity?: number;
    batteryLevel?: number;
    lastSeen?: string;
}

export class DeviceConfigurationService {
    public static async browse(token: string) {
        const { data: device } = await Axios.request().get(
            '/device/pre-added',
            {
                headers: { authorization: token },
            },
        );
        return device.data;
    }
}
