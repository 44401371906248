import { useContext } from 'react';

import { AuthContext } from '../../../contexts';
import { StartTask, TaskService } from '../../../services/task-service';

export const useTaskService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        getTasksForDevice: (uuid: string) =>
            TaskService.getTasksForDevice(idToken, uuid),
        start: (body: StartTask) => TaskService.start(idToken, body),
        terminate: (deviceUUID: string) =>
            TaskService.terminate(idToken, deviceUUID),
    };
};
