import { useContext, useEffect, useState } from 'react';

import { EnergyEquipmentShiftingService, EnergyEquipmentShiftingType } from '../../../../services/energy-equipment-shifting-service';
import { AuthContext } from '../../../../contexts';
import { EnergyDevice } from './useEnergyDeviceService';

const FIRST_DATA_INDEX = 0;

export const useEnergyEquipmentShifting = (energyDevices: EnergyDevice[] | null) => {
    const { idToken } = useContext(AuthContext);
    const [energyEquipmentShifting, setEnergyEquipmentShifting] = useState<EnergyEquipmentShiftingType[] | []>(
        [],
    );
    const [energyEquipmentLoadShifting, setEnergyEquipmentLoadShifting] = useState<EnergyEquipmentShiftingType[] | []>(
        [],
    );
    const [energyEquipShiftLoading, setEnergyEquipShiftLoading] = useState<boolean>(
        false,
    );

    const getEnergyEquipmentShifting = async (energyDeviceIds: string[]) => {
        setEnergyEquipShiftLoading(true);
        let equipmentShifting = [] as EnergyEquipmentShiftingType[];
        let equipmentLoadShifting = [] as EnergyEquipmentShiftingType[];
        try {
            const results = await Promise.all(energyDeviceIds.map(async (uuid) => {
                return EnergyEquipmentShiftingService.browseEquipmentShiftingEntries(uuid, idToken);
            }))

            const filteredResults = results
                .filter((data) => data.length);

            equipmentLoadShifting = filteredResults
                .map((data) => data.filter((entry: EnergyEquipmentShiftingType) => entry.type === 'load_shifting'))
                .filter(data => data.length)
                .map((data) => data[FIRST_DATA_INDEX]);

            equipmentShifting = filteredResults
                .map((data) => data.filter((entry: EnergyEquipmentShiftingType) => entry.type === 'shifting'))
                .filter(data => data.length)
                .map((data) => data[FIRST_DATA_INDEX]);

        } catch (error) {
            console.log(
                `Error fetching equipment shifting information`,
            );
        }

        setEnergyEquipmentShifting(equipmentShifting);
        setEnergyEquipmentLoadShifting(equipmentLoadShifting);
        setEnergyEquipShiftLoading(false);
    };

    useEffect(() => {
        if (!energyDevices) {
            return;
        }

        const energyDeviceIds = energyDevices.map(({uuid}: {uuid: string}) => uuid);
        getEnergyEquipmentShifting(energyDeviceIds);
        
    }, [energyDevices]);

    return {
        energyEquipmentLoadShifting,
        energyEquipmentShifting,
        loading: energyEquipShiftLoading,
        setEnergyEquipmentLoadShifting,
        setEnergyEquipmentShifting,
    };
};
