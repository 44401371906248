import React from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

type CardProps = {
    children: React.ReactNode;
    header?: string;
};

const CardStyled = styled.div`
    background: white;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    padding: 50px 70px 20px;
`;

const CardHeader = styled(Header)`
    margin-bottom: 25px;
    font-size: 16px;
`;

export const Card: React.FC<CardProps> = (props) => {
    const { children, header } = props;

    return (
        <CardStyled>
            {header && <CardHeader as="h2">{header}</CardHeader>}
            <div>{children}</div>
        </CardStyled>
    );
};
