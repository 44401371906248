import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

export const RightPaddedDiv = styled.div`
    padding-right: 10px;
`;

export const StyledHeader = styled(Header)`
    padding: 15px 0;
`;
