import { useEffect, useState } from 'react';

import useEnergyLoadControllerService from './useEnergyLoadControllerService';
import { EnergyLoadControllerType } from '../../../../services/energy-load-controller-service';

const NUMBER_OF_RETRIES = 5;

export const useEnergyLoadControllers = (monitoringLocationUUID: string) => {
    const { getLoadControllersByMonitoringLocation } = useEnergyLoadControllerService();

    const [loadControllers, setLoadControllers] = useState<EnergyLoadControllerType[]>(
        [],
    );
    const [loadControllersLoading, setLoadControllersLoading] = useState<boolean>(
        false,
    );

    const getEnergyDevices = async (locationUUID: string) => {
        setLoadControllersLoading(true);
        let loadControllerList = [] as EnergyLoadControllerType[];

        for (let i = 0; i < NUMBER_OF_RETRIES; i++) {
            const backoff = 1000 * 2 ** i;

            try {
                loadControllerList = await getLoadControllersByMonitoringLocation(locationUUID);
            } catch (error) {
                console.log(
                    `Error fetching load controllers, retrying in ${backoff /
                        1000} seconds`,
                );
            }

            if (loadControllerList) {
                break;
            }

            await new Promise((res) => setTimeout(res, backoff));
        }

        setLoadControllers(loadControllerList);
        setLoadControllersLoading(false);
    };

    useEffect(() => {
        if (!monitoringLocationUUID) {
            return;
        }
        getEnergyDevices(monitoringLocationUUID);
    }, [monitoringLocationUUID]);

    return { loadControllers, loading: loadControllersLoading, setLoadControllers };
};
