import { MILESIGHT_VENDOR } from "../../../../services/energy-device-service";

export const MILESIGHT_THERMOSTAT_VALUES = {
  type: 'thermostat-milesight',
  vendor: MILESIGHT_VENDOR,
  vendorModel: 'wt201',
};

export const MILESIGHT_POWER_METER_VALUES = {
  type: 'powermeter-milesight',
  vendor: MILESIGHT_VENDOR,
  vendorModel: 'ct101',
};

export const MILESIGHT_LOAD_CONTROLLER_VALUES = {
  type: 'load-controller-milesight',
  vendor: MILESIGHT_VENDOR,
  vendorModel: 'ws558',
};

export const ON_OFF_OPTIONS = [
  {
      key: 'on',
      text: 'On',
      value: true,
  },
  {
      key: 'off',
      text: 'Off',
      value: false,
  },
];

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const LOAD_CONTROLLER_TYPES = [{
  key: 'lighting',
  text: 'Lighting',
  value: 'lighting',
}];

export const SINGLE_PHASE_METER_KEY = 'singlePhaseMeter';
export const SINGLE_PHASE_METER_TEXT = 'Single Phase Meter';

export const COOLER_EQUIPMENT_TYPE = 'Cooler';
export const FREEZER_EQUIPMENT_TYPE = 'Freezer';
export const HVAC_EQUIPMENT_TYPE = 'HVAC';
export const METER_EQUIPMENT_TYPE = 'Energy meter';
export const LIGHTING_EQUIPMENT_TYPE = 'Lighting';
export const OTHER_EQUIPMENT_TYPE = 'Other';

export const REFRIGERATOR_EQUIPMENT_TYPE = 'Refrigerator';

export const MILESIGHT_DEVICE_TYPES = {
  CT101: 'Milesight ct101', 
  CT103: 'Milesight ct103'
};
