import React from 'react';

import EnergyDeviceFormRow from './energy-device-form-row';
import EnergyDeviceLabelInput from './energy-device-label-input';

type Props = {
  title: string;
  heatName?: string;
  coolName?: string;
  heatSetPoint: string | undefined;
  coolSetPoint: string | undefined;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const EnergyDeviceSetPoints = ({
  title = 'Set Points:',
  heatName,
  coolName,
  heatSetPoint,
  coolSetPoint,
  handleChange,
  disabled = false,
}: Props) => {
  return (
    <EnergyDeviceFormRow label={title}>
      <EnergyDeviceLabelInput
        prefix="Heating:"
        name={heatName}
        value={heatSetPoint}
        handleChange={handleChange}
        disabled={disabled}
        suffix="&deg;F"
      />
      <EnergyDeviceLabelInput
        prefix="Cooling:"
        name={coolName}
        value={coolSetPoint}
        handleChange={handleChange}
        disabled={disabled}
        suffix="&deg;F"
      />
    </EnergyDeviceFormRow>
  );
};

export default EnergyDeviceSetPoints;
