/* eslint-disable @typescript-eslint/no-explicit-any */
import { Axios } from '.';

export class AuthService {
    public static async authenticateFirebase(
        firebaseToken: string,
    ): Promise<{
        token: string;
        atkaToken: string;
        atkaUser: any;
    }> {
        const res = await Axios.request().post('/auth/firebase', {
            firebaseToken,
        });
        const {
            data: { token, atkaToken, atkaUser },
        } = res.data;

        return { token, atkaToken, atkaUser };
    }
}
