import { Axios } from '.';
export default class HologramService {
    public static async getHologramDeviceId(sim: string) {
        return Axios.request().get(`hologram/devices/${sim}`);
    }

    public static async updateHologramName(deviceId: number, name: string) {
        return Axios.request().put(`hologram/devices/${deviceId}/${name}`);
    }

    public static async getHologramLastSeen(sim: string) {
        return Axios.request().get(`hologram/lastSeen/${sim}`);
    }
}
