import { atkaRequest } from '../contexts';
import { BaseModel } from './';

export interface UpdateModel extends BaseModel {
    toFirmwareVersion: string;
    fromFirmwareVersion: string;
    url: string;
}
export interface forUpdateType {
    gatewayId: string;
    updateUuid?: string;
    uuid: string;
}

export interface BroadcastedGateways {
    gatewayId: string;
    toFirmwareVersion: string;
    flashSpaceNeeded: number;
    scriptSize: number;
    url: string;
}

export class UpdateService {
    public async findFromFirmwareVersion(fromFirmwareVersion: string) {
        const { data: updates } = await atkaRequest.get(
            `/gateway/firmware/from/${fromFirmwareVersion}`,
        );
        return updates.data;
    }

    public async findFromAllFirmwareVersion(fromFirmwareVersion: string) {
        const { data: updates } = await atkaRequest.get(
            `/gateway/firmware/from/all/${fromFirmwareVersion}`,
        );
        return updates.data;
    }

    public async broadcastGatewayUpdates(gatewaysToUpdate: forUpdateType[]) {
        const { data: broadcastedGateways } = await atkaRequest.post(
            `/gateway/firmware/publish`,
            {
                gatewayDetails: gatewaysToUpdate,
            },
        );
        return broadcastedGateways.data;
    }
}
