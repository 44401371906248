import React from 'react';
import { Header, Input } from 'semantic-ui-react';

import { FieldWrapper } from '../../styles';
import { FieldInputWrapper, FieldLabelWrapper } from './styles';

type Props = {
  title: string;
  name?: string;
  value?: string | undefined;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const EnergyDeviceInput = ({
  title,
  name,
  value,
  handleChange,
  disabled = false,
}: Props) => {
  return (
    <FieldWrapper>
      {title && (
        <FieldLabelWrapper>
          <Header as="h5">{title}</Header>
        </FieldLabelWrapper>
      )}
      <FieldInputWrapper>
        <span style={{ width: '100%' }}>
          <Input
            type="text"
            fluid
            name={name}
            value={value}
            onChange={handleChange}
            disabled={disabled}
          >
            <input />
          </Input>
        </span>
      </FieldInputWrapper>
    </FieldWrapper>
  );
};

export default EnergyDeviceInput;
