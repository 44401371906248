import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { normalize, schema } from 'normalizr';
import { useContext } from 'react';
import Socket from 'simple-websocket';

import { AuthContext } from '../contexts';
import {
    availableUpdateType,
    BroadcastedGateways,
    ChirpstackService,
    forUpdateType,
    GatewayAssetModel,
    GatewayService,
    IotService,
    UpdateService,
} from '../services';
import HologramService from '../services/hologram-service';
import { StoreContext } from './store-context';

const GatewaySchema = new schema.Entity(
    'gatewayAsset',
    {},
    {
        idAttribute: 'uuid',
    },
);

const GatewayListSchema = [GatewaySchema];

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(advanced);

export function useGatewayAssetsService() {
    const { dispatch, store } = useContext(StoreContext);
    const { gatewayAssets } = store.entities;
    const { token } = useContext(AuthContext);

    async function loadAllGateways() {
        const gateways = await GatewayService.loadGateways();
        const normalized = normalize(gateways, GatewayListSchema);
        dispatch({
            data: normalized,
            type: 'gatewayAsset:set',
        });
    }

    async function loadGatewaysByPage(
        page: number,
        limit: number,
        keyword: string,
    ): Promise<{
        gateways: GatewayAssetModel[];
        meta: { page: number; limit: number; total: number };
    }> {
        const gatewaysData = await GatewayService.loadGatewaysByPage(
            page,
            limit,
            keyword,
            token,
        );

        for (const gateway in gatewaysData.gateways) {
            let tempHologramStatusString = 'Invalid SIM';

            if (gatewaysData.gateways[gateway].sim) {
                try {
                    const tempHologramStatusData = await HologramService.getHologramLastSeen(
                        gatewaysData.gateways[gateway].sim,
                    );

                    const {
                        data: { deviceId: tempHologramDeviceId },
                    } = await HologramService.getHologramDeviceId(
                        gatewaysData.gateways[gateway].sim,
                    );
                    gatewaysData.gateways[
                        gateway
                    ].hologramDeviceId = tempHologramDeviceId;

                    if (
                        tempHologramStatusData &&
                        tempHologramStatusData.data &&
                        tempHologramStatusData.data.hologramLastSeen
                    ) {
                        const timeZone = dayjs.tz.guess();
                        tempHologramStatusString = dayjs
                            .utc(tempHologramStatusData.data.hologramLastSeen)
                            .tz(timeZone)
                            .format('MM/DD/YYYY HH:mm z');
                    }
                } catch (error) {
                    gatewaysData.gateways[gateway].hologramStatus =
                        'Invalid SIM';
                    continue;
                }
            }

            let tempGatewayLastSeen = ' ';
            if (gatewaysData.gateways[gateway].gatewayId) {
                try {
                    const {
                        data: {
                            data: { lastSeenAt: tempGatewayLastSeenData },
                        },
                    } = await ChirpstackService.getGateway(
                        gatewaysData.gateways[gateway].gatewayId,
                    );

                    if (tempGatewayLastSeen) {
                        const timeZone = dayjs.tz.guess();
                        tempGatewayLastSeen = dayjs
                            .utc(tempGatewayLastSeenData)
                            .tz(timeZone)
                            .format('MM/DD/YYYY HH:mm z');
                    }
                } catch (error) {
                    gatewaysData.gateways[gateway].chirpstackStatus =
                        'No gateway ID found';
                }
            }

            gatewaysData.gateways[
                gateway
            ].chirpstackStatus = tempGatewayLastSeen;
            gatewaysData.gateways[
                gateway
            ].hologramStatus = tempHologramStatusString;
        }

        const normalized = normalize(gatewaysData.gateways, GatewayListSchema);

        dispatch({
            data: normalized,
            type: 'gatewayAsset:set',
        });

        return gatewaysData;
    }

    async function addGateway(gatewayAsset: GatewayAssetModel): Promise<void> {
        const gateway = await GatewayService.addGateway(gatewayAsset);
        const normalized = normalize([gateway], GatewayListSchema);
        dispatch({
            data: normalized,
            type: 'gatewayAsset:add',
        });
    }

    function updateGatewayState(gateway: GatewayAssetModel): void {
        const normalized = normalize([gateway], GatewayListSchema);
        dispatch({
            data: normalized,
            type: 'gatewayAsset:edit',
        });
    }

    async function updateGatewayStateAndData(
        gateway: GatewayAssetModel,
    ): Promise<GatewayAssetModel> {
        const updatedGateway = await GatewayService.updateGateway(gateway);
        const normalized = normalize([updatedGateway], GatewayListSchema);
        dispatch({
            data: normalized,
            type: 'gatewayAsset:edit',
        });
        return updatedGateway;
    }

    async function getAllAvailableUpdates(
        gateways: GatewayAssetModel[],
    ): Promise<void> {
        if (gateways.length) {
            const gatewayUpdates = Promise.all(
                Object.values(gateways as GatewayAssetModel[]).map(
                    async (gateway) => {
                        const { firmwareVersion } = gateway;
                        if (!firmwareVersion) {
                            return;
                        }

                        try {
                            const updateServiceInstance = new UpdateService();
                            const availableUpdateData = await updateServiceInstance.findFromAllFirmwareVersion(
                                firmwareVersion,
                            );
                            const availableVersions: availableUpdateType[] = [];
                            availableUpdateData.map(
                                (data: availableUpdateType) => {
                                    availableVersions.push({
                                        uuid: data.uuid,
                                        toFirmwareVersion:
                                            data.toFirmwareVersion,
                                    });
                                },
                            );
                            updateGatewayState({
                                ...gateway,
                                availableUpdate: availableVersions,
                                selectedUpdate:
                                    availableVersions[0]?.uuid || '',
                            });
                        } catch (e) {
                            console.error(e.data.message);
                        }
                    },
                ),
            );
            await gatewayUpdates;
        }
    }

    async function broadcastGatewayUpdates(
        gatewaysToUpdate: forUpdateType[],
    ): Promise<BroadcastedGateways[]> {
        const updateService = new UpdateService();
        const broadcasted = await updateService.broadcastGatewayUpdates(
            gatewaysToUpdate,
        );
        return broadcasted;
    }

    function resetGateways(): Promise<void> {
        dispatch({
            type: 'gatewayAsset:reset',
        });

        return Promise.resolve();
    }

    async function deleteGateway(uuid: string): Promise<void> {
        const data = await GatewayService.deleteGateway(uuid);
        dispatch({
            data: uuid,
            type: 'gatewayAsset:delete',
        });
        return data;
    }

    async function checkIfUniqueGatewayName(name: string): Promise<boolean> {
        const unique = await GatewayService.checkIfUniqueGatewayName(name);
        if (unique) {
            return false;
        }
        return true;
    }

    async function checkIfUniqueGatewayId(gatewayId: string): Promise<boolean> {
        const unique = await GatewayService.checkIfUniqueGatewayId(gatewayId);
        if (unique) {
            return false;
        }
        return true;
    }

    function getUpdatingGateways(): GatewayAssetModel[] {
        return gatewayAssets.all
            .map((uuid: string) => {
                const { updateStatus } = gatewayAssets.byUUID[uuid];
                if (updateStatus === 'updating')
                    return gatewayAssets.byUUID[uuid];
            })
            .filter((gateway: GatewayAssetModel) => gateway);
    }

    async function searchByKeyword(
        page: number,
        limit: number,
        keyword: string,
    ): Promise<{
        gateways: GatewayAssetModel[];
        meta: { page: number; limit: number; total: number };
    }> {
        const gatewaysData = await GatewayService.loadGatewaysByPage(
            1,
            limit,
            keyword,
            token,
        );

        const normalized = normalize(gatewaysData.gateways, GatewayListSchema);
        dispatch({
            data: normalized,
            type: 'gatewayAsset:set',
        });
        return gatewaysData;
    }

    async function waitForUpdatedGateways(
        updatingGateways: GatewayAssetModel[],
    ): Promise<Socket | undefined> {
        if (updatingGateways.length <= 0) return;
        const iot = new IotService();
        const socket = await iot.subscribeToGatewayStartup();
        socket.on('data', (message) => {
            const startupData = JSON.parse(message.toString());
            for (const updatingGateway of updatingGateways) {
                if (startupData.gatewayId === updatingGateway.gatewayId) {
                    updateGatewayState({
                        ...updatingGateway,
                        updateStatus: 'updated',
                        firmwareVersion: startupData.firmwareVersion,
                    });
                }
            }
        });
        return socket;
    }

    return {
        checkIfUniqueGatewayId,
        checkIfUniqueGatewayName,
        addGateway,
        resetGateways,
        deleteGateway,
        updateGatewayState,
        updateGatewayStateAndData,
        loadAllGateways,
        getAllAvailableUpdates,
        broadcastGatewayUpdates,
        loadGatewaysByPage,
        searchByKeyword,
        getUpdatingGateways,
        waitForUpdatedGateways,
    };
}
