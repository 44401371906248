import React from 'react';
import { Header } from 'semantic-ui-react';

import { FieldWrapper } from '../../styles';
import { FieldInputWrapper, FieldLabelWrapper } from './styles';

type Props = {
    label: string;
    children: React.ReactNode;
};

const EnergyDeviceFormRow = ({ label, children }: Props) => {
    return (
        <FieldWrapper>
            <FieldLabelWrapper>
                <Header as="h5">{label}</Header>
            </FieldLabelWrapper>
            <FieldInputWrapper>{children}</FieldInputWrapper>
        </FieldWrapper>
    );
};

export default EnergyDeviceFormRow;
