import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'semantic-ui-less/semantic.less';

import { BasePage } from './components/pages';
import {
    AuthProvider,
    DeviceProvider,
    DevToolsProvider,
    ModalWithTransaction, /* Use the RUM instrumented Modal component */
} from './contexts';
import { Store } from './store';
import './style/custom-fonts.less';
import { GlobalStyles } from './style/global-styles';
import './style/system-icons.less';

export const App: React.FunctionComponent<{}> = () => {
    return (
        <Router>
            <GlobalStyles />
            <ToastContainer />
            <Store>
                <DevToolsProvider>
                    <Switch>
                        <AuthProvider>
                            <DeviceProvider>
                                <ModalWithTransaction>
                                    <BasePage />
                                </ModalWithTransaction>
                            </DeviceProvider>
                        </AuthProvider>
                    </Switch>
                </DevToolsProvider>
            </Store>
        </Router>
    );
};
