import { AxiosResponse, AxiosError } from 'axios';

import {
    Bill,
    BillFile,
    EnergyAndMonitoringData,
    GenerateSavingsData,
} from '../components/pages/energy-billing-form-page/types';
import { Axios } from './axios-service';
const THERMA_ENERGY_API_PROXY_URL =
    process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

export const fetchLocationsAndCompanies = async (
    token: string,
): Promise<EnergyAndMonitoringData[]> => {
    const results = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
        `/energy-locations/monitoring-companies-locations`,
        {
            headers: {
                authorization: `Bearer ${token}`,
            },
        },
    );
    if (results.data) {
        return results.data;
    }

    return [];
};

export const saveBill = async (
    token: string,
    bill: Bill | BillFile,
): Promise<AxiosResponse | []> => {
    try {
        let data;
        if ('file' in bill) {
            const formData = new FormData();
            formData.append('locationUUID', bill.locationUUID);
            formData.append('billDate', bill.billDate);
            formData.append('file', bill.file);
            data = formData;
        } else {
            data = bill;
        }
        const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL).post(
            `/energy-locations/create-savings`,
            data,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    'Content-Type':
                        'file' in bill
                            ? 'multipart/form-data'
                            : 'application/json',
                },
            },
        );
        return response;
    } catch (e) {
        return [];
    }
};

export const generateSavings = async (
    token: string,
    billData: GenerateSavingsData,
): Promise<AxiosResponse | string[]> => {
    try {
        let data;
        if ('file' in billData) {
            const formData = new FormData();
            formData.append('companyUUID', billData.companyUUID);
            formData.append('energyLocationUUID', billData.locationUUID);
            formData.append('billDate', billData.billDate);
            formData.append('file', billData.file);
            data = formData;
        } else {
            data = data;
        }
        const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL).post(
            `/energy-locations/generate-savings`,
            data,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    'Content-Type':
                        'file' in billData
                            ? 'multipart/form-data'
                            : 'application/json',
                },
            },
        );
        return response;
    } catch (e) {
        const errors: string[] = [];
        if(e instanceof Error) {
            const response = (e as unknown as AxiosError).response;
            if (response && response.data?.statusCode === 400) {
                errors.push(response.data.message?.replace("ValidationType.UTILITY: ", ''));
            }
        }
        return errors;
    }
};

export const getSignedURL = async (token: string, savingsUUID: string) => {
    try {
        const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
            `/savings-api/${savingsUUID}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return response;
    } catch (e) {
        return null;
    }
};
