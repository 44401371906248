import { useContext } from 'react';

import { AuthContext } from '../../../../contexts';
import { EnergyTimeOfUseService, EnergyTimeOfUseType } from '../../../../services/energy-time-of-use-service';

const useEnergyTimeOfUseService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        createEnergyTimeOfUse: (payload: EnergyTimeOfUseType) =>
            EnergyTimeOfUseService.createTimeOfUseByUuid(
                payload,
                idToken,
            ),
        getEnergyTimeOfUse: (uuid: string) =>
            EnergyTimeOfUseService.getTimeOfUseByUuid(
                uuid,
                idToken,
            ),
        updateEnergyTimeOfUse: (payload: EnergyTimeOfUseType) =>
            EnergyTimeOfUseService.updateTimeOfUseByUuid(
                payload,
                idToken,
            ),
        removeEnergyTimeOfUse: (uuid: string) =>
            EnergyTimeOfUseService.removeTimeOfUseByUuid(
                uuid,
                idToken,
            )
    };
};

export default useEnergyTimeOfUseService;
