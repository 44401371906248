import React, { createContext, FunctionComponent } from 'react';

export const DevToolsContext = createContext<unknown>({});

export const DevToolsProvider: FunctionComponent = ({ children }) => {
    return (
        <DevToolsContext.Provider value={{}}>
            {children}
        </DevToolsContext.Provider>
    );
};
