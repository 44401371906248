import moment from 'moment';
import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Icon } from 'semantic-ui-react';
import { GatewayModel } from 'services';
import Socket from 'simple-websocket';
import styled from 'styled-components';

import {
    LimitedContainer,
    ListContainer,
    ListItem,
    RightPaddedDiv,
    StyledHeader,
} from '..';
import { StoreContext, useGatewayService } from '../../store';

const TableCell = styled(RightPaddedDiv)`
    text-align: center;
`;

const FadingIcon = styled(Icon)`
    && {
        opacity: 0;
        animation: fadein 10s;
        -moz-animation: fadein 10s; /* Firefox */
        -webkit-animation: fadein 10s; /* Safari and Chrome */
        -o-animation: fadein 10s; /* Opera */
    }
    @keyframes fadein {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @-moz-keyframes fadein {
        /* Firefox */
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @-webkit-keyframes fadein {
        /* Safari and Chrome */
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @-o-keyframes fadein {
        /* Opera */
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

export const GatewayPage: FunctionComponent<{}> = () => {
    const { store } = useContext(StoreContext);
    const { gateways } = store.entities;
    const { browseGateways, streamGatewayStats } = useGatewayService();
    const [recentlyUpdatedGateways, setRecentlyUpdatedGateways] = useState<
        string[]
    >([]);

    console.log(gateways);

    function setStatus(lastSeen: string | undefined): string {
        if (!lastSeen) {
            return 'inactive';
        }
        if (moment().diff(lastSeen, 'hours') > 4) {
            return 'inactive';
        }
        return 'active';
    }

    useEffect(() => {
        (async () => {
            await browseGateways();
        })();
    }, []);

    useEffect(() => {
        let socket: Socket | undefined;
        function getRecentlyUpdatedGateways(gatewayUuids: string[]): void {
            setRecentlyUpdatedGateways(gatewayUuids);
        }
        (async () => {
            socket = await streamGatewayStats(getRecentlyUpdatedGateways);
        })();
        return () => {
            socket && socket.destroy();
        };
    }, [gateways.all.length]);

    return (
        <LimitedContainer>
            <StyledHeader as="h1">Configured Gateways</StyledHeader>
            <ListContainer>
                <ListItem key={1} bottom={0 !== gateways.length - 1}>
                    <TableCell className="bold flex-1">ID</TableCell>
                    <TableCell className="bold flex-1">Serial Number</TableCell>
                    <TableCell className="bold flex-1">Name</TableCell>
                    <TableCell className="bold flex-1">
                        Date Configured
                    </TableCell>
                    <TableCell className="bold flex-1">Last Seen</TableCell>
                    <TableCell className="bold flex-1">Stat Count</TableCell>
                    <TableCell className="bold flex-1">Status</TableCell>
                    <TableCell className="bold flex-1"></TableCell>
                </ListItem>
                {gateways.all.length > 0 &&
                    gateways.all.map((gatewayUuid: string, idx: number) => {
                        const {
                            gatewayId,
                            gatewayName,
                            serialNumber,
                            lastSeen,
                            createdAt,
                            status,
                            count,
                        } = gateways.byUUID[gatewayUuid] as GatewayModel;
                        return (
                            <ListItem
                                key={gatewayId}
                                bottom={idx !== gateways.length - 1}
                            >
                                <TableCell className="flex-1">
                                    {gatewayId}
                                </TableCell>
                                <TableCell className="flex-1">
                                    {serialNumber ? serialNumber : '--'}
                                </TableCell>
                                <TableCell className="flex-1">
                                    {gatewayName}
                                </TableCell>
                                <TableCell className="flex-1">
                                    {moment(createdAt).format('LLL')}
                                </TableCell>
                                <TableCell className="flex-1">
                                    {lastSeen
                                        ? moment(lastSeen).fromNow()
                                        : 'Never'}
                                </TableCell>
                                <TableCell className="flex-1">
                                    {count ? count : '--'}
                                </TableCell>
                                <TableCell className="flex-1">
                                    {status ? status : setStatus(lastSeen)}
                                </TableCell>
                                <TableCell className="flex-1">
                                    {recentlyUpdatedGateways.includes(
                                        gatewayUuid,
                                    ) && (
                                        <FadingIcon
                                            color="yellow"
                                            name="circle"
                                        />
                                    )}
                                </TableCell>
                            </ListItem>
                        );
                    })}
            </ListContainer>
        </LimitedContainer>
    );
};
