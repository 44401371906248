import { Axios } from '.';

const THERMA_ENERGY_API_PROXY_URL =
    process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

// this api does not use the /energy route, so we need to carefully remove it
const START_INDEX = 0;
const re = /\/energy$/;
const res = THERMA_ENERGY_API_PROXY_URL.match(re);
const THERMA_ENERGY_SHIFTING_URL = res && res.index
    ? THERMA_ENERGY_API_PROXY_URL.substring(START_INDEX, res.index)
    : THERMA_ENERGY_API_PROXY_URL;

export type EnergyShiftingType = 'load_shifting' | 'time_of_use_pre_cooling' | 'shifting';

export type EnergyEquipmentShiftingType = {
    uuid: string;
    entityUUID: string;
    type: EnergyShiftingType;
}

export type EnergyEquipmentShiftingConfigType = {
    uuid: string,
    companyUUID: string;
    type: EnergyShiftingType;
    entityUUID: string;
    duration: number;
    metadata: {
        temperatureDelta: number;
    };
}

/**
 * used for turning shifting off and on through the Therma energy api
 */
export class EnergyEquipmentShiftingConfigsService {
    public static async browseEquipmentShiftingConfigs(
        energyDeviceIds: string[],
        token: string,
        type: EnergyShiftingType,
    ): Promise<EnergyEquipmentShiftingConfigType[]> {
        const { data } = await Axios.request(THERMA_ENERGY_SHIFTING_URL).get(
            '/api/v1/control-event-config',
            {
                params: {
                  entityType: 'energy_device',
                  entityUUIDs: energyDeviceIds,
                  type,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return data.data ? data.data : {};
    }
    public static async removeEquipmentShiftingConfig(
        configUUID: string,
        token: string,
    ) {
        const request = await Axios.request(THERMA_ENERGY_SHIFTING_URL).delete(
            `/api/v1/control-event-config/${configUUID}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? 'success' : 'failure';
    }
    public static async createEquipmentShiftingConfig({
        companyUUID,
        duration,
        energyDeviceId,
        temperatureDelta,
        token,
        type,
    }: {
        companyUUID: string;
        duration: number;
        energyDeviceId: string;
        temperatureDelta: number;
        token: string;
        type: EnergyShiftingType;
    }): Promise<EnergyEquipmentShiftingConfigType[]> {
        const request = await Axios.request(THERMA_ENERGY_SHIFTING_URL).post(
            '/api/v1/control-event-config',
            {
                companyUUID,
                duration,
                entityType: 'energy_device',
                entityUUID: energyDeviceId,
                metadata: {
                    temperatureDelta,
                },
                type,
            },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : {};
    }

    public static async updateEquipmentShiftingConfig({
        companyUUID,
        configUUID,
        duration,
        energyDeviceId,
        temperatureDelta,
        token,
        type,
    }: {
        companyUUID: string;
        configUUID: string;
        duration: number;
        energyDeviceId: string;
        temperatureDelta: number;
        token: string;
        type: EnergyShiftingType;
    }): Promise<EnergyEquipmentShiftingConfigType[]> {
        const request = await Axios.request(THERMA_ENERGY_SHIFTING_URL).put(
            `/api/v1/control-event-config/${configUUID}`,
            {
                companyUUID,
                duration,
                entityType: 'energy_device',
                entityUUID: energyDeviceId,
                metadata: {
                    temperatureDelta,
                },
                type,
            },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : {};
    }
}
