import React, { useContext } from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { PinType } from '../types';
import { WEEKDAYS } from '../constants';
import styled from 'styled-components';
import { EnergyLCPinType, EnergyLoadControllerType } from 'services/energy-load-controller-service';
import { ModalContext } from '../../../../../contexts';
import EnergyLoadControllerEditModal from './energy-load-controller-edit-modal';

const ScheduleList = styled.ul`
  list-style-type: none;
  padding: 0;
  text-align: left;
`;

const ScheduleListItem = styled.li`
  padding: 2px 0;
  font-size: 12px;
`;

const EditButton = styled.div`
    color: #4183C4;
    cursor: pointer;
    &:hover {
        color: #551a8b;
    }
    display: inline;
    padding-left: 5px;
`;

const emptyArray8 = Array(8).fill(null);

const to12Hour = (time: string) => {
  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours, 10);
  const suffix = hour >= 12 ? 'PM' : 'AM';
  const newHour = hour % 12 || 12;
  return `${newHour}:${minutes} ${suffix}`;
};

const formatSchedule = (pin?: PinType) => {
  if (!pin) {
    return [];
  }
  return WEEKDAYS.map((day, index) => {
    const schedule = pin.schedule[index];
    const stringDay = day[0] + day.slice(1).toLowerCase();
    return schedule ? `${stringDay}: ${to12Hour(schedule.on)} - ${to12Hour(schedule.off)}`: '--';
  }).filter((day) => day !== '--');
}

export const EnergyLoadControllerTable = ({
  loadControllers,
  handleEdit,
  handleDelete,
}:{
  loadControllers: EnergyLoadControllerType[];
  handleEdit: (loadController: EnergyLoadControllerType) => void;
  handleDelete: (uuid: string) => void;
}) => {
  const { openModal, hideModal } = useContext(ModalContext);

  return (
    <div>
      <h3>Load Controllers</h3>
      <Table celled stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Serial Number</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="8">
                Equipment
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loadControllers.map((loadController, index) => {
            return (
              <React.Fragment key={loadController.uuid}>
                <Table.Row>
                  <Table.Cell rowSpan="2" textAlign="center" width="1">
                    <Button
                        icon
                        color="red"
                        onClick={() => handleDelete(loadController.uuid || '')}
                    >
                        <Icon name="delete" />
                    </Button>
                  </Table.Cell>
                  <Table.Cell rowSpan="2">{loadController.identifier}</Table.Cell>
                  {emptyArray8.map((_, index) => {
                    const pin = loadController.pins[index + 1];
                    const pinName = `${index+1}. ${pin ? pin.name : '--'}`;
                    const pinKey = index + 1;
                    const onEditPin = (newPin: EnergyLCPinType) => {
                      const newLoadController: EnergyLoadControllerType = {
                        ...loadController,
                        pins: {
                          ...loadController.pins,
                          [pinKey]: newPin,
                        },
                      };
                      handleEdit(newLoadController);
                    };
                    return (
                      <Table.Cell
                        key={pinKey}
                        textAlign="center"
                        style={{backgroundColor: 'rgb(249, 250, 251)'}}
                      >
                        {pinName}<EditButton onClick={() =>
                          openModal({
                            size: 'tiny',
                            header: 'Edit Load Controller Pin',
                            hasModalActions: false,
                            content: <EnergyLoadControllerEditModal pin={pin} onEditPin={onEditPin}/>,
                            onClose: hideModal,
                            closeIcon: true,
                          })}
                        >
                          Edit
                        </EditButton>
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
                <Table.Row>
                  {emptyArray8.map((_, index) => {
                    const pin = loadController.pins[index + 1];
                    const schedule = formatSchedule(pin);
                    return pin ? (
                      <Table.Cell style={{borderLeft: '1px solid rgba(34, 36, 38, 0.1)'}}>
                        <ScheduleList>
                          {schedule.map((day, index) => (
                            <ScheduleListItem key={index}>{day}</ScheduleListItem>
                          ))}
                        </ScheduleList>
                      </Table.Cell>
                    ) : (
                      <Table.Cell
                        textAlign="center"
                        style={{borderLeft: '1px solid rgba(34, 36, 38, 0.1)'}}
                      >
                        --
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              </React.Fragment>
            );
          })}
        </Table.Body>
        </Table>
    </div>
  );
};

export default EnergyLoadControllerTable;