import { Axios } from '.';

const THERMA_ENERGY_API_PROXY_URL =
    process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

export type Company = {
    id: number;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    monitoringLocations: MonitoringLocation[];
};

export type MonitoringLocation = {
    id: number;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    locality?: string;
    address?: string;
    line1?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    companyUUID: string;
};

export type EnergyLocation = {
    uuid: string;
    monitoringLocationUuid: string;
    utilityProviderId: string;
    demandResponseProviderId: string;
};

export type EnergyMeter = {
    uid: string;
    billing_contact: string;
    billing_address: string;
    service_address: string;
};

/**
 * used for handling locations through the Therma energy api
 */
export class EnergyLocationService {
    public static async addMeter(
        token: string,
        locationUuid: string,
        meterUuid: string,
    ): Promise<{ sucess: boolean }> {
        const results = await Axios.request(THERMA_ENERGY_API_PROXY_URL).put(
            `/energy-locations/add-meter/${locationUuid}`,
            { meterUuid },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );
        if (results.data) {
            return results.data;
        }

        return { sucess: false };
    }
    public static async removeMeter(
        token: string,
        locationUuid: string,
    ): Promise<{ sucess: boolean }> {
        const results = await Axios.request(THERMA_ENERGY_API_PROXY_URL).put(
            `/energy-locations/remove-meter/${locationUuid}`,
            {},
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );
        if (results.data) {
            return results.data;
        }

        return { sucess: false };
    }
    public static async getAllCompaniesWithMonitoringLocations(
        token: string,
    ): Promise<Company[]> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
            `energy-locations/all-companies-with-monitoring-locations`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }

    public static async createLocationByMonitoring(
        token: string,
        monitoringLocationUuid: string,
    ): Promise<EnergyLocation[]> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).post(
            `energy-locations/create-location-by-monitoring`,
            {
                monitoringLocationUuid,
            },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }
    public static async removeLocationByMonitoring(
        token: string,
        monitoringLocationUuid: string,
    ): Promise<EnergyLocation[]> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).delete(
            `energy-locations/remove-location-by-monitoring/${monitoringLocationUuid}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }
    public static async byMonitoringLocations(
        token: string,
        monitoringLocationUUIDs: string[],
    ): Promise<EnergyLocation[]> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).post(
            `energy-locations/by-monitoring-locations`,
            {
                monitoringLocationUUIDs,
            },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }
    public static async addLeapMeter(
        token: string,
        locationUuid: string,
        meterUuid: string,
    ): Promise<{ message: string }> {
        const results = await Axios.request(THERMA_ENERGY_API_PROXY_URL).patch(
            `/energy-locations/patch-demand-response-provider-id/${locationUuid}`,
            { demandResponseProviderId: meterUuid },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );
        if (results.data) {
            return results.data;
        }

        return { message: 'false' };
    }
    public static async removeLeapMeter(
        token: string,
        locationUuid: string,
    ): Promise<{ message: string }> {
        const results = await Axios.request(THERMA_ENERGY_API_PROXY_URL).patch(
            `/energy-locations/patch-demand-response-provider-id/${locationUuid}`,
            { demandResponseProviderId: null },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );
        if (results.data) {
            return results.data;
        }

        return { message: 'false' };
    }
}
