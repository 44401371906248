import React from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { EnergyEquipmentShiftingType } from '../../../services/energy-equipment-shifting-service';
import { EnergyEquipmentShiftingConfigType } from '../../../services/energy-shifting-config-service';
import EquipmentTOUConfig from './equipment-time-of-use-config';
import { EnergyDevice } from './hooks/useEnergyDeviceService';
import { ListLabelWrapper, ListNumberWrapper, ListTextWrapper } from './styles';
import { ContentWrapper } from './system-commissioning-body';

const MCCI_VENDOR = 'mcci';

const CTOUFormContainer = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px 40px 15px 40px;
    gap: 40px;
    justify-content: space-evenly;
    margin-bottom: 15px;
`;

type CustomTimeOfUseFormProps = {
    companyUUID: string;
    energyDevices: EnergyDevice[] | null;
    energyEquipmentLoadShifting: EnergyEquipmentShiftingType[];
    energyEquipmentShifting: EnergyEquipmentShiftingType[];
    onEquipmentShiftingUpdate: Function;
    equipmentLoadShiftingConfigs: EnergyEquipmentShiftingConfigType[];
    equipmentPreCoolConfigs: EnergyEquipmentShiftingConfigType[];
    onEquipmentShiftingConfigUpdate: Function;
};

const EquipmentTimeOfUseForm = ({
    companyUUID,
    energyDevices,
    energyEquipmentLoadShifting,
    energyEquipmentShifting,
    onEquipmentShiftingUpdate,
    equipmentLoadShiftingConfigs,
    equipmentPreCoolConfigs,
    onEquipmentShiftingConfigUpdate,
}: CustomTimeOfUseFormProps) => {
    return !!energyDevices?.length ? (
        <CTOUFormContainer>
            <ContentWrapper>
                <ListLabelWrapper>
                    <ListNumberWrapper>
                        <Header size="tiny">{'5.'}</Header>
                    </ListNumberWrapper>
                    <ListTextWrapper>
                        <Header size="tiny">
                            Equipment Time-of-Use Load shifting
                        </Header>
                    </ListTextWrapper>
                </ListLabelWrapper>
            </ContentWrapper>
            <div>
                <Header size="tiny">Equipment Name:</Header>
                {energyDevices.map((device) => {
                    if (device.vendor === MCCI_VENDOR) {
                        return null;
                    }

                    const loadShiftingEntry = energyEquipmentLoadShifting.find(
                        ({ entityUUID }: { entityUUID: string }) =>
                            device.uuid === entityUUID,
                    );

                    const hasShiftingEntry = energyEquipmentShifting.some(
                        ({ entityUUID }: { entityUUID: string }) =>
                            device.uuid === entityUUID,
                    );

                    const preCoolConfig = equipmentPreCoolConfigs.find(
                        ({ entityUUID }: { entityUUID: string }) =>
                            device.uuid === entityUUID,
                    );

                    const peakConfig = equipmentLoadShiftingConfigs.find(
                        ({ entityUUID }: { entityUUID: string }) =>
                            device.uuid === entityUUID,
                    );

                    const handleShiftingSuccess = (
                        newShifting: EnergyEquipmentShiftingType[],
                    ) => {
                        const filteredLoadShiftingEntries = loadShiftingEntry
                            ? energyEquipmentLoadShifting.filter(
                                  ({ entityUUID }: { entityUUID: string }) =>
                                      device.uuid !== entityUUID,
                              )
                            : energyEquipmentLoadShifting;

                        onEquipmentShiftingUpdate(
                            [...filteredLoadShiftingEntries, ...newShifting],
                            true,
                        );
                    };

                    const handleConfigUpdate = (
                        newShiftingConfig: EnergyEquipmentShiftingType[],
                        isPreCool: boolean,
                    ) => {
                        if (isPreCool) {
                            const filteredPreCoolConfigs = preCoolConfig
                                ? equipmentPreCoolConfigs.filter(
                                      ({
                                          entityUUID,
                                      }: {
                                          entityUUID: string;
                                      }) => device.uuid !== entityUUID,
                                  )
                                : equipmentPreCoolConfigs;

                            onEquipmentShiftingConfigUpdate(
                                [
                                    ...filteredPreCoolConfigs,
                                    ...newShiftingConfig,
                                ],
                                true,
                            );
                        } else {
                            const filteredPeakConfigs = peakConfig
                                ? equipmentLoadShiftingConfigs.filter(
                                      ({
                                          entityUUID,
                                      }: {
                                          entityUUID: string;
                                      }) => device.uuid !== entityUUID,
                                  )
                                : equipmentLoadShiftingConfigs;

                            onEquipmentShiftingConfigUpdate(
                                [...filteredPeakConfigs, ...newShiftingConfig],
                                false,
                            );
                        }
                    };

                    return (
                        <EquipmentTOUConfig
                            device={device}
                            key={device.uuid}
                            loadShiftingEntry={loadShiftingEntry}
                            isShiftingDisabled={hasShiftingEntry}
                            onEquipmentShiftingUpdate={handleShiftingSuccess}
                            companyUUID={companyUUID}
                            preCoolConfig={preCoolConfig}
                            peakConfig={peakConfig}
                            handleConfigUpdate={handleConfigUpdate}
                        />
                    );
                })}
            </div>
        </CTOUFormContainer>
    ) : null;
};

export default EquipmentTimeOfUseForm;
