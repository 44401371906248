import { useContext } from 'react';

import { AuthContext } from '../../../../contexts';
import { EnergyLocationService } from '../../../../services/energy-location-service';

const useEnergyLocationService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        getAllCompaniesWithMonitoringLocations: () =>
            EnergyLocationService.getAllCompaniesWithMonitoringLocations(
                idToken,
            ),
        createLocationbyMonitoring: (monitoringLocationUUID: string) =>
            EnergyLocationService.createLocationByMonitoring(
                idToken,
                monitoringLocationUUID,
            ),
        removeLocationbyMonitoring: (monitoringLocationUUID: string) =>
            EnergyLocationService.removeLocationByMonitoring(
                idToken,
                monitoringLocationUUID,
            ),
        byMonitoringLocations: (monitoringLocationUUIDs: string[]) =>
            EnergyLocationService.byMonitoringLocations(
                idToken,
                monitoringLocationUUIDs,
            ),
        addMeter: (locationUuid: string, meterUuid: string) =>
            EnergyLocationService.addMeter(idToken, locationUuid, meterUuid),
        removeMeter: (locationUuid: string) =>
            EnergyLocationService.removeMeter(idToken, locationUuid),
        addLeapMeter: (locationUuid: string, meterUuid: string) =>
            EnergyLocationService.addLeapMeter(idToken, locationUuid, meterUuid),
        removeLeapMeter: (locationUuid: string) =>
            EnergyLocationService.removeLeapMeter(idToken, locationUuid),
    };
};

export default useEnergyLocationService;
