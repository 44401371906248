import styled from 'styled-components';

export const EquipmentConfigContainer = styled.div`
  margin: 24px 0;
  padding-bottom: 24px;
  border-bottom: 1px solid #AAACAD;
`;

export const EquipmentConfigTop = styled.div`
  display: flex;
`;

export const EquipmentConfigBottom = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const EquipmentName = styled.div`
  width: 200px;
  min-width: 200px;
  display: flex;
  gap: 12px;
  font-weight: 500;
  margin-right: 16px;

  & .disabled {
    color: #7D7D7D;
  }
`;

export const EquipmentConfig = styled.div`
  width: 100%;
  font-weight: 500;
`;

export const ConfigOptionContainter = styled.div`
  width: 80%;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
`;

export const ShiftingCommandsDisabled = styled.div`
  width: 100%;
  text-align: center;
  color: #7D7D7D;
  font-weight: 400;
`;

export const ConfigErrorMessage = styled.div`
  color: red;
  text-align: center;
`;

export const ConfigOptionCheckContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 30%;
`;

export const ConfigDropdownLabel = styled.span`
  display: block;
  margin-bottom: 8px;
`;