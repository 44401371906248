import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import { AuthContext } from '../contexts';
import {
    GatewayModel,
    GatewayService,
    GatewayStatWebsocketRequest,
    IotService,
} from '../services';
import { StoreContext } from './store-context';

const GatewaySchema = new schema.Entity(
    'gateway',
    {},
    {
        idAttribute: 'uuid',
    },
);

const GatewayListSchema = [GatewaySchema];

export function useGatewayService() {
    const { dispatch, store } = useContext(StoreContext);
    let { gateways } = store.entities;
    const { token } = useContext(AuthContext);

    async function browseGateways(): Promise<void> {
        const gateways = await GatewayService.browseConfigured(token);
        const normalized = normalize(gateways, GatewayListSchema);
        dispatch({
            data: normalized,
            type: 'gateway:set',
        });
    }

    async function addGateway(gatewayId: string): Promise<void> {
        const gateway = await GatewayService.add(gatewayId, token);
        const normalized = normalize([gateway], GatewayListSchema);
        dispatch({
            data: normalized,
            type: 'gateway:add',
        });
    }

    function updateGateway(gateway: GatewayModel): void {
        const normalized = normalize([gateway], GatewayListSchema);
        dispatch({
            data: normalized,
            type: 'gateway:edit',
        });
    }

    async function streamGatewayStats(callback: Function | undefined) {
        if (gateways.all.length > 0) {
            let recentlyUpdated: string[] = [];
            const iot = new IotService();
            let gatewayReqs: GatewayStatWebsocketRequest[] | [] = [];
            for (const uuid of gateways.all) {
                const { uuid: gatewayUuid, gatewayId } = gateways.byUUID[uuid];
                gatewayReqs = [...gatewayReqs, { gatewayUuid, gatewayId }];
            }
            const gatewaySocket = await iot.subscribeToGatewayStats(
                gatewayReqs,
            );
            gatewaySocket.on('data', (msg) => {
                const { gatewayUuid, lastSeen, status } = JSON.parse(msg);
                gateways = {
                    ...gateways,
                    byUUID: {
                        ...gateways.byUUID,
                        [gatewayUuid]: {
                            ...gateways.byUUID[gatewayUuid],
                            count: gateways.byUUID[gatewayUuid].count
                                ? gateways.byUUID[gatewayUuid].count + 1
                                : 1,
                        },
                    },
                };
                updateGateway({
                    ...gateways.byUUID[gatewayUuid],
                    lastSeen,
                    status: status,
                });
                if (callback) {
                    recentlyUpdated = [...recentlyUpdated, gatewayUuid];
                    callback(recentlyUpdated);
                    setTimeout(() => {
                        const index = recentlyUpdated.indexOf(gatewayUuid);
                        recentlyUpdated = [
                            ...recentlyUpdated.slice(0, index),
                            ...recentlyUpdated.slice(index + 1),
                        ];
                        callback(recentlyUpdated);
                    }, 10000);
                }
            });
            return gatewaySocket;
        }
    }

    function resetGateways(): void {
        dispatch({
            type: 'gateway:reset',
        });
    }

    async function deleteGateway(uuid: string): Promise<void> {
        const data = await GatewayService.delete(uuid, token);
        dispatch({
            data: uuid,
            type: 'gateway:delete',
        });
        return data;
    }

    return {
        browseGateways,
        addGateway,
        resetGateways,
        deleteGateway,
        updateGateway,
        streamGatewayStats,
    };
}
