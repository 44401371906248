import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import { AuthContext } from '../contexts';
import { DeviceService } from '../services';
import { StoreContext } from './store-context';

const DeviceSchema = new schema.Entity(
    'device',
    {},
    {
        idAttribute: 'uuid',
    },
);

const DeviceListSchema = [DeviceSchema];

export function useDeviceService() {
    const { dispatch } = useContext(StoreContext);
    const { token } = useContext(AuthContext);

    async function browseDevices(): Promise<void> {
        const devices = await DeviceService.browse(token);
        const normalized = normalize(devices, DeviceListSchema);
        dispatch({
            data: normalized,
            type: 'device:set',
        });
    }

    async function findDeviceBySerialNumber(
        serialNumber: string,
    ): Promise<void> {
        const devices = await DeviceService.findBySerialNumber(
            serialNumber,
            token,
        );
        const normalized = normalize(devices, DeviceSchema);
        dispatch({
            data: normalized,
            type: 'device:set',
        });
    }

    async function addDevice(
        serialNumber: string,
        deviceType: string,
    ): Promise<void> {
        const device = await DeviceService.add(serialNumber, deviceType, token);
        const normalized = normalize([device], DeviceListSchema);
        dispatch({
            data: normalized,
            type: 'device:add',
        });
    }

    function resetDevices(): void {
        dispatch({
            type: 'device:reset',
        });
    }

    async function deleteDevice(uuid: string): Promise<void> {
        const data = await DeviceService.delete(uuid, token);
        dispatch({
            data: uuid,
            type: 'device:delete',
        });
        return data;
    }

    return {
        browseDevices,
        findDeviceBySerialNumber,
        addDevice,
        resetDevices,
        deleteDevice,
    };
}
