import React, { FunctionComponent, useContext } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import { NavigationBar } from '../';
import { AuthContext } from '../../contexts';
import {
    DeploymentsPage,
    DevicePage,
    GatewayAssetsPage,
    GatewayPage,
    LoadingPage,
    LoginPage,
    ToolsPage,
    UserPage,
} from './';
import { EnergyBillingFormPage } from './energy-billing-form-page';
import { EnergySystemCommissioningPage } from './energy-system-commissioning';
import { TotsUiPage } from './tots-ui-page';
import { ApmRoute } from '@elastic/apm-rum-react';

/* Replace Route components from the react-router library with ApmRoute to instrument the application routes
ApmRoute creates a transaction that has the path of the Route as its name and has route-change as its type. */
const UserRoute = (args: RouteProps): JSX.Element => {
    const { hasUser } = useContext(AuthContext);

    if (hasUser) {
        return <ApmRoute exact={args.exact} path={args.path} component={args.component} />;
    } else {
        return <Redirect to={{ pathname: '/' }} />;
    }
};

const PublicPages: FunctionComponent<{}> = () => {
    return (
        <Switch>
            <ApmRoute component={LoginPage} />
        </Switch>
    );
};

/* ApmRoute only instruments the route if component property is provided */
const PrivatePages: FunctionComponent<{}> = () => {
    const { hasUser } = useContext(AuthContext);

    if (!hasUser) {
        return null;
    }

    return (
        <Switch>
            <UserRoute exact path="/devices" component={DevicePage} />
            <UserRoute exact path="/gateways" component={GatewayPage} />
            <UserRoute
                exact
                path="/gatewayassets"
                component={GatewayAssetsPage}
            />
            <UserRoute
                exact
                path="/energy-billing-form"
                component={EnergyBillingFormPage}
            />
            <UserRoute exact path="/tots" component={TotsUiPage} />
            <UserRoute exact path="/users" component={UserPage} />
            <UserRoute exact path="/deployments" component={DeploymentsPage} />
            <UserRoute
                exact
                path="/system-commissioning"
                component={EnergySystemCommissioningPage}
            />
            <ApmRoute exact path="/tools" component={ToolsPage} />
            <Route
                path="/"
                render={(): JSX.Element => {
                    const newRoute = hasUser ? '/devices' : 'calibration';
                    return <Redirect to={newRoute} />;
                }}
            />
            <Route render={() => <div>Not found</div>} />
        </Switch>
    );
};

export const BasePage: FunctionComponent<{}> = () => {
    const { hasUser, isInitializing } = useContext(AuthContext);
    if (isInitializing) {
        return <LoadingPage />;
    }

    if (!hasUser) {
        return <PublicPages />;
    }

    return (
        <>
            <NavigationBar />
            <PrivatePages />
        </>
    );
};
