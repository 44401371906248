import { Axios } from '.';

const THERMA_ENERGY_API_PROXY_URL =
    process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

export type Company = {
    id: number;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    monitoringLocations: MonitoringLocation[];
};

export type MonitoringLocation = {
    id: number;
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    locality?: string;
    address?: string;
    line1?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    companyUUID: string;
};

export type EnergyLocation = {
    uuid: string;
    monitoringLocationUuid: string;
    utilityProviderId: string;
};

export type EnergyMeter = {
    uid: string;
    billing_contact: string;
    billing_address: string;
};

export type EnergyTimeOfUseType = {
    energyLocationUuid: string;
    startTimeTariff: string;
    endTimeTariff: string;
    timezoneLocation: string;
    uuid?: string;
}

/**
 * used for handling time of use through the Therma energy api
 */
export class EnergyTimeOfUseService {
    public static async browseTimeOfUse(
        energyLocationUUID: string,
        token: string,
    ) {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
            'time-of-use',
            {
                params: {
                  energyLocationUUID: [energyLocationUUID]
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : {};
    }
    public static async getTimeOfUseByUuid(
        uuid: string,
        token: string,
    ) {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
            `time-of-use/${uuid}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : {};
    }
    public static async updateTimeOfUseByUuid(
        payload: EnergyTimeOfUseType,
        token: string,
    ) {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).put(
            `time-of-use/${payload.uuid}`,
            payload,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : {};
    }
    public static async createTimeOfUseByUuid(
        payload: EnergyTimeOfUseType,
        token: string,
    ) {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).post(
            `time-of-use`,
            payload,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : {};
    }
    public static async removeTimeOfUseByUuid(
        uuid: string,
        token: string,
    ) {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).delete(
            `time-of-use/${uuid}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : {};
    }
}
