import { useEffect, useState } from 'react';

import {
    EnergyLocation,
    EnergyMeter,
} from '../../../../services/energy-location-service';
import useEnergyUtilityService from './useEnergyUtilityService';

export const useMeters = (energyLocation: EnergyLocation | null) => {
    const { getAvailableMeters } = useEnergyUtilityService();

    const [meters, setMeters] = useState<EnergyMeter[]>([]);
    const [metersLoading, setMetersLoading] = useState<boolean>(false);

    const getMeters = async (utilityProviderId: string) => {
        setMetersLoading(true);
        const nextMeters: EnergyMeter[] = await getAvailableMeters(
            utilityProviderId,
        );
        setMeters(nextMeters);
        setMetersLoading(false);
    };

    useEffect(() => {
        if (!energyLocation) {
            return;
        }

        const { utilityProviderId } = energyLocation;

        getMeters(utilityProviderId);
    }, [energyLocation?.uuid]);

    return { meters, loading: metersLoading, setMeters };
};
