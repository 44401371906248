import { Axios } from '.';
import { BaseModel } from './';

export interface UserModel extends BaseModel {
    email: string;
    firebaseUid: string;
    firstName: string;
    lastName: string;
    preferredTempUnit: 'f' | 'c';
}

export class UserService {
    public static async browse(token: string): Promise<UserModel[]> {
        const { data: client } = await Axios.request().get(`/user`, {
            headers: { authorization: token },
        });
        return client.data;
    }

    public static async add(email: string, token: string): Promise<UserModel> {
        const { data: user } = await Axios.request().post(
            `/user`,
            {
                email,
            },
            {
                headers: { authorization: token },
            },
        );

        return user.data;
    }

    public static async delete(uuid: string, token: string) {
        const { data: user } = await Axios.request().delete(`/user/${uuid}`, {
            headers: { authorization: token },
        });
        return user.data;
    }
}
