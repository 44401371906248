import React from 'react';
import styled from 'styled-components';

type ListContainerProps = {
    children: React.ReactNode;
};

const ListContainerStyled = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: block;
    overflow: auto;
    width: 100%;
`;

export const ListContainer: React.FC<ListContainerProps> = (props) => {
    const { children } = props;
    return <ListContainerStyled>{children}</ListContainerStyled>;
};
