import { Axios } from './axios-service';

const THERMA_ENERGY_API_PROXY_URL =
    process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

export type EnergyMeter = {
    uid: string;
    billing_contact: string;
    billing_address: string;
};

export type LeapMeter = {
    meter_id: string;
    service_address_full: string;
    service_point_number: string;
}

export const getAvailableMeters = async (
    token: string,
    currentLocationMeterUid: string | undefined,
): Promise<[]> => {
    let path = `/utility-api/available-meters`;
    if (currentLocationMeterUid) {
        path += `?alwaysInclude=${currentLocationMeterUid}`;
    }
    const results = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(path, {
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    if (results.data) {
        return results.data;
    }

    return [];
};

export const getAvailableLeapMeters = async (
    token: string,
    currentLocationMeterUid: string | undefined,
): Promise<[]> => {
    let path = `/leap/available-meters`;
    if (currentLocationMeterUid) {
        path += `?alwaysInclude=${currentLocationMeterUid}`;
    }
    const results = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(path, {
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    if (results.data) {
        return results.data;
    }

    return [];
};
