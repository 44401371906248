import { Axios } from '.';

export class ChirpstackService {
    public static async queueDownlink(
        devEui: string,
        payload: string,
        fPort = 2,
    ) {
        return Axios.request().post(`/chirpstack/downlink`, {
            devEui,
            payload,
            fPort,
        });
    }

    public static async getDevice(devEui: string, appId: number) {
        try {
            return (
                await Axios.request().get(
                    `/chirpstack/device/${appId}/${devEui}`,
                )
            ).data.data;
        } catch (e) {
            if (!e.response || !e.response.data) {
                throw new Error(e);
            }
            throw new Error(e.response.data.message);
        }
    }

    public static async updateChirpstackName(
        gatewayid: string,
        newName: string,
    ) {
        try {
            return await Axios.request().put(
                `chirpstack/gateways/${gatewayid}`,
                {
                    gatewayid,
                    newName,
                },
            );
        } catch (e) {
            if (!e.response || !e.response.data) {
                throw new Error(e);
            }
            throw new Error(e.response.data.message);
        }
    }

    public static async getGateway(gatewayId: string) {
        try {
            return await Axios.request().get(
                `chirpstack/gateways/${gatewayId}`,
            );
        } catch (e) {
            throw new Error(e.response.data.message);
        }
    }
}
