import { ChirpstackService } from './';

export class DraginoService {
    public static async requestBacklog(
        devEui: string,
        unixStartRange: number,
        unixEndRange: number,
        commandHeader: 'A9' | '31',
    ) {
        const hexPayload = `${commandHeader}${unixStartRange.toString(
            16,
        )}${unixEndRange.toString(16)}05`;
        const payload = Buffer.from(hexPayload, 'hex').toString('base64');
        return ChirpstackService.queueDownlink(devEui, payload, 2);
    }

    public static async getDevice(name: string, appId: number) {
        const device = (await ChirpstackService.getDevice(name, appId)).device;
        const {
            tags: { deviceType },
        } = device;
        if (deviceType !== 'dragino') {
            throw new Error('not a dragino device');
        }
        return device;
    }
}
