import { Reducer } from 'react';

import {
    DeviceConfigurationModel,
    DeviceModel,
    GatewayAssetModel,
    GatewayModel,
    UserModel,
} from '../services';
import { queryReducer } from './reducers';

export interface NormalEntity<T> {
    byUUID: {
        [key: string]: T;
    };
    all: string[];
}

export interface QueryInProgress {
    [key: string]: boolean;
}

export interface NormalEntities {
    devices: NormalEntity<DeviceModel>;
    gateways: NormalEntity<GatewayModel>;
    users: NormalEntity<UserModel>;
    deviceConfigurations: NormalEntity<DeviceConfigurationModel>;
    gatewayAssets: NormalEntity<GatewayAssetModel>;
}

export interface DataStoreAction {
    type: string;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data: any;
    meta?: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
}

export function initialStoreEntity<T>(): NormalEntity<T> {
    return {
        all: [],
        byUUID: {},
    };
}

export interface DataStoreState {
    entities: NormalEntities;
    pages: {
        isQueryInProgress: QueryInProgress;
    };
}

export function initialStoreState(): DataStoreState {
    return {
        entities: {
            devices: initialStoreEntity<DeviceModel>(),
            gateways: initialStoreEntity<GatewayModel>(),
            users: initialStoreEntity<UserModel>(),
            deviceConfigurations: initialStoreEntity<
                DeviceConfigurationModel
            >(),
            gatewayAssets: initialStoreEntity<GatewayAssetModel>(),
        },
        pages: {
            isQueryInProgress: {},
        },
    };
}

type EntityNames =
    | 'client'
    | 'device'
    | 'gateway'
    | 'gatewayAsset'
    | 'calibrationPoint'
    | 'deviceConfiguration'
    | 'user';

function genericEntityReducer<T>(entityName: EntityNames) {
    return (
        state: NormalEntity<T>,
        action: DataStoreAction,
    ): NormalEntity<T> => {
        switch (action.type) {
            case `${entityName}:set`:
                return {
                    all: [...new Set(state.all.concat(action.data.result))],
                    byUUID: {
                        ...state.byUUID,
                        ...action.data.entities[entityName],
                    },
                };
            case `${entityName}:reset`:
                return initialStoreEntity<T>();

            case `${entityName}:add`:
                return {
                    all: [...new Set(state.all.concat(action.data.result))],
                    byUUID: {
                        ...state.byUUID,
                        ...action.data.entities[entityName],
                    },
                };

            case `${entityName}:edit`:
                return {
                    ...state,
                    byUUID: {
                        ...state.byUUID,
                        ...action.data.entities[entityName],
                    },
                };

            case `${entityName}:delete`: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [action.data]: val, ...byUUID } = state.byUUID;
                const uuidIdx = state.all.indexOf(action.data);
                const all = state.all
                    .slice(0, uuidIdx)
                    .concat(state.all.slice(uuidIdx + 1));
                return {
                    all,
                    byUUID,
                };
            }

            default:
                return state;
        }
    };
}

const deviceEntityReducer = genericEntityReducer<DeviceModel>('device');
const gatewayEntityReducer = genericEntityReducer<GatewayModel>('gateway');
const gatewayAssetsEntityReducer = genericEntityReducer<GatewayAssetModel>(
    'gatewayAsset',
);
const deviceConfigurationEntityReducer = genericEntityReducer<
    DeviceConfigurationModel
>('deviceConfiguration');
const userEntityReducer = genericEntityReducer<UserModel>('user');

export const RootReducer: Reducer<DataStoreState, DataStoreAction> = (
    state: DataStoreState = initialStoreState(),
    action: DataStoreAction,
): DataStoreState => {
    if (action.type === 'clearall') {
        return initialStoreState();
    }

    return {
        entities: {
            devices: deviceEntityReducer(state.entities.devices, action),
            gateways: gatewayEntityReducer(state.entities.gateways, action),
            users: userEntityReducer(state.entities.users, action),
            deviceConfigurations: deviceConfigurationEntityReducer(
                state.entities.deviceConfigurations,
                action,
            ),
            gatewayAssets: gatewayAssetsEntityReducer(
                state.entities.gatewayAssets,
                action,
            ),
        },
        pages: {
            isQueryInProgress: queryReducer(
                state.pages.isQueryInProgress,
                action,
            ),
        },
    };
};
