import React, { useContext } from 'react';
import styled from 'styled-components';

import { forUpdateType, GatewayAssetModel } from '../services';
import {
    StoreContext,
    useConfirmDialog,
    useGatewayAssetsService,
} from '../store';

const ErrorBlock = styled.div`
    color: red;
`;

export const useUpdateGateway = () => {
    const { openDialog, closeDialog, errorDialog } = useConfirmDialog();
    const { dispatch } = useContext(StoreContext);

    const {
        deleteGateway,
        broadcastGatewayUpdates,
        updateGatewayStateAndData,
        waitForUpdatedGateways,
    } = useGatewayAssetsService();

    function doUpdateGateway(gatewaysToUpdate: forUpdateType[]) {
        (async () => {
            try {
                dispatch({ type: 'gatewayAssets:broadcastUpdate_REQUEST' });
                const broadcastedGateways = (
                    await broadcastGatewayUpdates(gatewaysToUpdate)
                ).map((gateway) => gateway.gatewayId);
                const updatingGateways = (await Promise.all(
                    gatewaysToUpdate
                        .map(async (updatingGateway: forUpdateType) => {
                            if (
                                broadcastedGateways.includes(
                                    updatingGateway.gatewayId,
                                )
                            ) {
                                return updateGatewayStateAndData({
                                    uuid: updatingGateway.uuid,
                                    updateStatus: 'updating',
                                } as GatewayAssetModel);
                            }
                        })
                        .filter((gateway) => gateway),
                )) as GatewayAssetModel[];
                await waitForUpdatedGateways(updatingGateways);
                closeDialog();
            } catch (error) {
                showErrorUpdateModal(error.message);
            } finally {
                dispatch({ type: 'gatewayAssets:broadcastUpdate_FINALLY' });
            }
        })();
    }

    function showUpdateModal(
        gatewaysToUpdate: forUpdateType[],
        excludedGatewayNames: string[],
    ) {
        openDialog({
            content: (
                <>
                    Are you really sure you want to update the selected
                    gateways?
                    <br />
                    Make sure you have the appropriate permission prior to
                    proceeding
                    {excludedGatewayNames.length ? (
                        <ErrorBlock>
                            <div>
                                Note that the following gateways will not be
                                updated due to either being offline or their
                                firmware version being latest, below 1.3.6, or
                                unknown
                            </div>
                            {excludedGatewayNames.length &&
                                excludedGatewayNames.map(
                                    (excludedGateway, i) => {
                                        return (
                                            <div
                                                key={i}
                                            >{`- ${excludedGateway}`}</div>
                                        );
                                    },
                                )}
                        </ErrorBlock>
                    ) : (
                        ''
                    )}
                </>
            ),
            header: 'Update',
            onConfirm: () => {
                doUpdateGateway(gatewaysToUpdate);
            },
            disableButtonsOnQuery: 'gatewayAssets:broadcastUpdate',
        });
    }

    function showDeleteModal(
        selectedGateways: {
            [key: string]: GatewayAssetModel;
        },
        postDeleteFunction: () => void,
    ) {
        return openDialog({
            content: <>Are you sure you want delete the selected gateways?</>,
            header: 'Delete',
            onConfirm: async () => {
                const promises = Promise.all([
                    Object.keys(selectedGateways).map(async (gatewayUuid) => {
                        return deleteGateway(gatewayUuid);
                    }),
                ]);
                await promises;
                closeDialog();
                postDeleteFunction();
            },
        });
    }

    function showErrorUpdateModal(message: string) {
        errorDialog({
            content: <>Failed to broadcast update</>,
            header: 'Update Error',
            onConfirm: () => {
                closeDialog();
            },
        });
    }

    return {
        showUpdateModal,
        showDeleteModal,
    };
};
