import { useEffect, useState } from 'react';

import {
    EnergyLocation,
    EnergyMeter,
} from '../../../../services/energy-location-service';
import useEnergyUtilityService from './useEnergyUtilityService';
import { LeapMeter } from '../../../../services/energy-utility-service';

export const useLeapMeters = (energyLocation: EnergyLocation | null) => {
    const { getAvailableLeapMeters } = useEnergyUtilityService();

    const [leapMeters, setLeapMeters] = useState<LeapMeter[]>([]);
    const [leapMetersLoading, setLeapMetersLoading] = useState<boolean>(false);

    const getMeters = async (demandResponseProviderId: string) => {
        setLeapMetersLoading(true);
        const nextMeters: LeapMeter[] = await getAvailableLeapMeters(
            demandResponseProviderId,
        );
        setLeapMeters(nextMeters);
        setLeapMetersLoading(false);
    };

    useEffect(() => {
        if (!energyLocation) {
            return;
        }

        const { demandResponseProviderId } = energyLocation;

        getMeters(demandResponseProviderId);
    }, [energyLocation?.uuid]);

    return { leapMeters, loading: leapMetersLoading, setLeapMeters };
};
