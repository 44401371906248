import Socket from 'simple-websocket';

export interface GatewayStatWebsocketRequest {
    gatewayUuid: string;
    gatewayId: string;
}

export class IotService {
    private url: string;
    constructor(
        url = process.env.THERMALINK_WEBSOCKET_ENDPOINT ||
            'ws://localhost:9026',
    ) {
        this.url = url;
    }

    public async calibrateSensor(
        devEui: string,
        temperatureC: number,
    ): Promise<string> {
        const socket = new Socket(this.url);
        await this.waitForConnected(socket);
        socket.send(JSON.stringify({ devEui, temperatureC, event: 'rx' }));
        const celsiusRead = await this.waitForFirstMessage(socket);
        socket.destroy();
        return celsiusRead.toString();
    }

    public async waitForSensorAck(devEui: string): Promise<Buffer> {
        const socket = new Socket(this.url);
        await this.waitForConnected(socket);
        socket.send(JSON.stringify({ devEui, event: 'ack' }));
        const acknowledgement = await this.waitForFirstMessage(socket);
        socket.destroy();
        return acknowledgement;
    }

    public async subscribeToGatewayStats(
        gatewayReqs: GatewayStatWebsocketRequest[],
    ) {
        const socket = new Socket(`${this.url}/gateway/stats`);
        await this.waitForConnected(socket);
        for (const req of gatewayReqs) {
            socket.send(JSON.stringify({ ...req, deviceType: 'thermabeta' }));
        }
        return socket;
    }

    public async subscribeToGatewayStartup(): Promise<Socket> {
        const socket = new Socket(`${this.url}/gateway/startup`);
        await this.waitForConnected(socket);
        socket.send('start');
        return socket;
    }

    public async subscribeToSensorMessages(callback: (data: Buffer) => void) {
        const socket = new Socket(`${this.url}/device/updates`);
        await this.waitForConnected(socket);
        socket.send(1);
        socket.on('data', callback);
        return socket;
    }

    private waitForFirstMessage(socket: Socket): Promise<Buffer> {
        return new Promise((res, rej) => {
            try {
                socket.on('data', res);
            } catch (e) {
                rej(e);
            }
        });
    }

    private waitForConnected(socket: Socket) {
        return new Promise((res, rej) => {
            try {
                socket.on('connect', () => {
                    res(true);
                });
            } catch (e) {
                rej(e);
            }
        });
    }
}
