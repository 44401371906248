import React, { FunctionComponent, useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { StoreContext, useUserService } from '../../store';
import { ListItem } from '../list';

export interface UserListProps {
    userUuids: string[];
}

export const UserList: FunctionComponent<UserListProps> = (props) => {
    const { store } = useContext(StoreContext);
    const { userUuids } = props;
    const { users } = store.entities;
    const { deleteUser } = useUserService();
    return (
        <div>
            {userUuids.length > 0 &&
                userUuids.map((userUuid, idx) => (
                    <ListItem
                        key={userUuid}
                        bottom={idx !== userUuids.length - 1}
                    >
                        <div className="bold flex-1">
                            {users.byUUID[userUuid].email}
                        </div>
                        <Button
                            circular
                            icon
                            onClick={() => deleteUser(userUuid)}
                        >
                            <Icon size="large" className="icon-trash" />
                        </Button>
                    </ListItem>
                ))}
        </div>
    );
};
