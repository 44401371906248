import React, { MouseEventHandler, useState } from 'react';
import styled, {css} from 'styled-components';

const ToggleWrapper = styled.div`
  width: fit-content;
  border: 1px solid #D2D7E2;
  border-radius: 5px;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

const ToggleButton = styled.div`
  font-size: 12px;
  padding: 12px;

  &.selected {
    background: #CE849B;
    color: white;
  }
`;

type ToggleOption = {
  value: string;
  text: string;
}

const Toggle = ({
  options,
  selected,
  onChange,
}: {
  options: ToggleOption[];
  selected: string;
  onChange: Function;
}) => {
  return (
    <>
      <ToggleWrapper>
        {options.map(option => (
          <ToggleButton
            className={option.value === selected ? 'selected' : ''}
            onClick={() => onChange(option.value)}
            key={option.value}
          >
            {option.text}
          </ToggleButton>
        ))}
      </ToggleWrapper>
    </>
  );
}

export default Toggle;
