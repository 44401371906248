import { Axios } from '.';

const THERMA_ENERGY_API_PROXY_URL =
    process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

// this api does not use the /energy route, so we need to carefully remove it
const re = /\/energy$/;
const res = THERMA_ENERGY_API_PROXY_URL.match(re);
const THERMA_ENERGY_SHIFTING_URL = res && res.index
    ? THERMA_ENERGY_API_PROXY_URL.substring(0, res.index)
    : THERMA_ENERGY_API_PROXY_URL;

export type EnergyEquipmentShiftingType = {
    uuid: string;
    entityUUID: string;
    type: string;
}

/**
 * used for turning shifting off and on through the Therma energy api
 */
export class EnergyEquipmentShiftingService {
    public static async browseEquipmentShiftingEntries(
        energyDeviceId: string,
        token: string,
    ): Promise<EnergyEquipmentShiftingType[]> {
        const { data } = await Axios.request(THERMA_ENERGY_SHIFTING_URL).get(
            '/api/v1/unsupported-control-event',
            {
                params: {
                  entityType: 'energy_device',
                  entityUUID: energyDeviceId,
                  type: ['shifting', 'load_shifting'],
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return data.data ? data.data : {};
    }

    public static async removeEquipmentShiftingEntry(
        entryUUID: string,
        token: string,
    ) {
        const request = await Axios.request(THERMA_ENERGY_SHIFTING_URL).delete(
            `/api/v1/unsupported-control-event/${entryUUID}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? 'success' : 'failure';
    }
    public static async createEquipmentShiftingEntry(
        energyDeviceId: string,
        token: string,
        type?: string,
    ) {
        const request = await Axios.request(THERMA_ENERGY_SHIFTING_URL).post(
            '/api/v1/unsupported-control-event',
            {
                entityType: 'energy_device',
                entityUUID: energyDeviceId,
                type: type || 'shifting',
            },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : {};
    }
}
