import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import { CheckboxWrapper } from './styles';

type Props = {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (
    event: React.FormEvent<HTMLInputElement>,
    data: { checked: boolean },
  ) => void;
};

const EnergyDeviceCheckbox = ({
  prefix,
  suffix,
  checked,
  onChange,
  disabled = false,
}: Props) => {
  return (
    <CheckboxWrapper>
      {prefix && <span>{prefix}</span>}
      <Checkbox checked={checked} disabled={disabled} onChange={onChange} />
      {suffix && <span>{suffix}</span>}
    </CheckboxWrapper>
  );
};

export default EnergyDeviceCheckbox;
