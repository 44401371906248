import React from 'react';
import styled from 'styled-components';

type ListItemProps = {
    children: React.ReactNode;
    bottom?: boolean;
};

const ListItemStyled = styled.div<{ bottom?: boolean }>`
    display: flex;
    padding: 20px 24px;
    align-items: center;
    color: rgba(26, 17, 51, 0.8);
    line-height: 1.7;
    ${({ bottom }) =>
        bottom && 'border-bottom: 1px solid rgba(209, 215, 227, 0.5);'}
`;

export const ListItem: React.FC<ListItemProps> = (props) => {
    const { children, bottom } = props;
    return <ListItemStyled bottom={bottom}>{children}</ListItemStyled>;
};
