import { Responsive } from 'semantic-ui-react';
import styled from 'styled-components';

export const ListTextWrapper = styled.div`
    display: flex;
    flex: 1;
    align-self: baseline;
    padding-top: 5px;
`;

export const ListNumberWrapper = styled.div`
    display: flex;
    width: min-content;
    align-self: baseline;
    padding-top: 5px;
`;

export const ListLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex: 1;
    align-self: baseline;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: baseline;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-self: stretch;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex: 1;
    align-self: baseline;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-self: stretch;
    }
`;

export const InputContainerBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #faf9f6;
    border-radius: 4px;
    border: 1px solid rgba(151, 151, 151, 0.2);
    padding: 10px;
    gap: 10px;
`;

export const FieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: row;
        align-items: stretch;
    }
`;

export const FieldLabelWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    padding-left: 15px;
`;

export const TableWrapper = styled.div`
    padding: 15px 40px;
    background: white;
    margin-bottom: 15px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
`;

export const TableLoaderWrapper = styled.div`
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FieldInputWrapper = styled.div`
    display: flex;
    flex: 2;
    align-items: stretch;
    flex-direction: column;
`;

export const DeviceFormContainer = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px 40px 15px 40px;
    gap: 40px;
    justify-content: space-evenly;
    margin-bottom: 15px;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    height: 45px;
    width: 100%;
`;
