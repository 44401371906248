import React, { createContext, FunctionComponent, useState } from 'react';

interface Device {
    devEui: string;
    setDevEui: (newToken: string) => void;
    deviceUuid: string;
    setDeviceUuid: (newDeviceUuid: string) => void;
    points: number[];
    setPoints: (newDeviceUuid: number[]) => void;
    clearAll: () => void;
    deviceType: 'dragino' | 'thermabeta' | '';
    setDeviceType: (newDeviceType: 'dragino' | 'thermabeta' | '') => void;
}

export const DeviceContext = createContext<Device>({
    devEui: '',
    setDevEui: (): void => undefined,
    deviceUuid: '',
    setDeviceUuid: (): void => undefined,
    points: [],
    setPoints: (): void => undefined,
    clearAll: (): void => undefined,
    deviceType: '',
    setDeviceType: (): void => undefined,
});

export const DeviceProvider: FunctionComponent = ({ children }) => {
    const [devEui, setDevEui] = useState('');
    const [deviceUuid, setDeviceUuid] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [points, setPoints] = useState(Array<number>());
    const clearAll = () => {
        setDevEui('');
        setDeviceUuid('');
        setPoints([]);
        setDeviceType('');
    };

    return (
        <DeviceContext.Provider
            value={{
                devEui,
                setDevEui,
                deviceUuid,
                setDeviceUuid,
                points,
                setPoints,
                clearAll,
                deviceType,
                setDeviceType,
            }}
        >
            {children}
        </DeviceContext.Provider>
    );
};
