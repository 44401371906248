import { thermaH2FontFamily } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { NoGatewayFound } from '../ui/images/svgs';

interface EmptyGatewayProps {
    message?: string;
}
const EmptyGatewayContainer = styled.div`
    background: none;
    text-align: center;
    height: 50vh;
    display: grid;
    align-items: center;
    grid-template-rows: 1fr;
`;
const SubHeaderText = styled.p`
    width: 320px;
    font-size: 16px;
    font-weight: 300;
    margin: 0 auto;
    font-family: ${thermaH2FontFamily};
`;

export const EmptyGateway: React.FunctionComponent<EmptyGatewayProps> = (
    props,
) => {
    const { message = ' Create gateway by adding gateway asset.' } = props;
    return (
        <EmptyGatewayContainer className="empty-gateway">
            <Header icon>
                <NoGatewayFound />
                <Header className="empty-gateway__header" as="h2">
                    No gateways found
                </Header>
                <Header.Subheader>
                    <SubHeaderText className="empty-gateway_sub-header">
                        {/* {message} */}
                    </SubHeaderText>
                </Header.Subheader>
            </Header>
        </EmptyGatewayContainer>
    );
};
