import { useEffect, useState } from 'react';

import { VendorDeviceType } from '../../../../services/energy-device-service';
import useEnergyDeviceService from './useEnergyDeviceService';
import useEnergyLoadControllerService from './useEnergyLoadControllerService';

const NUMBER_OF_RETRIES = 5;

export const useVendorDevices = () => {
    const { getAvailableVendorDevices } = useEnergyDeviceService();
    const { getAvailableLoadControllers } = useEnergyLoadControllerService();

    const [vendorDevices, setVendorDevices] = useState<
        VendorDeviceType[] | null
    >(null);
    const [vendorDevicesLoading, setVendorDevicesLoading] = useState<boolean>(
        false,
    );

    const getVendorDevices = async () => {
        setVendorDevicesLoading(true);
        let devices = null;
        let loadControllers = [] as VendorDeviceType[];
        try {
            loadControllers = await getAvailableLoadControllers();
        } catch (error) {
            console.log('Error fetching load controllers');
        }

        for (let i = 0; i < NUMBER_OF_RETRIES; i++) {
            const backoff = 1000 * 2 ** i;

            try {
                devices = await getAvailableVendorDevices();
            } catch (error) {
                console.log(
                    `Error fetching devices, retrying in ${backoff /
                        1000} seconds`,
                );
            }

            if (devices) {
                break;
            }

            await new Promise((res) => setTimeout(res, backoff));
        }

        setVendorDevices(devices ? [...devices, ...loadControllers] : devices);
        setVendorDevicesLoading(false);
    };

    useEffect(() => {
        getVendorDevices();
    }, []);

    return { vendorDevices, loading: vendorDevicesLoading, setVendorDevices };
};
