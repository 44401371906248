import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../../../contexts';
import { ScheduleService, LocationSchedule } from '../../../../services/schedule-service';

const NUMBER_OF_RETRIES = 5;

export const useSchedule = (companyUUID: string, locationUUID: string) => {

    const { idToken } = useContext(AuthContext);
    const [schedules, setSchedules] = useState([]);

    const getSchedules = async (companyUUID: string, locationUUID: string) => {
        for (let i = 0; i < NUMBER_OF_RETRIES; i++) {
            const backoff = 1000 * 2 ** i;

        try{
            const results = await ScheduleService.browseSchedules(idToken, companyUUID, locationUUID);
            setSchedules(results.data.data);
        } catch(error) {
            console.log(
                `Error fetching schedules, retrying in ${backoff /
                        1000} seconds`,
            );
        }

        if (schedules) {
            break;
        }

        await new Promise((res) => setTimeout(res, backoff));
    }
    }

    useEffect(() => {
        if (!companyUUID || !locationUUID) {
            return;
        }

        getSchedules(companyUUID, locationUUID);
    }, [companyUUID, locationUUID]);

    return { schedules, setSchedules };
};

export default useSchedule;
