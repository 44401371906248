import { Axios } from '.';
import { VendorDeviceType } from './energy-device-service';

const THERMA_ENERGY_API_PROXY_URL =
    process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

export type EnergyLCPinScheduleType = {
    on: string;
    off: string;
};

export type EnergyLCPinType = {
    name: string;
    pin: number;
    schedule: {
        [key: string]: EnergyLCPinScheduleType;
    };
};

export type EnergyLoadControllerType = {
    uuid?: string;
    timezone?: string;
    identifier: string;
    locationUuid: string;
    pins: {
        [key: string]: EnergyLCPinType;
    };
    typeUuid: string;
};

/**
 * used for handling Load Controllers through the Therma energy api
 */
export class EnergyLoadControllerService {
    // get available load controllers
    public static async getAvailableLoadControllers(
        token: string,
    ): Promise<VendorDeviceType[]> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
            'load-controller/available',
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }
    // get load controllers by location uuid
    public static async getLoadControllersByMonitoringLocation(
        token: string,
        locationUuid: string,
    ): Promise<EnergyLoadControllerType[]> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
            `load-controller?locationUuid=${locationUuid}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }
    // commission load controller
    public static async commissionLoadController(
        token: string,
        loadController: EnergyLoadControllerType,
    ): Promise<EnergyLoadControllerType> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).post(
            'load-controller',
            loadController,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }
    // decommission load controller
    public static async deleteLoadController(
        token: string,
        loadControllerUuid: string,
    ): Promise<void> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).delete(
            `load-controller/${loadControllerUuid}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }
    // update load controller
    public static async updateLoadController(
        token: string,
        loadController: EnergyLoadControllerType,
        loadControllerUuid: string,
    ): Promise<EnergyLoadControllerType> {
        const request = await Axios.request(THERMA_ENERGY_API_PROXY_URL).patch(
            `load-controller/${loadControllerUuid}`,
            loadController.pins,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );

        return request.data ? request.data : [];
    }
}
