import { useContext } from 'react';

import { AuthContext } from '../../../../contexts';
import { EnergyEquipmentShiftingService } from '../../../../services/energy-equipment-shifting-service';

const useEnergyEquipmentShiftingService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        addEquipmentShiftingEntry: (entityUUID: string, type?: string) =>
            EnergyEquipmentShiftingService.createEquipmentShiftingEntry(entityUUID, idToken, type),
        removeEquipmentShiftingEntry: (uuid: string) =>
            EnergyEquipmentShiftingService.removeEquipmentShiftingEntry(uuid, idToken),
    };
};

export default useEnergyEquipmentShiftingService;
