import React from 'react';
import { Dropdown, Loader } from 'semantic-ui-react';

import { RawEnergyDeviceType } from '../../../../../services/energy-device-service';
import { capitalizeFirstLetter } from '../../hooks/useEnergyDeviceService';
import { DropdownWrapper } from '../../index';
import { LoaderWrapper } from '../../styles';
import { SINGLE_PHASE_METER_KEY, SINGLE_PHASE_METER_TEXT } from '../constants';

type Props = {
  energyDeviceTypes: RawEnergyDeviceType[] | null;
  selectedEnergyDeviceType: string | undefined;
  handleDeviceTypeChange: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: {
      value: string;
    },
  ) => void;
};

const EnergyDeviceDropdown = ({
  energyDeviceTypes,
  selectedEnergyDeviceType,
  handleDeviceTypeChange,
}: Props) => {
  const deviceTypeOptions = !!energyDeviceTypes
    ? [
        ...energyDeviceTypes.map((type: RawEnergyDeviceType) => ({
          key: type.uuid,
          text: `${capitalizeFirstLetter(type.vendor)} ${type.vendorModel}`,
          value: type.uuid,
        })),
      ]
    : [];
  return (
    <DropdownWrapper>
      {energyDeviceTypes ? (
        <Dropdown
          clearable
          required
          placeholder="Select Type"
          options={deviceTypeOptions}
          name="type"
          selection
          fluid
          value={selectedEnergyDeviceType}
          onChange={handleDeviceTypeChange}
          search
        />
      ) : (
        <LoaderWrapper>
          <Loader
            active
            size="tiny"
            style={{
              position: 'relative',
            }}
          />
        </LoaderWrapper>
      )}
    </DropdownWrapper>
  );
};

export default EnergyDeviceDropdown;
