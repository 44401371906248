import { Axios } from '.';

export interface Task {
    serialNumber: string;
    deviceType: 'dragino' | 'thermabeta';
}

export type StartTask = {
    mode: string;
    deviceUUID: string;
    startDate: string;
    duration?: number;
    setPoint: string;
    setUnit?: string;
    name: string;
};

export type TimedTaskType = {
    device_uuid: string;
    uuid: string;
    body: string;
    name: string;
    url: string;
    scheduledDateTime: string;
    method: string;
    taskType: string;
};

export type GetTaskReturnType = {
    data: TimedTaskType[];
};

const THERMA_ENERGY_API_PROXY_URL =
    process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

const THERMA_ENERGY_API_URL =
    process.env.THERMA_ENERGY_API_URL ?? 'http://localhost:3000';

export class TaskService {
    public static async getTasksForDevice(
        token: string,
        deviceUUID: string,
    ): Promise<GetTaskReturnType> {
        return Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
            `task/by-device/${deviceUUID}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        );
    }
    public static async start(
        token: string,
        {
            mode = '',
            deviceUUID = '',
            startDate = '',
            setPoint = '',
            name = '',
        }: StartTask,
    ) {
        // every task has a time `scheduledDateTime` (validated between mode start times)
        await Axios.request(THERMA_ENERGY_API_PROXY_URL).put(
            `/task`,
            {
                device_uuid: deviceUUID,
                url: `${THERMA_ENERGY_API_URL}/control/${deviceUUID}/${mode}`,
                body: JSON.stringify({
                    temperature: Number(setPoint),
                }),
                name,
                scheduledDateTime: startDate,
                method: 'post',
                taskType: 'timed',
            },
            { headers: { authorization: `Bearer ${token}` } },
        );
    }
    public static async terminate(token: string, deviceUUID: string) {
        await Axios.request(THERMA_ENERGY_API_PROXY_URL).post(
            `/task/deleteTimedTasks/byDevice/${deviceUUID}`,
            {},
            {
                headers: { authorization: `Bearer ${token}` },
            },
        );
    }
}
