import React from 'react';
import { Checkbox, Header } from 'semantic-ui-react';

import { FieldLabelWrapper, FieldWrapper } from './styles';

export const OccupiedHoursWeekday = ({
    setStartTime,
    setEndTime,
    header,
    startTime,
    endTime,
    headerMargin,
}: {
    setStartTime: Function;
    setEndTime: Function;
    header: string;
    startTime: string;
    endTime: string;
    headerMargin: string;
}) => {
    return (
        <FieldWrapper>
            <Header as="h4" style={{ marginRight: `${headerMargin}` }}>
                {' '}
                {header}:{' '}
            </Header>
            <FieldLabelWrapper style={{ display: 'inline-block' }}>
                <Header as="h5"> Closed: </Header>
                <Checkbox
                    checked={startTime === 'closed'}
                    style={{ paddingLeft: '20px' }}
                    onChange={() => {
                        if (startTime === 'closed') {
                            setStartTime('');
                            setEndTime('');
                        } else {
                            setStartTime('closed');
                            setEndTime('closed');
                        }
                    }}
                />
            </FieldLabelWrapper>
            <FieldLabelWrapper>
                <Header as="h5">Start time:</Header>
                <input
                    aria-label="Time"
                    type="time"
                    value={startTime !== 'closed' ? startTime : ''}
                    onChange={(e) => setStartTime(e.target.value)}
                    disabled={startTime === 'closed'}
                    required
                    name="start time"
                />
            </FieldLabelWrapper>
            <FieldLabelWrapper>
                <Header as="h5">End time:</Header>
                <input
                    aria-label="Time"
                    type="time"
                    value={endTime !== 'closed' ? endTime : ''}
                    onChange={(e) => setEndTime(e.target.value)}
                    disabled={endTime === 'closed'}
                    required
                    name="end time"
                />
            </FieldLabelWrapper>
        </FieldWrapper>
    );
};
