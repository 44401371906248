import { Responsive } from 'semantic-ui-react';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  .block{
    display: block;
  }

  .flex{
    display: flex;
  }

  .right,
  .float-right {
    float: right;
  }

  .left,
  .float-left{
    float: left;
  }

  .ease-transition {
    transition: 0.275s all ease;
  }

  .hidden {
    display: none;
  }

  .not-visible {
    visibility: hidden;
  }

  .default-cursor {
    cursor: default;
  }

  .text-center{
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .ellipse-text {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .unscrollable {
    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
      position: fixed;
      max-height: 100vh;
      overflow-y: hidden;
      width: 100%;
    }
  }

  .mobile-fixed {
    @media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
      position: fixed;
    }
  }

  .break-word {
    word-break: break-word;
  }

  .location-dropdown.not-exist > .text {
    color: rgb(209, 215, 227);
  }

  .location-dropdown > .label.not-exist,
  .location-dropdown > .label.not-exist:hover {
    color: rgb(168, 172, 181);
  }

  .flex-1 {
    flex: 1 1 0%;
  }
`;
