import { thermaGrey, thermaH2FontFamily } from '@coinspect/ui';
import React, { useContext } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Header, Menu, Responsive } from 'semantic-ui-react';
import styled from 'styled-components';

import { AuthContext } from '../contexts';

const LinkLabel = styled(NavLink)`
    color: rgba(255, 255, 255, 0.9);
    padding: 4px 0;
    margin: 14px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    &:hover {
        color: #fff;
    }
    &.active {
        border-bottom: 2px #fff solid;
    }

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        display: block;
        color: rgba(255, 255, 255, 0.9);
        font-weight: bold;
        font-size: 18px;
        padding: 10px 0;

    @media only screen and (max-width: 995px) {
        display: inline-block;
        color: rgba(255, 255, 255, 0.9);
        font-weight: bold;
        letter-spacing: 0.5px;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        padding: 0px 5px;
        margin: 14px 0;
        height: max-content;
        &.active {
            font-weight: bold;
            color: rgba(255, 255, 255, 0.9);
        }
        &:hover {
            color: rgba(26, 17, 51, 0.8);
        }
    }
`;

const LogoutLabel = styled(NavLink)`
    color: rgba(255, 255, 255, 0.9);
    padding: 4px 14px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    &:hover {
        color: #fff;
    }
`;

const NavbarContainer = styled(Menu)`
    && {
        height: 74px;
        background-color: ${thermaGrey};
        display: block;
        color: rgba(255, 255, 255, 1);
        padding: 25px 68px 16px;
        box-shadow: 0 2px 2px 0 rgba(25, 40, 63, 0.3);
        transform: translate3d(0, 0, 0);

        .ui.header {
            font-family: ${thermaH2FontFamily};
        }

        @media (max-width: 995px) {
            height: max-content;
            padding: 25px 68px 16px;
        }
    }
`;

const NavBarPlaceholder = styled.div`
    position: relative;
    height: 73px;
`;

const AdminLink = (args: NavLinkProps): JSX.Element => {
    const authContext = useContext(AuthContext);

    if (!authContext.hasUser) {
        return <></>;
    }
    return <LinkLabel {...args} />;
};

export const NavigationBar = () => {
    const userContext = useContext(AuthContext);
    const { logout } = userContext;

    return (
        <>
            <NavBarPlaceholder />
            <NavbarContainer fixed="top">
                <Header>
                    <div className="right">
                        <AdminLink to="/devices" activeClassName="active">
                            Devices
                        </AdminLink>
                        <AdminLink to="/gateways" activeClassName="active">
                            Gateways
                        </AdminLink>
                        <AdminLink to="/gatewayassets" activeClassName="active">
                            Gateway Assets
                        </AdminLink>
                        <AdminLink
                            to="/energy-billing-form"
                            activeClassName="active"
                        >
                            Energy Billing Form
                        </AdminLink>
                        <AdminLink to="/tots" activeClassName="active">
                            TOTS Control
                        </AdminLink>
                        <AdminLink
                            to="/system-commissioning"
                            activeClassName="active"
                        >
                            System Commissioning
                        </AdminLink>
                        <AdminLink to="/users" activeClassName="active">
                            Users
                        </AdminLink>
                        <AdminLink to="/deployments" activeClassName="active">
                            Deployments
                        </AdminLink>
                        <AdminLink to="/tools" activeClassName="active">
                            Tools
                        </AdminLink>
                        <LogoutLabel
                            to="/"
                            onClick={logout}
                            activeClassName="active"
                        >
                            Logout
                        </LogoutLabel>
                    </div>
                </Header>
            </NavbarContainer>
        </>
    );
};
