import { getExcludeEcobeeFlagValue } from '../utils/local-flags';
import { Axios } from '.';

export type EquipmentType = {
  name: string;
  equipmentType: string;
  uuid: string;
};

export type ControlDevice = {
  deviceUUID: string;
  temperature: number;
  mode: string;
};

export type HVACMode = 'heat' | 'cool' | 'auto';

export type EnergyDeviceMetadata = {
  equipmentName?: string;
  referenceVoltage?: string;
  ctType?: string;
  model?: string;
  currentMultiplier?: number;
  energyDeviceUUID?: string;
};

export type SetPointMetadata = {
  occupiedHeatSetPoint?: number;
  occupiedCoolSetPoint?: number;
  unoccupiedHeatSetPoint?: number;
  unoccupiedCoolSetPoint?: number;
  hvacMode?: HVACMode;
  name?: string;
};

export type RawEnergyDevice = {
  uuid: string;
  deviceType: RawEnergyDeviceType;
  vendorId: string;
  equipment: EquipmentType;
  vendorDevice: VendorDeviceType;
  metadata: SetPointMetadata;
};

export type RawEnergyDeviceType = {
  vendor: string;
  vendorModel: string;
  uuid: string;
};

export type VendorDeviceType = {
  name: string;
  id: string;
  vendor: string;
  type?: string;
};

export type CreateDeviceParams = {
  deviceTypeUUID: string;
  vendorId: string;
  meterUUID: string;
  equipmentUUID?: string;
  metadata: SetPointMetadata;
  locationUUID?: string;
};

export const ECOBEE_VENDOR = 'ecobee';
export const SHELLY_VENDOR = 'shelly';
export const MCCI_VENDOR = 'mcci';
export const MEKI_VENDOR = 'meki';
export const ENGINKO_VENDOR = 'enginko';
export const MILESIGHT_VENDOR = 'milesight';

// TODO: Cleanup after ecobee api issue is resolved. See USDIB-597 for more info.
const excludeEcobeeVendor = getExcludeEcobeeFlagValue();
const AVAILABLE_VENDORS = [
  excludeEcobeeVendor ? null : ECOBEE_VENDOR,
  SHELLY_VENDOR,
  MCCI_VENDOR,
  ENGINKO_VENDOR,
  MEKI_VENDOR,
  MILESIGHT_VENDOR,
]
  .filter(Boolean)
  .join(',');

const THERMA_ENERGY_API_PROXY_URL =
  process.env.THERMA_ENERGY_API_PROXY_URL ?? 'http://localhost:3001/energy';

// v2 api does not use the /energy route, so we need to carefully remove it
const START_INDEX = 0;
const re = /\/energy$/;
const res = THERMA_ENERGY_API_PROXY_URL.match(re);
const THERMA_ENERGY_API_PROXY_URL_V2 =
  res && res.index
    ? THERMA_ENERGY_API_PROXY_URL.substring(START_INDEX, res.index)
    : THERMA_ENERGY_API_PROXY_URL;

/**
 * used for handling devices through the Therma energy api
 */
export class EnergyDeviceService {
  public static async getDevicesByVendorIds(
    token: string,
    vendorIds: string[],
  ) {
    return Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
      `device/by-vendor-ids?vendorId=${vendorIds.join(',')}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
  }

  public static async getDevicesByEnergyLocation(
    token: string,
    energyLocationUUID: string,
  ): Promise<RawEnergyDevice[]> {
    const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
      `device/by-energy-location/${energyLocationUUID}?vendor=${AVAILABLE_VENDORS}&includeEquipment=true&includeVendorDevice=true`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data ? response.data : [];
  }

  public static async control(
    token: string,
    { deviceUUID, temperature, mode }: ControlDevice,
  ) {
    return Axios.request(THERMA_ENERGY_API_PROXY_URL).post(
      `control/${deviceUUID}/${mode}`,
      {
        temperature,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
  }

  public static async delete(token: string, uuid: string) {
    const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL).delete(
      `device/${uuid}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data ? response.data : undefined;
  }

  public static async create(
    token: string,
    {
      deviceTypeUUID,
      vendorId,
      meterUUID,
      equipmentUUID,
      metadata,
      locationUUID,
    }: CreateDeviceParams,
  ) {
    const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL_V2).post(
      `api/v2/energy-devices`,
      {
        equipmentUUID,
        deviceTypeUUID,
        vendorId,
        metadata,
        locationUUID,
        meterUUID,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data?.data ? response.data.data : undefined;
  }
  public static async getDeviceTypes(
    token: string,
  ): Promise<RawEnergyDeviceType[]> {
    const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
      `device/device-types?vendor=${AVAILABLE_VENDORS}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data ? response.data : [];
  }

  public static async getEquipment(
    token: string,
    monitoringLocationUUID: string,
  ): Promise<EquipmentType[]> {
    const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL).get(
      `device/therma/equipment?monitoringLocationUuid=${monitoringLocationUUID}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data ? response.data : [];
  }

  public static async getAvailableVendorDevices(
    token: string,
  ): Promise<VendorDeviceType[]> {
    const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL_V2).get(
      `api/v2/energy-devices/vendor/available?vendor=${AVAILABLE_VENDORS}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data?.data ? response.data.data : [];
  }

  public static async updateDeviceSetpoints(
    token: string,
    metadata: SetPointMetadata,
    deviceUUID: string,
  ): Promise<SetPointMetadata> {
    const response = await Axios.request(THERMA_ENERGY_API_PROXY_URL_V2).put(
      `api/v2/energy-devices/${deviceUUID}`,
      {
        metadata,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data ? response.data : {};
  }
}
