import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import { EnergyDevice } from './hooks/useEnergyDeviceService';
import Toggle from './energy-toggle';
import { EnergyEquipmentShiftingType } from '../../../services/energy-equipment-shifting-service';
import useEnergyEquipmentShiftingService from './hooks/useEnergyEquipmentShiftingService';
import useEnergyEquipmentShiftingConfigService from './hooks/useEnergyEquipmentShiftingConfigService';
import { EnergyEquipmentShiftingConfigType } from '../../../services/energy-shifting-config-service';
import {
  ConfigDropdownLabel,
  ConfigErrorMessage,
  ConfigOptionCheckContainer,
  ConfigOptionContainter,
  EquipmentConfig,
  EquipmentConfigBottom,
  EquipmentConfigContainer,
  EquipmentConfigTop,
  EquipmentName,
  ShiftingCommandsDisabled
} from './equipment-time-of-use-config-styles';

const configOptions = [{
  text: 'Default',
  value: 'default',
},{
  text: 'Custom',
  value: 'custom',
}];

const timeDropdownOptions = [{
  text: '30 mins',
  value: 30,
}, {
  text: '1 hour',
  value: 60,
}, {
  text: '1 hour 30 mins',
  value: 90,
}, {
  text: '2 hours',
  value: 120,
}]

const DEFAULT_TEMP = 1;
const DEFAULT_DURATION = 120;
const MAX_DEGREES = 10;

const generateDegreeDropdownOptions = () => {
  const options = [];
  for (let i = 1; i <= MAX_DEGREES; i++) {
    options.push({
      text: `${i} degree${i > 1 ? 's' : ''}`,
      value: i,
    })
  }
  return options;
}

const EquipmentTOUConfig = ({
  companyUUID,
  device,
  handleConfigUpdate,
  loadShiftingEntry,
  isShiftingDisabled,
  onEquipmentShiftingUpdate,
  peakConfig,
  preCoolConfig,
}: {
  companyUUID: string;
  device: EnergyDevice;
  handleConfigUpdate: Function;
  loadShiftingEntry?: EnergyEquipmentShiftingType;
  isShiftingDisabled: boolean;
  onEquipmentShiftingUpdate: Function;
  peakConfig?: EnergyEquipmentShiftingConfigType;
  preCoolConfig?: EnergyEquipmentShiftingConfigType;
}) => {
  const [precoolTemp, setPrecoolTemp] = useState<number>(DEFAULT_TEMP);
  const [peakTemp, setPeakTemp] = useState<number>(DEFAULT_TEMP);
  const [precoolDuration, setPrecoolDuration] = useState<number>(DEFAULT_DURATION);
  const [peakDuration, setPeakDuration] = useState<number>(DEFAULT_DURATION);
  const [isPrecoolEnabled, setIsPrecoolEnabled] = useState<boolean>(false);
  const [isPeakEnabled, setIsPeakEnabled] = useState<boolean>(false);

  const [configOption, setConfigOption] = useState<string>('default');
  const [isTOUEnabled, setIsTOUEnabled] = useState<boolean>(true);
  const [isShiftingUpdateLoading, setIsShiftingUpdateLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const isCustom = configOption === 'custom';
  const isHVAC = device.equipmentType === 'HVAC';

  const {
    addEquipmentShiftingEntry,
    removeEquipmentShiftingEntry,
  } = useEnergyEquipmentShiftingService();
  const {
    addEquipmentShiftingConfig,
    updateEquipmentShiftingConfig,
    removeEquipmentShiftingConfig,
  } = useEnergyEquipmentShiftingConfigService();

  const changeToggle = (value: string) => {
    setConfigOption(value);
    if (!isHVAC) {
      setIsPeakEnabled(value === 'custom')
    }
  }

  const resetValues  = () => {
    setPrecoolTemp(DEFAULT_TEMP);
    setPeakTemp(DEFAULT_TEMP);
    setPrecoolDuration(DEFAULT_DURATION);
    setPeakDuration(DEFAULT_DURATION);
    setIsPrecoolEnabled(false);
    setIsPeakEnabled(false);
  }

  const requiresUpdate = (
    prevConfig: EnergyEquipmentShiftingConfigType,
    nextConfig: { temp: number, duration: number },
  ) => {
    if (device.equipmentType === 'hvac') {
      return prevConfig && (
        prevConfig.duration !== nextConfig.duration ||
        prevConfig.metadata?.temperatureDelta !== nextConfig.temp
      );
    }

    return prevConfig && prevConfig.duration !== nextConfig.duration;
  }

  const handleOnChangeWith = (handlerFn) => {
    return (e, data) => {
      if (data.value) {
        handlerFn(data.value);
        setHasError(false);
      }
    }
  }

  const handleTOUCheck = async () => {
    const nextTOUState = !isTOUEnabled;
    setIsShiftingUpdateLoading(true);
    const updatedState = [];

    if(nextTOUState && loadShiftingEntry) {
      await removeEquipmentShiftingEntry(loadShiftingEntry.uuid)
    } else {
      updatedState.push(await addEquipmentShiftingEntry(device.uuid, 'load_shifting'))
    }

    onEquipmentShiftingUpdate(updatedState);
    setIsShiftingUpdateLoading(false);
  }

  const onSubmit = async () => {
    if(isHVAC && isCustom && !isPrecoolEnabled && !isPeakEnabled) {
      setHasError(true);
      return;
    }

    if (!isCustom) {
      // Delete all configs
      if (preCoolConfig) {
        await removeEquipmentShiftingConfig(preCoolConfig.uuid);
        handleConfigUpdate([], true);
      }
      if (peakConfig) {
        await removeEquipmentShiftingConfig(peakConfig.uuid);
        handleConfigUpdate([], false);
      }
    }

    if (isPrecoolEnabled) {
      // if not previously enabled, create
      if (!preCoolConfig) {
        const addedConfig = await addEquipmentShiftingConfig({
          companyUUID,
          duration: precoolDuration,
          entityUUID: device.uuid,
          temperatureDelta: precoolTemp,
          type: 'time_of_use_pre_cooling',
        });
        // update top level state
        handleConfigUpdate([addedConfig], true);

      // if already enabled but new values, update
      } else if (requiresUpdate(preCoolConfig, { duration: precoolDuration, temp: precoolTemp })) {
        const updatedConfig = await updateEquipmentShiftingConfig({
          companyUUID,
          duration: precoolDuration,
          entityUUID: device.uuid,
          temperatureDelta: precoolTemp,
          type: 'time_of_use_pre_cooling',
          uuid: preCoolConfig.uuid,
        });
        // update top level state
        handleConfigUpdate([updatedConfig], true);
      }

      // if already enabled and no updates, skip
    } else {
      // if config exists, delete
      if (!!preCoolConfig) {
        await removeEquipmentShiftingConfig(preCoolConfig.uuid);
        // update top level state
        handleConfigUpdate([], true);
      }

      // if no config, skip
    }

    if (isPeakEnabled) {
      // if not previously enabled, create
      if (!peakConfig) {
        const addedConfig = await addEquipmentShiftingConfig({
          companyUUID,
          duration: peakDuration,
          entityUUID: device.uuid,
          temperatureDelta: peakTemp,
          type: 'load_shifting',
        });
        // update top level state
        handleConfigUpdate([addedConfig], false);

      // if already enabled but new values, update
      } else if (requiresUpdate(peakConfig, { duration: peakDuration, temp: peakTemp })) {
        const updatedConfig = await updateEquipmentShiftingConfig({
          companyUUID,
          duration: peakDuration,
          entityUUID: device.uuid,
          temperatureDelta: peakTemp,
          type: 'load_shifting',
          uuid: peakConfig.uuid,
        });
        // update top level state
        handleConfigUpdate([updatedConfig], false);
      }

      // if already enabled and no updates, skip
    } else {
      // if config exists, delete
      if (!!peakConfig) {
        await removeEquipmentShiftingConfig(peakConfig.uuid);
        // update top level state
        handleConfigUpdate([], false);
      }

      // if no config, skip
    }
  }

  useEffect(() => {
    setIsTOUEnabled(!!!loadShiftingEntry);
  }, [loadShiftingEntry]);

  useEffect(() => {
    if (preCoolConfig) {
      setConfigOption('custom');
      setIsPrecoolEnabled(true);
      setPrecoolDuration(preCoolConfig.duration);
      setPrecoolTemp(preCoolConfig.metadata?.temperatureDelta);
    } else {
      resetValues();
    }
  }, [preCoolConfig]);

  useEffect(() => {
    if (peakConfig) {
      setConfigOption('custom');
      setIsPeakEnabled(true);
      setPeakDuration(peakConfig.duration);
      setPeakTemp(peakConfig.metadata?.temperatureDelta);
    } else {
      resetValues();
    }
  }, [peakConfig]);

  return (
    <EquipmentConfigContainer>
      <EquipmentConfigTop>
        <EquipmentName>
          <Checkbox
            checked={isTOUEnabled && !isShiftingDisabled}
            onChange={handleTOUCheck}
            disabled={isShiftingDisabled || isShiftingUpdateLoading}
            label={device.equipmentName}
          />
        </EquipmentName>
        {isTOUEnabled && !isShiftingDisabled &&
          <EquipmentConfig>
            <div style={{paddingBottom: '8px'}}>Configurations</div>
            <Toggle
              options={configOptions}
              selected={configOption}
              onChange={changeToggle}
            />
            {
              isCustom && device.equipmentType === 'HVAC' && (
                <>
                  {hasError && (
                    <ConfigErrorMessage>
                      Custom setpoint configurations require at least one selection of pre-cool or peak.
                    </ConfigErrorMessage>
                  )}
                  <ConfigOptionContainter>
                    <ConfigOptionCheckContainer>
                      <Checkbox
                        label="Lower setpoint to pre-cool"
                        checked={isPrecoolEnabled}
                        onChange={() => setIsPrecoolEnabled(!isPrecoolEnabled)}
                      />
                    </ConfigOptionCheckContainer>
                    <div style={{width: '30%'}}>
                      <ConfigDropdownLabel>By how many degrees</ConfigDropdownLabel>
                      <Dropdown
                        options={generateDegreeDropdownOptions()}
                        search
                        clearable
                        required
                        name="Pre-cool temperature delta"
                        selection
                        fluid
                        value={precoolTemp}
                        onChange={handleOnChangeWith(setPrecoolTemp)}
                        disabled={!isPrecoolEnabled}
                      />
                    </div>
                    <div style={{width: '30%'}}>
                      <ConfigDropdownLabel>For how long</ConfigDropdownLabel>
                      <Dropdown
                        options={timeDropdownOptions}
                        search
                        clearable
                        required
                        name="Pre-cool duration"
                        selection
                        fluid
                        value={precoolDuration}
                        onChange={handleOnChangeWith(setPrecoolDuration)}
                        disabled={!isPrecoolEnabled}
                      />
                    </div>
                  </ConfigOptionContainter>
                  <ConfigOptionContainter>
                    <ConfigOptionCheckContainer>
                      <Checkbox
                        label="Increase setpoint during peak period"
                        checked={isPeakEnabled}
                        onChange={() => setIsPeakEnabled(!isPeakEnabled)}
                      />
                    </ConfigOptionCheckContainer>
                    <div style={{width: '30%'}}>
                      <ConfigDropdownLabel>By how many degrees</ConfigDropdownLabel>
                      <Dropdown
                        options={generateDegreeDropdownOptions()}
                        search
                        clearable
                        required
                        name="Peak temperature delta"
                        selection
                        fluid
                        value={peakTemp}
                        onChange={handleOnChangeWith(setPeakTemp)}
                        disabled={!isPeakEnabled}
                      />
                    </div>
                    <div style={{width: '30%'}}>
                      <ConfigDropdownLabel>For how long</ConfigDropdownLabel>
                      <Dropdown
                        options={timeDropdownOptions}
                        search
                        clearable
                        required
                        name="Peak duration"
                        selection
                        fluid
                        value={peakDuration}
                        onChange={handleOnChangeWith(setPeakDuration)}
                        disabled={!isPeakEnabled}
                      />
                    </div>
                  </ConfigOptionContainter>
                </>
              )
            }
            {
              isCustom && device.equipmentType !== 'HVAC' && (
                <>
                  <ConfigDropdownLabel style={{marginTop: '8px'}}>For how long?</ConfigDropdownLabel>
                  <Dropdown
                    options={timeDropdownOptions}
                    search
                    clearable
                    required
                    name="duration"
                    selection
                    value={peakDuration}
                    onChange={handleOnChangeWith(setPeakDuration)}
                  />
                </>
              )
            }
          </EquipmentConfig>
        }
        {!isTOUEnabled && !isShiftingDisabled && (
          <div style={{color: '#7D7D7D', fontWeight: 400}}>
            Time of Use disabled for this equipment
          </div>
        )}
        {isShiftingDisabled && (
          <ShiftingCommandsDisabled>
            All shifting commands disabled for this equipment
          </ShiftingCommandsDisabled>
        )}
      </EquipmentConfigTop>
      {isTOUEnabled && !isShiftingDisabled &&
        <EquipmentConfigBottom>
          <Button
            onClick={onSubmit}
            color="green"
          >
            Submit
          </Button>
        </EquipmentConfigBottom>
      }
    </EquipmentConfigContainer>
  );
}

export default EquipmentTOUConfig;
