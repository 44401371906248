import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { RightPaddedDiv } from '..';
import { ListContainer, ListItem } from '../list';

interface SensorDataProps {
    sensors: string[];
}

const TableCell = styled(RightPaddedDiv)`
    text-align: center;
`;

export const SensorDisplayList: FunctionComponent<SensorDataProps> = (
    props,
) => {
    const { sensors } = props;

    const timeZone = dayjs.tz.guess();

    return (
        <div>
            <ListContainer>
                <ListItem>
                    <TableCell className="bold flex-1">Serial Number</TableCell>
                    <TableCell className="bold flex-1">Created At</TableCell>
                    <TableCell className="bold flex-1">Location UUID</TableCell>
                    <TableCell className="bold flex-1">Name</TableCell>
                    <TableCell className="bold flex-1">Type</TableCell>
                </ListItem>

                {sensors.map((sensor, index) => (
                    <ListItem key={index}>
                        <TableCell className="flex-1">
                            {sensor.serial_number}
                        </TableCell>
                        <TableCell className="flex-1">
                            {dayjs
                                .utc(sensor.created_at)
                                .tz(timeZone)
                                .format('MM/DD/YYYY HH:mm z')}
                        </TableCell>
                        <TableCell className="flex-1">
                            {sensor.location_uuid}
                        </TableCell>
                        <TableCell className="flex-1">{sensor.name}</TableCell>
                        <TableCell className="flex-1">{sensor.type}</TableCell>
                    </ListItem>
                ))}
            </ListContainer>
        </div>
    );
};
