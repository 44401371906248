import React, {
    FunctionComponent,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import {
    Button,
    Dropdown,
    Header,
    Loader,
    Responsive,
} from 'semantic-ui-react';
import styled from 'styled-components';

import {
    Company,
    MonitoringLocation,
} from '../../../services/energy-location-service';
import { DeleteModal } from './delete-modal';
import { useEnergyDevices } from './hooks/useEnergyDevices';
import useEnergyDeviceService, {
    EnergyDevice,
} from './hooks/useEnergyDeviceService';
import { useEnergyLocation } from './hooks/useEnergyLocation';
import useEnergyLocationService from './hooks/useEnergyLocationService';
import { useMeters } from './hooks/useMeters';
import { useMonitoringEquipments } from './hooks/useMonitoringEquipments';
import { useVendorDevices } from './hooks/useVendorDevices';
import { SystemCommissioningBody } from './system-commissioning-body';
import { WarningModal } from './warning-modal';
import { useLeapMeters } from './hooks/useLeapMeters';
import { useEnergyTimeOfUse } from './hooks/useEnergyTimeOfUse';
import { EnergyTimeOfUseType } from '../../../services/energy-time-of-use-service';
import { useEnergyEquipmentShifting } from './hooks/useEnergyEquipmentShifting';
import { useSchedule } from './hooks/useSchedule';
import { EnergyEquipmentShiftingType } from '../../../services/energy-equipment-shifting-service';
import { useEnergyEquipmentShiftingConfigs } from './hooks/useEnergyEquipmentShiftingConfig';
import { EnergyEquipmentShiftingConfigType } from '../../../services/energy-shifting-config-service';
import { SetPointMetadata } from 'services/energy-device-service';

const Container = styled.div`
    margin: 10px 10px 10px 10px;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        margin: 100px 10px 10px 10px;
    }

    @media only screen and (min-width: ${Responsive.onlyWidescreen
            .minWidth}px) {
        margin: 10px 15% 10px 15%;
    }

    .ui.button {
        margin: 0;
    }
`;

const LocationCustomerHeaderContainer = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 15px 40px 15px 40px;
    gap: 40px;
    justify-content: space-evenly;
    margin-bottom: 15px;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        gap: 20px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

export const HeaderContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex: 2;
    height: 100%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    width: max-content;
`;

interface EnergySystemCommissioningState {
    selectedCompany: Company | null;
    selectedMonitoringLocation: MonitoringLocation | null;
    companies: Company[];
    modal: {
        type: string;
        text: string;
        device?: EnergyDevice;
    } | null;
}

const INITIAL_STATE: EnergySystemCommissioningState = {
    selectedCompany: null,
    selectedMonitoringLocation: null,
    companies: [],
    modal: {
        type: '',
        text: '',
        device: undefined,
    },
};

const reducer = (
    state: {
        selectedCompany: Company | null;
        selectedMonitoringLocation: MonitoringLocation | null;
        companies: Company[];
        modal: {
            type: string;
            text: string;
            device?: EnergyDevice;
        } | null;
    },
    action: {
        type: string;
        selectedCompany?: Company | null;
        selectedMonitoringLocation?: MonitoringLocation | null;
        companies?: Company[];
        modalText?: string;
        modalType?: string;
        device?: EnergyDevice;
    },
): typeof INITIAL_STATE => {
    switch (action.type) {
        case 'SET_SELECTED_COMPANY':
            return {
                ...state,
                selectedCompany: action.selectedCompany as Company,
            };
        case 'SET_COMPANIES':
            return {
                ...state,
                companies: action.companies as Company[],
            };
        case 'SET_SELECTED_MONITORING_LOCATION':
            return {
                ...state,
                selectedMonitoringLocation: action.selectedMonitoringLocation as MonitoringLocation,
            };
        case 'CLOSE_MODAL':
            return {
                ...state,
                modal: {
                    type: '',
                    text: '',
                },
            };
        case 'OPEN_MODAL':
            return {
                ...state,
                modal: {
                    type: action.modalType as string,
                    text: action.modalText as string,
                    device: action.device as EnergyDevice,
                },
            };
        default:
            return INITIAL_STATE;
    }
};

export const EnergySystemCommissioningPage: FunctionComponent<{}> = () => {
    const energyLocationService = useEnergyLocationService();
    const energyDeviceService = useEnergyDeviceService();

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const [deleteDeviceLoading, setDeleteDeviceLoading] = useState<boolean>(
        false,
    );

    const [updateMeterLoading, setUpdateMeterLoading] = useState<boolean>(
        false,
    );

    const [updateLeapMeterLoading, setUpdateLeapMeterLoading] = useState<boolean>(
        false,
    );

    const [selectedMeter, setSelectedMeter] = useState<string>('');

    const [selectedLeapMeter, setSelectedLeapMeter] = useState<string>('');

    const {
        companies,
        selectedCompany,
        selectedMonitoringLocation,
        modal,
    } = state;

    const {
        vendorDevices,
        loading: vendorDevicesLoading,
        setVendorDevices,
    } = useVendorDevices();

    const { equipments } = useMonitoringEquipments(selectedMonitoringLocation);

    const {
        energyLocation,
        loading: energyLocationLoading,
        setEnergyLocation,
        getEnergyLocation,
    } = useEnergyLocation(selectedMonitoringLocation);

    const {
        energyDevices,
        loading: energyDevicesLoading,
        setEnergyDevices,
    } = useEnergyDevices(energyLocation);

    const {
        energyTimeOfUse,
        loading: timeOfUseLoading,
        setEnergyTimeOfUse
    } = useEnergyTimeOfUse(energyLocation);
    
    const { 
        schedules,
        setSchedules,
    } = useSchedule(selectedCompany?.uuid, energyLocation?.monitoringLocationUuid);

    const {
        energyEquipmentLoadShifting,
        energyEquipmentShifting,
        loading: energyEquipmentShiftingLoading,
        setEnergyEquipmentLoadShifting,
        setEnergyEquipmentShifting,
    } = useEnergyEquipmentShifting(energyDevices);

    const {
        equipmentLoadShiftingConfigs,
        equipmentPreCoolConfigs,
        setEquipmentLoadShiftingConfigs,
        setEquipmentPreCoolConfigs,
    } = useEnergyEquipmentShiftingConfigs(energyDevices);

    const { meters, loading: metersLoading } = useMeters(energyLocation);
    const { leapMeters, loading: leapMetersLoading } = useLeapMeters(energyLocation);

    const getCompanies = async () => {
        const companies = await energyLocationService.getAllCompaniesWithMonitoringLocations();
        dispatch({
            type: 'SET_COMPANIES',
            companies,
        });
    };

    const customerDropdownValues = companies.map(({ uuid, name }) => ({
        text: name,
        value: uuid,
    }));
    const locationDropdownValues = selectedCompany
        ? selectedCompany.monitoringLocations.map(({ uuid, name }) => ({
              text: name,
              value: uuid,
          }))
        : [];

    const onCustomerSelected = (
        _: unknown,
        { value: selectedCompanyUUID }: { value: string },
    ) => {
        const company = companies.find(
            ({ uuid }) => selectedCompanyUUID === uuid,
        );
        if (!company) {
            throw new Error('Selected company does not exist in state!');
        }
        dispatch({
            type: 'SET_SELECTED_COMPANY',
            selectedCompany: company,
        });

        dispatch({
            type: 'SET_SELECTED_MONITORING_LOCATION',
            selectedMonitoringLocation: null,
        });
    };

    const onLocationSelected = (
        _: unknown,
        { value: selectedLocationUUID }: { value: string },
    ) => {
        const location = selectedCompany?.monitoringLocations.find(
            ({ uuid }) => selectedLocationUUID === uuid,
        );
        if (!location) {
            throw new Error('Selected location does not exist in state!');
        }
        dispatch({
            type: 'SET_SELECTED_MONITORING_LOCATION',
            selectedMonitoringLocation: location,
        });
    };

    const handleEnroll = async (monitoringLocationUUID: string) => {
        await energyLocationService.createLocationbyMonitoring(
            monitoringLocationUUID,
        );
        await getEnergyLocation(monitoringLocationUUID);
    };
    const handleUnEnrollConfirm = async (monitoringLocationUUID: string) => {
        await energyLocationService.removeLocationbyMonitoring(
            monitoringLocationUUID,
        );
        await getEnergyLocation(monitoringLocationUUID);
        dispatch({
            type: 'CLOSE_MODAL',
        });
    };
    const handleUnEnroll = async () => {
        if (selectedMeter || selectedLeapMeter || energyDevices?.length) {
            dispatch({
                type: 'OPEN_MODAL',
                modalType: 'WARNING_MODAL',
                modalText:
                    'Need to remove both the meter id and all devices before unenrolling this location',
            });
            return;
        }
        dispatch({
            type: 'OPEN_MODAL',
            modalType: 'DELETE_MODAL',
            modalText: 'Confirm to unenroll this location',
        });
    };

    const handleDeviceDeletedConfirm = async (device: EnergyDevice) => {
        if (!energyDevices) {
            throw new Error('Energy devices should not be null!');
        }

        if (!vendorDevices) {
            throw new Error('Vendor devices should not be null');
        }
        const { uuid: deviceUUID, vendor, deviceName, deviceId } = device;
        dispatch({ type: 'CLOSE_MODAL' });
        setDeleteDeviceLoading(true);
        const deletedDevice = await energyDeviceService.delete(deviceUUID);
        if (!deletedDevice) {
            toast.error('🤖 Could not delete device!');
            return;
        }
        const newDevices = energyDevices.filter(
            ({ uuid }) => uuid !== deviceUUID,
        );
        const vendorDevice = {
            vendor,
            id: deviceId,
            name: deviceName,
        };
        setEnergyDevices(newDevices);
        setVendorDevices([...vendorDevices, vendorDevice]);
        toast.success('🤖 Device successfully deleted!');
        setDeleteDeviceLoading(false);
    };
    const handleDeviceDeleted = async (device: EnergyDevice) => {
        dispatch({
            type: 'OPEN_MODAL',
            modalType: 'DELETE_DEVICE_MODAL',
            modalText: 'Confirm remove device',
            device,
        });
    };

    const handleDeleteMeterConfirm = async () => {
        dispatch({ type: 'CLOSE_MODAL' });
        setUpdateMeterLoading(true);
        if (!energyLocation) {
            throw new Error(`There should be an energy location!`);
        }
        const { sucess } = await energyLocationService.removeMeter(
            energyLocation?.uuid as string,
        );
        if (sucess) {
            setSelectedMeter('');
            setEnergyLocation({
                ...energyLocation,
                utilityProviderId: 'n/a',
            });
            toast.success('🦄 Successly removed meter!');
        } else {
            toast.error('Could not remove meter!');
        }
        setUpdateMeterLoading(false);
    };

    const handleDeleteMeter = async () => {
        dispatch({
            type: 'OPEN_MODAL',
            modalType: 'DELETE_METER_MODAL',
            modalText: 'Confirm delete meter',
        });
    };

    const handleDeleteLeapMeterConfirm = async () => {
        dispatch({ type: 'CLOSE_MODAL' });
        setUpdateLeapMeterLoading(true);
        if (!energyLocation) {
            throw new Error(`There should be an energy location!`);
        }
        const { message } = await energyLocationService.removeLeapMeter(
            energyLocation?.uuid as string,
        );
        if (message === 'Successfully updated entry') {
            setSelectedLeapMeter('');
            setEnergyLocation({
                ...energyLocation,
                demandResponseProviderId: 'n/a',
            });
            toast.success('🦄 Successly removed leap meter!');
        } else {
            toast.error('Could not remove leap meter!');
        }
        setUpdateLeapMeterLoading(false);
    };

    const handleDeleteLeapMeter = async () => {
        dispatch({
            type: 'OPEN_MODAL',
            modalType: 'DELETE_LEAP_METER_MODAL',
            modalText: 'Confirm delete leap meter',
        });
    };

    const handleMeterChange = (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: string },
    ) => {
        setSelectedMeter(data.value);
    };

    const handleLeapMeterChange = (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: string },
    ) => {
        setSelectedLeapMeter(data.value);
    };

    const handleMeterUpdate = async () => {
        setUpdateMeterLoading(true);
        if (!energyLocation) {
            throw new Error(`There should be an energy location!`);
        }
        const { sucess } = await energyLocationService.addMeter(
            energyLocation.uuid,
            selectedMeter,
        );
        if (sucess) {
            setEnergyLocation({
                ...energyLocation,
                utilityProviderId: selectedMeter,
            });
            toast.success('🦄 Successly updated meter!');
        } else {
            toast.error('Could not update meter!');
        }
        setUpdateMeterLoading(false);
    };

    const handleLeapMeterUpdate = async () => {
        setUpdateLeapMeterLoading(true);
        if (!energyLocation) {
            throw new Error(`There should be an energy location!`);
        }
        const { message } = await energyLocationService.addLeapMeter(
            energyLocation.uuid,
            selectedLeapMeter,
        );
        if (message === 'Successfully updated entry') {
            setEnergyLocation({
                ...energyLocation,
                demandResponseProviderId: selectedLeapMeter,
            });
            toast.success('🦄 Successly updated leap meter!');
        } else {
            toast.error('Could not update leap meter!');
        }
        setUpdateLeapMeterLoading(false);
    };

    const handleDeviceCreated = (devices: EnergyDevice[]) => {
        setEnergyDevices(devices);
        if (!vendorDevices) {
            throw new Error(
                'There should be vendor devices if device has been created!',
            );
        }
        const availableDevices = vendorDevices.filter(
            ({ id }) => !devices.some(({ deviceId }) => deviceId === id),
        );
        setVendorDevices(availableDevices);
        toast.success('🤖 Device successfully created!');
    };

    const handleDeviceUpdated = (devices: EnergyDevice[]) => {
        setEnergyDevices(devices);
        toast.success('🤖 Device successfully updated!');
    };

    const handleTimeOfUseCreatedOrUpdated = async (timeOfUse: EnergyTimeOfUseType, isCreate:boolean) => {
        setEnergyTimeOfUse(timeOfUse);
        toast.success(`⏰ Time of Use successfully ${isCreate ? 'created' : 'updated'}`);
    }
    const handleTimeOfUseRemoved = async () => {
        setEnergyTimeOfUse(null);
        toast.success('⏰ Time of Use successfully removed');
    }

    const handleEnergyEquipmentShiftingUpdated = async (
        energyShifting: EnergyEquipmentShiftingType[],
        isLoadShiftingOnly = false,
    ) => {
        if (isLoadShiftingOnly) {
            setEnergyEquipmentLoadShifting(energyShifting);
        } else {
            setEnergyEquipmentShifting(energyShifting);
        }
        toast.success('🦄 Successfully updated shifting commands!');
    }

    const handleShiftingConfigUpdated = async (
        energyShiftingConfig: EnergyEquipmentShiftingConfigType[],
        isPreCoolConfig: boolean,
    ) => {
        if (isPreCoolConfig) {
            setEquipmentPreCoolConfigs(energyShiftingConfig);
        } else {
            setEquipmentLoadShiftingConfigs(energyShiftingConfig);
        }
        toast.success('🦄 Successfully updated shifting configurations!');
    }

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        if (
            energyLocation?.utilityProviderId &&
            energyLocation?.utilityProviderId !== 'n/a' &&
            meters.some(({ uid }) => energyLocation?.utilityProviderId === uid)
        ) {
            setSelectedMeter(energyLocation?.utilityProviderId);
        } else {
            setSelectedMeter('');
        }
    }, [meters, energyLocation?.uuid]);

    useEffect(() => {
        if (
            energyLocation?.demandResponseProviderId &&
            energyLocation?.demandResponseProviderId !== 'n/a' &&
            leapMeters.some(({ meter_id }) => energyLocation?.demandResponseProviderId === meter_id)
        ) {
            setSelectedLeapMeter(energyLocation?.demandResponseProviderId);
        } else {
            setSelectedLeapMeter('');
        }
    }, [leapMeters, energyLocation?.uuid]);

    return (
        <Container>
            <LocationCustomerHeaderContainer>
                <ContentWrapper>
                    <HeaderWrapper>
                        <Header as="h5">Select Customer:</Header>
                    </HeaderWrapper>
                    <DropdownWrapper>
                        {customerDropdownValues.length ? (
                            <Dropdown
                                clearable
                                required
                                placeholder="Select Customer"
                                options={customerDropdownValues}
                                name="customer"
                                selection
                                fluid
                                onChange={onCustomerSelected}
                                value={selectedCompany?.uuid || ''}
                                search
                            />
                        ) : (
                            <Loader
                                active
                                size="tiny"
                                style={{
                                    position: 'relative',
                                }}
                            />
                        )}
                    </DropdownWrapper>
                </ContentWrapper>
                <ContentWrapper>
                    <HeaderWrapper>
                        <Header as="h5">Select Location:</Header>
                    </HeaderWrapper>
                    <DropdownWrapper>
                        <Dropdown
                            clearable
                            required
                            placeholder="Select Location"
                            options={locationDropdownValues}
                            name="location"
                            selection
                            fluid
                            disabled={
                                !locationDropdownValues.length ||
                                energyDevicesLoading ||
                                metersLoading ||
                                leapMetersLoading ||
                                energyLocationLoading
                            }
                            onChange={onLocationSelected}
                            value={selectedMonitoringLocation?.uuid || ''}
                            search
                        />
                    </DropdownWrapper>
                </ContentWrapper>
                <Button
                    disabled={!energyLocation}
                    onClick={() => handleUnEnroll()}
                    color="red"
                >
                    Unenroll
                </Button>
            </LocationCustomerHeaderContainer>
            <SystemCommissioningBody
                companyUUID={selectedCompany?.uuid}
                selectedMeter={selectedMeter}
                selectedLeapMeter={selectedLeapMeter}
                meters={meters}
                leapMeters={leapMeters}
                handleMeterChange={handleMeterChange}
                handleLeapMeterChange={handleLeapMeterChange}
                monitoringLocation={selectedMonitoringLocation}
                handleDeleteMeter={handleDeleteMeter}
                handleDeleteLeapMeter={handleDeleteLeapMeter}
                energyLocation={energyLocation}
                energyDevices={energyDevices}
                energyDevicesLoading={energyDevicesLoading}
                energyLocationLoading={energyLocationLoading}
                handleDeviceDeleted={handleDeviceDeleted}
                onEnroll={() =>
                    handleEnroll(selectedMonitoringLocation?.uuid as string)
                }
                equipments={equipments}
                handleMeterUpdate={handleMeterUpdate}
                handleLeapMeterUpdate={handleLeapMeterUpdate}
                deleteDeviceLoading={deleteDeviceLoading}
                metersLoading={metersLoading}
                updateMeterLoading={updateMeterLoading}
                leapMetersLoading={leapMetersLoading}
                updateLeapMeterLoading={updateLeapMeterLoading}
                onDeviceCreated={handleDeviceCreated}
                vendorDevices={vendorDevices}
                vendorDevicesLoading={vendorDevicesLoading}
                timeOfUseLoading={timeOfUseLoading}
                timeOfUse={energyTimeOfUse}
                onTimeOfUseUpdated={handleTimeOfUseCreatedOrUpdated}
                onTimeOfUseRemoved={handleTimeOfUseRemoved}
                companyUuid={selectedMonitoringLocation?.companyUUID}
                locationSchedules={schedules}
                energyEquipmentShiftingLoading={energyEquipmentShiftingLoading}
                energyEquipmentShifting={energyEquipmentShifting}
                energyEquipmentLoadShifting={energyEquipmentLoadShifting}
                onEquipmentShiftingUpdate={handleEnergyEquipmentShiftingUpdated}
                equipmentLoadShiftingConfigs={equipmentLoadShiftingConfigs}
                equipmentPreCoolConfigs={equipmentPreCoolConfigs}
                onEquipmentShiftingConfigUpdate={handleShiftingConfigUpdated}
                onDeviceUpdated={handleDeviceUpdated}
            />
            <WarningModal
                open={modal?.type === 'WARNING_MODAL'}
                text={modal?.text as string}
                onCancel={() => dispatch({ type: 'CLOSE_MODAL' })}
            />
            <DeleteModal
                open={modal?.type === 'DELETE_DEVICE_MODAL'}
                text={modal?.text as string}
                onCancel={() => dispatch({ type: 'CLOSE_MODAL' })}
                onConfirm={() =>
                    handleDeviceDeletedConfirm(modal?.device as EnergyDevice)
                }
            />
            <DeleteModal
                open={modal?.type === 'DELETE_METER_MODAL'}
                text={modal?.text as string}
                onCancel={() => dispatch({ type: 'CLOSE_MODAL' })}
                onConfirm={handleDeleteMeterConfirm}
            />
            <DeleteModal
                open={modal?.type === 'DELETE_LEAP_METER_MODAL'}
                text={modal?.text as string}
                onCancel={() => dispatch({ type: 'CLOSE_MODAL' })}
                onConfirm={handleDeleteLeapMeterConfirm}
            />
            <DeleteModal
                open={modal?.type === 'DELETE_MODAL'}
                text={modal?.text as string}
                onCancel={() => dispatch({ type: 'CLOSE_MODAL' })}
                onConfirm={() =>
                    handleUnEnrollConfirm(
                        selectedMonitoringLocation?.uuid as string,
                    )
                }
            />
        </Container>
    );
};
