import { useContext, useEffect } from 'react';

import { AuthContext } from '../../../../contexts';
import { LocationSchedule, ScheduleService } from '../../../../services/schedule-service';
import { updatedSchedule } from '../occupied-hours-form';

export const useScheduleService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        addSchedule: (schedule: updatedSchedule) =>
            ScheduleService.addSchedule(
                idToken,
                schedule,
            ),
        browseSchedules: (companyUUID: string, locationUUID: string) =>
            ScheduleService.browseSchedules(
                idToken,
                companyUUID,
                locationUUID,
            ),
        deleteSchedule: (scheduleUUID: string) =>
            ScheduleService.deleteSchedule(
                idToken,
                scheduleUUID,
            ),
        updateSchedule: (schedule: updatedSchedule, scheduleUUID: string) =>
            ScheduleService.updateSchedule(
                idToken,
                schedule,
                scheduleUUID,
            ),
    };
};


export default useScheduleService;
