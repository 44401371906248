import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app';
import { init as initApm } from '@elastic/apm-rum'
/* npm prebuild hook runs node.js with the -p flag which gets package.json.version and redirects the output to src/version.ts
This results in src/version.ts containing:
export const LIB_VERSION = "1.0.0"; */
import { LIB_VERSION } from './version';

initApm({
    // A Boolean value that specifies if the agent should be active or not
    active: Boolean(process.env.ELASTIC_APM_ENABLED || false),
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: `thermalink-web-${process.env.THERMALINK_DEPLOYMENT}`,
    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: process.env.ELASTIC_APM_SERVER_URL,
    // Set service version (required for sourcemap feature). Using the version from the package.json file
    serviceVersion: LIB_VERSION,
    // A number between 0.0 and 1.0 that specifies the sample rate of transactions. By default, all transactions are sampled.
    transactionSampleRate: parseFloat(process.env.TRANSACTION_SAMPLE_RATE)
    // Secret tokens are not applicable for the RUM Agent, as there is no way to prevent them from being publicly exposed.
})

ReactDOM.render(<App />, document.getElementById('coinspect'));
