import {
    ButtonContainer,
    FormDropdown,
    FormInput,
    LimitedContainer,
} from '@coinspect/ui';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form, Header } from 'semantic-ui-react';

import { ListContainer } from '../';
import { StoreContext, useDeviceService } from '../../store';
import { Card } from '../card';
import { DeviceList } from '../devices';

interface DeviceForm {
    serialNumber: string;
    deviceType: string;
}

export const DevicePage: FunctionComponent<{}> = () => {
    const { store } = useContext(StoreContext);
    const { devices } = store.entities;
    const { addDevice, browseDevices } = useDeviceService();
    const formMethods = useForm<DeviceForm>({
        mode: 'onChange',
    });

    useEffect(() => {
        (async () => {
            await browseDevices();
        })();
    }, []);

    const onSubmit = formMethods.handleSubmit(async (data) => {
        const { serialNumber, deviceType } = data;
        await addDevice(serialNumber, deviceType);
        formMethods.reset({ serialNumber: '', deviceType: '' });
    });

    enum DeviceType {
        DRAGINO = 'dragino',
        THERMABETA = 'thermabeta',
    }

    const deviceType = [
        { key: 'Dragino', value: DeviceType.DRAGINO, text: 'Dragino' },
        {
            key: 'Therma-Beta',
            value: DeviceType.THERMABETA,
            text: 'Therma-Beta',
        },
    ];

    return (
        <LimitedContainer>
            <Card>
                <FormContext {...formMethods}>
                    <Form onSubmit={onSubmit}>
                        <FormDropdown
                            label="Device:"
                            name="deviceType"
                            options={deviceType}
                            selection
                            fluid
                            required
                            customErrors={{ required: 'Device is required.' }}
                        />

                        <FormInput
                            label="Serial number:"
                            name="serialNumber"
                            type="text"
                            required
                            customErrors={{
                                required: 'Serial number is required.',
                            }}
                        />

                        <ButtonContainer right>
                            <Button
                                primary
                                type="submit"
                                disabled={formMethods.formState.isSubmitting}
                                loading={formMethods.formState.isSubmitting}
                            >
                                Submit
                            </Button>
                        </ButtonContainer>
                    </Form>
                </FormContext>
            </Card>

            <Header as="h1">Devices</Header>
            <ListContainer>
                <DeviceList deviceUuids={devices.all} />
            </ListContainer>
        </LimitedContainer>
    );
};
