import moment from 'moment-timezone';
import { MILESIGHT_DEVICE_TYPES } from './constants';
import { RawEnergyDeviceType } from '../../../../services/energy-device-service';
import { FormDataType } from './types';
export const getTimezones = () =>
  moment.tz
    .names()
    .filter((tzName) => tzName.includes('US') || tzName.includes('Canada'))
    .map((tzName) => ({
      text: `(GMT${moment.tz(tzName).format('Z')}) ${tzName} (${moment
        .tz(tzName)
        .zoneAbbr()})`,
      value: tzName,
    }));

export const getEnergyDeviceTypeData = (
  deviceTypeUUID: string | undefined,
  energyDeviceTypes: RawEnergyDeviceType[] | null,
) => energyDeviceTypes?.find(({ uuid }) => uuid === deviceTypeUUID);

export const getIsMilesightEnergyMeterType = (deviceType: string) => {
  const milesightDeviceTypes = [MILESIGHT_DEVICE_TYPES.CT101, MILESIGHT_DEVICE_TYPES.CT103];
  return milesightDeviceTypes.includes(deviceType);
}
