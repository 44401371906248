import { useContext } from 'react';

import { AuthContext } from '../../../../contexts';
import {
  CreateDeviceParams,
  ECOBEE_VENDOR,
  EnergyDeviceService,
  EnergyDeviceMetadata,
  EquipmentType,
  MEKI_VENDOR,
  MILESIGHT_VENDOR,
  RawEnergyDevice,
  RawEnergyDeviceType,
  SetPointMetadata,
  VendorDeviceType,
} from '../../../../services/energy-device-service';
import {
  HVAC_EQUIPMENT_TYPE,
  METER_EQUIPMENT_TYPE,
  MILESIGHT_THERMOSTAT_VALUES,
} from '../energy-device-form/constants';

export type EnergyDevice = {
  equipmentType: string;
  deviceType: string;
  deviceId: string;
  equipmentName: string;
  uuid: string;
  deviceName: string;
  vendor: string;
  metadata: SetPointMetadata & EnergyDeviceMetadata;
};

const useEnergyDeviceService = () => {
  const { idToken } = useContext(AuthContext);
  return {
    getDevicesByEnergyLocation: async (
      energyLocationUUID: string,
    ): Promise<EnergyDevice[]> => {
      const devices = await EnergyDeviceService.getDevicesByEnergyLocation(
        idToken,
        energyLocationUUID,
      );

      return devices.map(
        ({
          deviceType: { vendor, vendorModel },
          equipment,
          metadata,
          uuid,
          vendorDevice,
          vendorId,
        }) => ({
          deviceId: vendorId,
          deviceName: vendorDevice?.name,
          deviceType: `${capitalizeFirstLetter(vendor)} ${vendorModel}`,
          equipmentName: equipment ? equipment.name : vendorDevice?.name,
          equipmentType: formatEquipmentType(equipment, vendor, vendorModel),
          metadata,
          uuid,
          vendor,
        }),
      );
    },
    delete: async (uuid: string): Promise<RawEnergyDevice | undefined> =>
      EnergyDeviceService.delete(idToken, uuid),
    getDeviceTypes: async (): Promise<RawEnergyDeviceType[]> =>
      EnergyDeviceService.getDeviceTypes(idToken),
    getEquipment: async (
      monitoringLocationUUID: string,
    ): Promise<EquipmentType[]> =>
      EnergyDeviceService.getEquipment(idToken, monitoringLocationUUID),
    getAvailableVendorDevices: async (): Promise<VendorDeviceType[]> =>
      EnergyDeviceService.getAvailableVendorDevices(idToken),
    create: async (
      deviceData: CreateDeviceParams,
    ): Promise<RawEnergyDevice | undefined> =>
      EnergyDeviceService.create(idToken, deviceData),
    updateDeviceSetpoints: async (
      metadata: SetPointMetadata,
      deviceUUID: string,
    ): Promise<SetPointMetadata> =>
      EnergyDeviceService.updateDeviceSetpoints(idToken, metadata, deviceUUID),
  };
};

export const formatEquipmentTypeWithoutVendor = (
  equipmentType: string,
): string => {
  return capitalizeFirstLetter(equipmentType);
};

export const formatEquipmentType = (
  equipment: { equipmentType: string } | undefined,
  vendor: string,
  vendorModel: string,
): string => {
  if (!equipment) {
    return vendor === ECOBEE_VENDOR || vendor === MEKI_VENDOR
      || (vendor === MILESIGHT_VENDOR && vendorModel === MILESIGHT_THERMOSTAT_VALUES.vendorModel)
      ? HVAC_EQUIPMENT_TYPE
      : METER_EQUIPMENT_TYPE;
  }

  return formatEquipmentTypeWithoutVendor(equipment.equipmentType);
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default useEnergyDeviceService;
