import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import { AuthContext } from '../contexts';
import { DeviceConfigurationService } from '../services';
import { StoreContext } from './store-context';

export const DeviceConfigurationSchema = new schema.Entity(
    'deviceConfiguration',
    {},
    {
        idAttribute: 'devEUI',
    },
);

export const DeviceConfigurationListSchema = [DeviceConfigurationSchema];

export function useDeviceConfigurationService() {
    const { dispatch } = useContext(StoreContext);
    const { token } = useContext(AuthContext);

    async function browseDeviceConfiguration(): Promise<void> {
        const deviceConfigurations = await DeviceConfigurationService.browse(
            token,
        );
        const normalized = normalize(
            deviceConfigurations,
            DeviceConfigurationListSchema,
        );
        dispatch({
            data: normalized,
            type: 'deviceConfiguration:set',
        });
    }

    return {
        browseDeviceConfiguration,
    };
}
