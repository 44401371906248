import { useEffect, useState } from 'react';

import {
    EnergyLocation,
    MonitoringLocation,
} from '../../../../services/energy-location-service';
import useEnergyLocationService from './useEnergyLocationService';

export const useEnergyLocation = (
    monitoringLocation: MonitoringLocation | null,
) => {
    const { byMonitoringLocations } = useEnergyLocationService();

    const [energyLocation, setEnergyLocation] = useState<EnergyLocation | null>(
        null,
    );
    const [energyLocationLoading, setEnergyLocationLoading] = useState<boolean>(
        false,
    );

    const getEnergyLocation = async (monitoringLocationUUID: string) => {
        setEnergyLocationLoading(true);
        const energyLocations = await byMonitoringLocations([
            monitoringLocationUUID,
        ]);

        if (!energyLocations.length) {
            setEnergyLocation(null);
            setEnergyLocationLoading(false);
            return;
        }

        if (energyLocations.length > 1) {
            throw new Error('There should only be 1 energy location!');
        }

        const [energyLocation] = energyLocations;
        setEnergyLocation(energyLocation);
        setEnergyLocationLoading(false);
    };

    useEffect(() => {
        if (!monitoringLocation) {
            setEnergyLocation(null);
            return;
        }

        const { uuid } = monitoringLocation;

        getEnergyLocation(uuid);
    }, [monitoringLocation]);

    return {
        energyLocation,
        loading: energyLocationLoading,
        setEnergyLocation,
        getEnergyLocation,
    };
};
