import React from 'react';
import { Input } from 'semantic-ui-react';

import { InputSuffix, InputWrapper } from './styles';

type Props = {
  prefix?: React.ReactNode;
  name?: string;
  value?: string | null;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  suffix?: React.ReactNode;
};

const EnergyDeviceLabelInput = ({
  prefix,
  name,
  value,
  handleChange,
  disabled = false,
  suffix,
}: Props) => {
  return (
    <>
      {prefix}
      <InputWrapper>
        <Input
          type="number"
          fluid
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        >
          <input style={{ minWidth: '80px' }} />
          {suffix && <InputSuffix>{suffix}</InputSuffix>}
        </Input>
      </InputWrapper>
    </>
  );
};

export default EnergyDeviceLabelInput;
