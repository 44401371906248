import React, { FunctionComponent } from 'react';
import { Button, Header, Loader, Responsive } from 'semantic-ui-react';
import { LocationSchedule } from 'services/schedule-service';
import styled from 'styled-components';

import {
    EquipmentType,
    VendorDeviceType,
} from '../../../services/energy-device-service';
import { EnergyEquipmentShiftingType } from '../../../services/energy-equipment-shifting-service';
import {
    EnergyLocation,
    EnergyMeter,
    MonitoringLocation,
} from '../../../services/energy-location-service';
import { EnergyEquipmentShiftingConfigType } from '../../../services/energy-shifting-config-service';
import { EnergyTimeOfUseType } from '../../../services/energy-time-of-use-service';
import { LeapMeter } from '../../../services/energy-utility-service';
import { EnergyDeviceForm } from './energy-device-form/';
import { EnergyLeapMeterForm } from './energy-location-leap-form';
import { EnergyUtilityMeterForm } from './energy-location-utility-form';
import EquipmentTimeOfUseForm from './equipment-time-of-use-form';
import { EnergyDevice } from './hooks/useEnergyDeviceService';
import { OccupiedHoursForm } from './occupied-hours-form';
import { TimeOfUseForm } from './time-of-use-form';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    flex-grow: 1;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex: 2;
`;

const EnrollButtonWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex: 1;
    align-self: center;
`;

const EnrollLocationContainer = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 15px 40px 15px 40px;
    gap: 40px;
    justify-content: space-evenly;
    margin-bottom: 15px;
    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-items: stretch;
        gap: 20px;
    }
`;

const DeviceErrorContainer = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 15px 40px 15px 40px;
    gap: 40px;
    justify-content: space-evenly;
    margin-bottom: 15px;
`;

const DeviceLoaderWrapper = styled.div`
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface SystemCommissioningBodyProps {
    companyUUID?: string;
    monitoringLocation: MonitoringLocation | null;
    energyLocation: EnergyLocation | null;
    energyDevices: EnergyDevice[] | null;
    energyLocationLoading: boolean;
    energyDevicesLoading: boolean;
    onEnroll: () => void;
    handleDeviceDeleted: (device: EnergyDevice) => Promise<void>;
    selectedMeter: string;
    selectedLeapMeter: string;
    handleMeterChange: (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: string },
    ) => void;
    handleLeapMeterChange: (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: string },
    ) => void;
    meters: EnergyMeter[];
    leapMeters: LeapMeter[];
    handleDeleteMeter: () => Promise<void>;
    handleDeleteLeapMeter: () => Promise<void>;
    equipments: EquipmentType[] | null;
    handleMeterUpdate: () => void;
    handleLeapMeterUpdate: () => void;
    deleteDeviceLoading: boolean;
    metersLoading: boolean;
    leapMetersLoading: boolean;
    updateMeterLoading: boolean;
    updateLeapMeterLoading: boolean;
    onDeviceCreated: (devices: EnergyDevice[]) => void;
    vendorDevices: VendorDeviceType[] | null;
    vendorDevicesLoading: boolean;
    timeOfUseLoading: boolean;
    timeOfUse: EnergyTimeOfUseType | null;
    onTimeOfUseUpdated: Function;
    onTimeOfUseRemoved: Function;
    companyUuid: string | undefined;
    locationSchedules: LocationSchedule | undefined;
    energyEquipmentShiftingLoading: boolean;
    energyEquipmentShifting: EnergyEquipmentShiftingType[];
    energyEquipmentLoadShifting: EnergyEquipmentShiftingType[];
    onEquipmentShiftingUpdate: Function;
    equipmentLoadShiftingConfigs: EnergyEquipmentShiftingConfigType[];
    equipmentPreCoolConfigs: EnergyEquipmentShiftingConfigType[];
    onEquipmentShiftingConfigUpdate: Function;
    onDeviceUpdated: Function;
}

export const SystemCommissioningBody: FunctionComponent<SystemCommissioningBodyProps> = ({
    companyUUID,
    monitoringLocation,
    energyLocation,
    energyLocationLoading,
    energyDevices,
    energyDevicesLoading,
    handleDeviceDeleted,
    onEnroll,
    selectedMeter,
    selectedLeapMeter,
    handleMeterChange,
    handleLeapMeterChange,
    handleDeleteMeter,
    handleDeleteLeapMeter,
    meters,
    leapMeters,
    equipments,
    handleMeterUpdate,
    handleLeapMeterUpdate,
    deleteDeviceLoading,
    metersLoading,
    leapMetersLoading,
    updateMeterLoading,
    updateLeapMeterLoading,
    onDeviceCreated,
    vendorDevices,
    vendorDevicesLoading,
    timeOfUseLoading,
    timeOfUse,
    onTimeOfUseUpdated,
    onTimeOfUseRemoved,
    companyUuid,
    locationSchedules,
    energyEquipmentShiftingLoading,
    energyEquipmentShifting,
    energyEquipmentLoadShifting,
    onEquipmentShiftingUpdate,
    equipmentLoadShiftingConfigs,
    equipmentPreCoolConfigs,
    onEquipmentShiftingConfigUpdate,
    onDeviceUpdated,
}: SystemCommissioningBodyProps) => {
    if (energyLocationLoading) {
        return <Loader active size="large" />;
    }

    // if no location, do not render anything
    if (monitoringLocation === null) {
        return <></>;
    }

    // if location but no energy location, return enroll button
    if (energyLocation === null) {
        return (
            <EnrollLocationContainer>
                <LabelWrapper>
                    <Header size="tiny">
                        {
                            'This Location is not currently enrolled in Energy. Press the button to enroll it.'
                        }
                    </Header>
                </LabelWrapper>
                <EnrollButtonWrapper>
                    <Button onClick={onEnroll} fluid color="green">
                        Enroll Location
                    </Button>
                </EnrollButtonWrapper>
            </EnrollLocationContainer>
        );
    }

    return (
        <>
            <EnergyUtilityMeterForm
                energyLocation={energyLocation}
                selectedMeter={selectedMeter}
                handleMeterChange={handleMeterChange}
                meters={meters}
                handleDeleteMeter={handleDeleteMeter}
                handleMeterUpdate={handleMeterUpdate}
                metersLoading={metersLoading}
                updateMeterLoading={updateMeterLoading}
            />
            <EnergyLeapMeterForm
                energyLocation={energyLocation}
                selectedMeter={selectedLeapMeter}
                handleMeterChange={handleLeapMeterChange}
                meters={leapMeters}
                handleDeleteMeter={handleDeleteLeapMeter}
                handleMeterUpdate={handleLeapMeterUpdate}
                metersLoading={leapMetersLoading}
                updateMeterLoading={updateLeapMeterLoading}
            />
            {energyDevicesLoading || vendorDevicesLoading ? (
                <DeviceLoaderWrapper>
                    <Loader active size="large" inline />
                </DeviceLoaderWrapper>
            ) : !energyDevices || !vendorDevices ? (
                <DeviceErrorContainer>
                    <Header size="tiny" color="red">
                        Could not fetch devices! Please try again later!
                    </Header>
                </DeviceErrorContainer>
            ) : (
                <EnergyDeviceForm
                    companyUUID={companyUUID}
                    equipments={equipments}
                    energyDevices={energyDevices}
                    energyDevicesLoading={energyDevicesLoading}
                    handleDeviceDeleted={handleDeviceDeleted}
                    deleteDeviceLoading={deleteDeviceLoading}
                    energyLocation={energyLocation}
                    onDeviceCreated={onDeviceCreated}
                    vendorDevices={vendorDevices}
                    vendorDevicesLoading={vendorDevicesLoading}
                    energyEquipmentShiftingLoading={
                        energyEquipmentShiftingLoading
                    }
                    energyEquipmentShifting={energyEquipmentShifting}
                    onEquipmentShiftingUpdate={onEquipmentShiftingUpdate}
                    onDeviceUpdated={onDeviceUpdated}
                />
            )}
            {timeOfUseLoading ? (
                <DeviceLoaderWrapper>
                    <Loader active size="large" inline />
                </DeviceLoaderWrapper>
            ) : (
                <>
                    <TimeOfUseForm
                        energyLocationUuid={energyLocation.uuid}
                        timeOfUse={timeOfUse}
                        onTimeOfUseUpdated={onTimeOfUseUpdated}
                        onTimeOfUseRemoved={onTimeOfUseRemoved}
                    />
                    {timeOfUse?.uuid && (
                        <EquipmentTimeOfUseForm
                            companyUUID={companyUUID || ''}
                            energyDevices={energyDevices}
                            energyEquipmentLoadShifting={
                                energyEquipmentLoadShifting
                            }
                            energyEquipmentShifting={energyEquipmentShifting}
                            onEquipmentShiftingUpdate={
                                onEquipmentShiftingUpdate
                            }
                            equipmentLoadShiftingConfigs={
                                equipmentLoadShiftingConfigs
                            }
                            equipmentPreCoolConfigs={equipmentPreCoolConfigs}
                            onEquipmentShiftingConfigUpdate={
                                onEquipmentShiftingConfigUpdate
                            }
                        />
                    )}
                </>
            )}
            {timeOfUseLoading ? (
                <DeviceLoaderWrapper>
                    <Loader active size="large" inline />
                </DeviceLoaderWrapper>
            ) : (
                <OccupiedHoursForm
                    monitoringLocationUuid={
                        energyLocation.monitoringLocationUuid
                    }
                    locationSchedules={locationSchedules}
                    companyUuid={companyUuid}
                />
            )}
        </>
    );
};
