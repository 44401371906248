import React, { ChangeEvent, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { EquipmentType } from '../../../../../services/energy-device-service';
import {
  EnergyDevice,
  formatEquipmentTypeWithoutVendor,
} from '../../hooks/useEnergyDeviceService';
import {
  COOLER_EQUIPMENT_TYPE,
  FREEZER_EQUIPMENT_TYPE,
  HVAC_EQUIPMENT_TYPE,
  METER_EQUIPMENT_TYPE,
  OTHER_EQUIPMENT_TYPE,
  REFRIGERATOR_EQUIPMENT_TYPE,
  MILESIGHT_DEVICE_TYPES,
} from '../constants';
import EnergyDeviceCheckbox from './energy-device-checkbox';
import EnergyDeviceFormRow from './energy-device-form-row';
import EnergyDeviceInput from './energy-device-input';
import { FormDataType } from '../types';

type EventType = React.SyntheticEvent<HTMLElement, Event>;

type OptionType = {
  key: string;
  text: string;
  value: string;
};

type SinglePhaseMeterFieldsProps = {
  equipments: EquipmentType[] | null;
  energyDevices: EnergyDevice[];
  formData: FormDataType;
  handleFormData: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCustomField: <T>(name: string, value: T) => void;
};

const optionMapping = (type: string) => ({
  key: type,
  text: type,
  value: type,
});

const SINGLE_PHASE_METER_EQUIPMENT_OPTIONS = [
  {key: COOLER_EQUIPMENT_TYPE, text: COOLER_EQUIPMENT_TYPE, value: REFRIGERATOR_EQUIPMENT_TYPE},
  {key: FREEZER_EQUIPMENT_TYPE, text: FREEZER_EQUIPMENT_TYPE, value: FREEZER_EQUIPMENT_TYPE},
  {key: HVAC_EQUIPMENT_TYPE, text: HVAC_EQUIPMENT_TYPE, value: HVAC_EQUIPMENT_TYPE},
  {key: OTHER_EQUIPMENT_TYPE, text: OTHER_EQUIPMENT_TYPE, value: OTHER_EQUIPMENT_TYPE},
];

const SINGLE_PHASE_METER_VOLTAGE_OPTIONS = [
  { key: '120/208', text: '120/208', value: '208' },
  { key: '120/240', text: '120/240', value: '240' },
  { key: '277/480', text: '277/480', value: '480' },
];

const SINGLE_PHASE_METER_MULTIPLIER_OPTIONS = ['1', '2', '3'].map(optionMapping);

const SinglePhaseMeterFields: React.FC<SinglePhaseMeterFieldsProps> = ({
  energyDevices,
  equipments,
  formData,
  handleFormData,
  handleCustomField,
}) => {
  const [equipmentOptionsForType, setEquipmentOptionsForType] = useState<
    OptionType[]
  >([]);

  const combinedDevicesAndEquipment = energyDevices
    .filter((device) => device.deviceType!== MILESIGHT_DEVICE_TYPES.CT101 && device.deviceType !== MILESIGHT_DEVICE_TYPES.CT103)
    .map((device) => ({
      type: device.equipmentType,
      name: device.metadata.name || device.deviceName || device.deviceId,
      uuid: device.uuid,
    }))
    .concat(
      equipments?.map((equipment) => ({
        type: formatEquipmentTypeWithoutVendor(equipment.equipmentType),
        name: equipment.name,
        uuid: equipment.uuid,
      })) || [],
    );
  const optionsForEachEquipmentType: {
    [key: string]: OptionType[];
  } = combinedDevicesAndEquipment.reduce((acc, device) => {
    const typeToUse =
      device.type === METER_EQUIPMENT_TYPE ? HVAC_EQUIPMENT_TYPE : device.type;
    if (!acc[typeToUse]) {
      acc[typeToUse] = [];
    }
    acc[typeToUse].push({
      key: device.uuid,
      text: device.name,
      value: device.uuid,
    });
    return acc;
  }, {} as { [key: string]: OptionType[] });

  const filterEquipmentNameOptions = (equipmentType?: string) => {
    if (!equipmentType) {
      return [];
    }
    return optionsForEachEquipmentType[equipmentType] || [];
  };

  useEffect(() => {
    const filteredEquipmentNameOptions = filterEquipmentNameOptions(
      formData.meterEquipmentType,
    );
    setEquipmentOptionsForType(filteredEquipmentNameOptions);
  }, [formData.meterEquipmentType]);

  const handleEquipmentNameDropdownChange = (
    nameText: string = '',
    selectedEquipmentUUID?: string,
  ) => {
    handleCustomField('equipmentName', nameText);
    handleCustomField('selectedEquipmentUUID', selectedEquipmentUUID);
  };

  return (
    <>
      <EnergyDeviceFormRow label={'Equipment Type'}>
        <Dropdown
          clearable
          required
          placeholder="Select Type"
          options={SINGLE_PHASE_METER_EQUIPMENT_OPTIONS}
          name="meterEquipmentType"
          selection
          fluid
          value={formData.meterEquipmentType || ''}
          onChange={(_: EventType, { value }: { value: string }) => {
            handleCustomField('meterEquipmentType', value);
            handleCustomField('newEquipment', false);
            handleEquipmentNameDropdownChange();
          }}
        />

        <EnergyDeviceCheckbox
          prefix="New?"
          checked={!!formData.newEquipment}
          onChange={(_: EventType, { checked }: { checked: boolean }) => {
            handleCustomField('newEquipment', checked);

            const switchToOtherEquipmentType =
              checked && formData.meterEquipmentType === HVAC_EQUIPMENT_TYPE;
            if (switchToOtherEquipmentType) {
              handleCustomField('meterEquipmentType', OTHER_EQUIPMENT_TYPE);
              handleEquipmentNameDropdownChange('HVAC-');
            } else if (checked) {
              handleEquipmentNameDropdownChange();
            }
          }}
        />
      </EnergyDeviceFormRow>
      <EnergyDeviceFormRow label="Equipment Name">
        {formData.newEquipment ? (
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <EnergyDeviceInput
              title=""
              name="equipmentName"
              value={formData.equipmentName}
              handleChange={handleFormData}
            />
          </div>
        ) : (
          <Dropdown
            clearable
            required
            placeholder="Select Equipment Name"
            options={equipmentOptionsForType || []}
            name="equipmentName"
            disabled={!equipmentOptionsForType?.length}
            selection
            fluid
            value={formData.selectedEquipmentUUID}
            onChange={(_e: EventType, { value }: { value: string }) => {
              const selectedOption = equipmentOptionsForType.find(
                (option) => option.value === value,
              );
              const selectedText = selectedOption ? selectedOption.text : '';
              handleEquipmentNameDropdownChange(selectedText, value);
            }}
          />
        )}
      </EnergyDeviceFormRow>
      <EnergyDeviceFormRow label={'Reference Voltage'}>
        <Dropdown
          clearable
          required
          placeholder="Select Voltage"
          options={SINGLE_PHASE_METER_VOLTAGE_OPTIONS}
          name="voltage"
          selection
          fluid
          value={formData.voltage}
          onChange={(_e: EventType, { value }: { value: string }) =>
            handleCustomField('voltage', value)
          }
        />
      </EnergyDeviceFormRow>
      <EnergyDeviceFormRow label={'Current Multiplier'}>
        <Dropdown
          clearable
          required
          placeholder="Select Multiplier"
          options={SINGLE_PHASE_METER_MULTIPLIER_OPTIONS}
          name="multiplier"
          selection
          fluid
          value={formData.multiplier}
          onChange={(_e: EventType, { value }: { value: string }) =>
            handleCustomField('multiplier', value)
          }
        />
      </EnergyDeviceFormRow>
    </>
  );
};

export default SinglePhaseMeterFields;
