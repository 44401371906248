import { ButtonContainer, FormInput } from '@coinspect/ui';
import React, { FunctionComponent, useContext } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';

import { StoreContext, useGatewayAssetsService } from '../../store';

interface GatewayAssetForm {
    name: string;
    vendor: string;
    gatewayid: string;
    sim: string;
    uuid: string;
    model: string;
    gatewayId: string;
    updateStatus: 'updated' | 'updating' | 'updatable';
    id: number;
    createdAt: Date;
    updatedAt: Date;
}

export const AddGatewayAssetForm: FunctionComponent<GatewayAssetForm> = (
    props,
) => {
    const { gatewayid } = props;

    const {
        checkIfUniqueGatewayName,
        checkIfUniqueGatewayId,
        addGateway,
    } = useGatewayAssetsService();

    const formMethods = useForm<GatewayAssetForm>({
        mode: 'onChange',
    });

    const { dispatch } = useContext(StoreContext);

    const onSubmit = formMethods.handleSubmit(async (data) => {
        const gatewayAssetModel = data as GatewayAssetForm;
        const { name } = data;

        const uniqueGatewayId = await checkIfUniqueGatewayId(gatewayid);
        if (!uniqueGatewayId) {
            formMethods.setError('gatewayid', 'noDuplicateID');
        }
        const uniqueName = await checkIfUniqueGatewayName(name);
        if (!uniqueName) {
            formMethods.setError('name', 'noDuplicateName');
        }
        try {
            await addGateway(gatewayAssetModel);
            formMethods.reset();
        } catch (err) {
            dispatch({
                type: 'OPEN_MODAL',
                dimmer: 'inverted',
                title: 'Error',
                message: `Something went wrong while adding gateway: ${err.data.message}`,
                error: true,
            });
        }
    });

    return (
        <FormContext {...formMethods}>
            <Form onSubmit={onSubmit}>
                <FormInput
                    label="Gateway ID:"
                    name="gatewayId"
                    type="text"
                    required
                    customErrors={{
                        required: 'Gateway ID is required',
                        duplicateID: 'Gateway ID already exists.',
                    }}
                />

                <FormInput
                    label="Name"
                    name="name"
                    type="text"
                    required
                    customErrors={{
                        required: 'Gateway Name is required',
                        noDuplicateName: 'Gateway Name already exists.',
                    }}
                />

                <FormInput
                    label="Vendor"
                    name="vendor"
                    type="text"
                    required
                    customErrors={{ required: 'Vendor is required' }}
                />

                <FormInput
                    label="Model"
                    name="model"
                    type="text"
                    required
                    customErrors={{ required: 'Model is required.' }}
                />

                <ButtonContainer right>
                    <Button
                        primary
                        type="submit"
                        disabled={formMethods.formState.isSubmitting}
                        loading={formMethods.formState.isSubmitting}
                    >
                        Submit
                    </Button>
                </ButtonContainer>
            </Form>
        </FormContext>
    );
};
