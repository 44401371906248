import { TotsEquipmentType } from '../../../data/real-tots-devices';

const ValidationService = {
    validDateTime: (
        value: Date | [Date, Date] | null,
    ): { error: boolean; text: string } => {
        const text = 'Date must be in the future';
        if (value && !Array.isArray(value)) {
            const selectedDate = new Date(value).getTime();
            if (selectedDate < new Date().getTime()) {
                return {
                    error: true,
                    text: text,
                };
            }
        }
        return {
            error: false,
            text: '',
        };
    },
    isBetween: (
        equipmentType: TotsEquipmentType | null,
        valueStr: string,
    ): { error: boolean; text: string } => {
        let minimum = -50;
        let maximum = 50;

        if (equipmentType === TotsEquipmentType.refrigerator) {
            minimum = 35;
            maximum = 43;
        }
        if (equipmentType === TotsEquipmentType.freezer) {
            minimum = -5;
            maximum = 5;
        }

        const text = `Setpoint must be between ${minimum} and ${maximum}`;
        const value = Number(valueStr);
        if (value < minimum || value > maximum) {
            return {
                error: true,
                text: text,
            };
        }
        return {
            error: false,
            text: '',
        };
    },
    isGreaterThanZero: (valueStr: string): { error: boolean; text: string } => {
        const text = 'Duration must be greater than zero.';
        const value = Number(valueStr);
        if (isNaN(value) || value < 0) {
            return {
                error: true,
                text: text,
            };
        }
        return {
            error: false,
            text: '',
        };
    },
};

export const initValidationEngine = ({
    equipmentType = TotsEquipmentType.freezer,
} = {}) => ({
    device: {},
    coolTime: {
        error: false,
        text: '',
        rules: {
            validDateTime: ValidationService.validDateTime,
        },
    },
    coolSetpoint: {
        error: false,
        text: '',
        rules: {
            isBetween: (valueStr: string) =>
                ValidationService.isBetween(null, valueStr),
        },
    },
    coolDuration: {
        error: false,
        text: '',
        rules: {
            isGreaterThanZero: ValidationService.isGreaterThanZero,
        },
    },
    ecoTime: {
        error: false,
        text: '',
        rules: {
            validDateTime: ValidationService.validDateTime,
        },
    },
    ecoSetpoint: {
        error: false,
        text: '',
        rules: {
            isBetween: (valueStr: string) =>
                ValidationService.isBetween(null, valueStr),
        },
    },
    ecoDuration: {
        error: false,
        text: '',
        rules: {
            isGreaterThanZero: ValidationService.isGreaterThanZero,
        },
    },
    normalSetpoint: {
        error: false,
        text: '',
        rules: {
            isBetween: (
                equipmentType: TotsEquipmentType | null,
                valueStr: string,
            ) => {
                return ValidationService.isBetween(equipmentType, valueStr);
            },
        },
    },
});
