import { normalize } from 'normalizr';
import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Button } from 'semantic-ui-react';
import Socket from 'simple-websocket';

import {
    LimitedContainer,
    ListContainer,
    ListItem,
    RightPaddedDiv,
    StyledHeader,
} from '../';
import { DeviceConfigurationModel, IotService } from '../../services';
import {
    DeviceConfigurationSchema,
    StoreContext,
    useDeviceConfigurationService,
} from '../../store';

export const DeploymentsPage: FunctionComponent<{}> = () => {
    const { store, dispatch } = useContext(StoreContext);
    const [hasBrowsed, setHasBrowsed] = useState<boolean>(false);

    const { deviceConfigurations } = store.entities;
    const { browseDeviceConfiguration } = useDeviceConfigurationService();

    const clickedDeploy = async (devEui: string) => {
        console.log('not yet implemented');
        return;
    };

    useEffect(() => {
        (async () => {
            await browseDeviceConfiguration();
            setHasBrowsed(true);
        })();
    }, []);

    useEffect(() => {
        const iotService = new IotService();
        let socket: Socket;
        (async () => {
            await browseDeviceConfiguration();
            socket = await iotService.subscribeToSensorMessages((rawData) => {
                const data = JSON.parse(rawData.toString());
                const foundDevice = deviceConfigurations.byUUID[data.devEUI];

                if (!foundDevice) {
                    return;
                }
                const { devEUI, serialNumber, ...restData } = data;

                const normalized = normalize(
                    { ...foundDevice, ...restData },
                    DeviceConfigurationSchema,
                );

                dispatch({
                    data: normalized,
                    type: 'deviceConfiguration:edit',
                });
            });
        })();
        return () => {
            socket?.destroy();
        };
    }, [hasBrowsed]);

    return (
        <LimitedContainer>
            <StyledHeader as="h1">Devices for configuration</StyledHeader>
            <ListContainer>
                <ListItem
                    key={1}
                    bottom={0 !== deviceConfigurations.length - 1}
                >
                    <RightPaddedDiv className="bold flex-1">
                        devEUI
                    </RightPaddedDiv>
                    <RightPaddedDiv className="bold flex-1">
                        serialNumber
                    </RightPaddedDiv>
                    <RightPaddedDiv className="bold flex-1">
                        temperature
                    </RightPaddedDiv>
                    <RightPaddedDiv className="bold flex-1">
                        humidity
                    </RightPaddedDiv>
                    <RightPaddedDiv className="bold flex-1">
                        batteryLevel
                    </RightPaddedDiv>
                    <RightPaddedDiv className="bold flex-1">
                        lastSeen
                    </RightPaddedDiv>
                </ListItem>
                {deviceConfigurations.all.length > 0 &&
                    deviceConfigurations.all.map(
                        (deviceConfigurationUuid: string, idx: number) => {
                            const {
                                devEUI,
                                serialNumber,
                                temperature,
                                humidity,
                                batteryLevel,
                                lastSeen,
                            } = deviceConfigurations.byUUID[
                                deviceConfigurationUuid
                            ] as DeviceConfigurationModel;
                            return (
                                <ListItem
                                    key={devEUI}
                                    bottom={
                                        idx !== deviceConfigurations.length - 1
                                    }
                                >
                                    <RightPaddedDiv className="flex-1">
                                        {devEUI}
                                    </RightPaddedDiv>
                                    <RightPaddedDiv className="bold flex-1">
                                        {serialNumber}
                                    </RightPaddedDiv>
                                    <RightPaddedDiv className="flex-1">
                                        {temperature}
                                    </RightPaddedDiv>
                                    <RightPaddedDiv className="flex-1">
                                        {humidity}
                                    </RightPaddedDiv>
                                    <RightPaddedDiv className="flex-1">
                                        {batteryLevel}
                                    </RightPaddedDiv>
                                    <RightPaddedDiv className="flex-1">
                                        {lastSeen}
                                    </RightPaddedDiv>

                                    <Button
                                        circular
                                        icon
                                        onClick={() => clickedDeploy(devEUI)}
                                    >
                                        Deploy
                                    </Button>
                                </ListItem>
                            );
                        },
                    )}
            </ListContainer>
        </LimitedContainer>
    );
};
