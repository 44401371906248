import { useContext, useEffect, useState } from 'react';

import { EnergyEquipmentShiftingType } from '../../../../services/energy-equipment-shifting-service';
import { AuthContext } from '../../../../contexts';
import { EnergyDevice } from './useEnergyDeviceService';
import { EnergyEquipmentShiftingConfigsService } from '../../../../services/energy-shifting-config-service';

const NUMBER_OF_RETRIES = 5;
const ONE_SECOND = 1000;

export const useEnergyEquipmentShiftingConfigs = (energyDevices: EnergyDevice[] | null) => {
    const { idToken } = useContext(AuthContext);
    const [
        equipmentLoadShiftingConfigs,
        setEquipmentLoadShiftingConfigs,
    ] = useState<EnergyEquipmentShiftingType[] | []>(
        [],
    );
    const [
        equipmentPreCoolConfigs,
        setEquipmentPreCoolConfigs,
    ] = useState<EnergyEquipmentShiftingType[] | []>(
        [],
    );

    const [energyEquipShiftConfigLoading, setEnergyEquipShiftConfigLoading] = useState<boolean>(
        false,
    );

    const getEnergyEquipmentShifting = async (energyDeviceIds: string[]) => {
        setEnergyEquipShiftConfigLoading(true);
        let loadShiftingConfigs = null;
        let preCoolingConfigs = null;
        for (let i = 0; i < NUMBER_OF_RETRIES; i++) {
            const backoff = ONE_SECOND * 2 ** i;

            try {
                loadShiftingConfigs = await EnergyEquipmentShiftingConfigsService
                    .browseEquipmentShiftingConfigs(
                        energyDeviceIds,
                        idToken,
                        'load_shifting',
                    );
                preCoolingConfigs = await EnergyEquipmentShiftingConfigsService
                    .browseEquipmentShiftingConfigs(
                        energyDeviceIds,
                        idToken,
                        'time_of_use_pre_cooling',
                    );
            } catch (error) {
                console.log(
                    `Error fetching shifting configs, retrying in ${backoff /
                        ONE_SECOND} seconds`,
                );
            }

            if (loadShiftingConfigs && preCoolingConfigs) {
                break;
            }

            await new Promise((res) => setTimeout(res, backoff));
        }

        setEquipmentLoadShiftingConfigs(loadShiftingConfigs || []);
        setEquipmentPreCoolConfigs(preCoolingConfigs || []);
        setEnergyEquipShiftConfigLoading(false);
    };

    useEffect(() => {
        if (!energyDevices) {
            return;
        }

        const energyDeviceIds = energyDevices.map(({uuid}: {uuid: string}) => uuid);
        getEnergyEquipmentShifting(energyDeviceIds);
        
    }, [energyDevices]);

    return {
        equipmentLoadShiftingConfigs,
        equipmentPreCoolConfigs,
        loading: energyEquipShiftConfigLoading,
        setEquipmentLoadShiftingConfigs,
        setEquipmentPreCoolConfigs,
    };
};
