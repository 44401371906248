import { useContext } from 'react';

import { AuthContext } from '../../../../contexts';
import { EnergyEquipmentShiftingConfigsService, EnergyShiftingType } from '../../../../services/energy-shifting-config-service';

const useEnergyEquipmentShiftingConfigService = () => {
    const { idToken } = useContext(AuthContext);
    return {
        addEquipmentShiftingConfig: ({
            companyUUID,
            duration,
            entityUUID,
            temperatureDelta,
            type,
        }: {
            companyUUID: string;
            duration: number;
            entityUUID: string;
            temperatureDelta: number;
            type: EnergyShiftingType;
        }) =>
            EnergyEquipmentShiftingConfigsService.createEquipmentShiftingConfig({
                companyUUID,
                duration,
                energyDeviceId: entityUUID,
                temperatureDelta,
                token: idToken,
                type,
            }),
        removeEquipmentShiftingConfig: (uuid: string) =>
            EnergyEquipmentShiftingConfigsService.removeEquipmentShiftingConfig(uuid, idToken),
        updateEquipmentShiftingConfig: ({
            companyUUID,
            duration,
            entityUUID,
            temperatureDelta,
            type,
            uuid,
        }: {
            companyUUID: string;
            duration: number;
            entityUUID: string;
            temperatureDelta: number;
            type: EnergyShiftingType;
            uuid: string;
        }) =>
            EnergyEquipmentShiftingConfigsService.updateEquipmentShiftingConfig({
                companyUUID,
                configUUID: uuid,
                duration,
                energyDeviceId: entityUUID,
                temperatureDelta,
                token: idToken,
                type,
            }),
    };
};

export default useEnergyEquipmentShiftingConfigService;
