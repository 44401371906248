import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Header, Responsive } from 'semantic-ui-react';
import styled from 'styled-components';

import { EnergyTimeOfUseType } from '../../../services/energy-time-of-use-service';
import useEnergyTimeOfUseService from './hooks/useEnergyTimeOfUseService';
import { ContentWrapper, DropdownWrapper } from './index';
import {
    FieldLabelWrapper,
    FieldWrapper,
    InputContainerBody,
    ListLabelWrapper,
    ListNumberWrapper,
    ListTextWrapper,
} from './styles';

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: baseline;
    gap: 20px;

    @media only screen and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
        flex-direction: column;
        align-self: stretch;
    }
`;

const TOUFormContainer = styled.div`
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(155, 155, 155, 0.3);
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px 40px 15px 40px;
    gap: 40px;
    justify-content: space-evenly;
    margin-bottom: 15px;
`;

const ErrorMessage = styled.div`
    color: red;
`;

export const TimeOfUseForm = ({
    energyLocationUuid,
    timeOfUse,
    onTimeOfUseUpdated,
    onTimeOfUseRemoved,
}: {
    energyLocationUuid: string;
    timeOfUse: EnergyTimeOfUseType | null;
    onTimeOfUseUpdated: Function;
    onTimeOfUseRemoved: Function;
}) => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const {
        createEnergyTimeOfUse,
        updateEnergyTimeOfUse,
        removeEnergyTimeOfUse,
    } = useEnergyTimeOfUseService();
    const timezones = useMemo(
        () =>
            moment.tz.names().map((tzName) => ({
                text: `(GMT${moment
                    .tz(tzName)
                    .format('Z')}) ${tzName} (${moment.tz(tzName).zoneAbbr()})`,
                value: tzName,
            })),
        [],
    );

    const timeArrayOptions = () => {
        const format24hrTime = (num: number): string =>
            `${num < 10 ? '0' : ''}${num}:00:00`;
        const createHalfArray = (suffix: string) => {
            const offset = suffix === 'AM' ? 0 : 12;
            const arr = [
                {
                    text: '12:00 ' + suffix,
                    value: format24hrTime(0 + offset),
                },
            ];
            for (let i = 1; i < 12; i++) {
                arr.push({
                    text: `${i}:00 ${suffix}`,
                    value: format24hrTime(i + offset),
                });
            }
            return arr;
        };
        return [...createHalfArray('AM'), ...createHalfArray('PM')];
    };

    const onEnableTOU = async () => {
        const formattedTOU = {
            startTimeTariff: startTime,
            endTimeTariff: endTime,
            timezoneLocation: timeZone,
            energyLocationUuid,
        };
        if (timeOfUse) {
            // update
            const updateTOUPayload = {
                ...formattedTOU,
                uuid: timeOfUse.uuid,
            };
            await updateEnergyTimeOfUse(updateTOUPayload);
            onTimeOfUseUpdated(updateTOUPayload, false);
        } else {
            // create
            const { uuid } = await createEnergyTimeOfUse(formattedTOU);
            onTimeOfUseUpdated({ ...formattedTOU, uuid }, true);
        }
    };
    const onRemoveTOU = async () => {
        if (timeOfUse && timeOfUse.uuid) {
            await removeEnergyTimeOfUse(timeOfUse.uuid);
            onTimeOfUseRemoved();
        }
    };

    const handleOnChangeWith = (handlerFn) => {
        return (e, data) => {
            if (data.value) {
                handlerFn(data.value);
            }
        };
    };

    useEffect(() => {
        if (timeOfUse) {
            setStartTime(timeOfUse.startTimeTariff);
            setEndTime(timeOfUse.endTimeTariff);
            setTimeZone(timeOfUse.timezoneLocation);
        } else {
            setStartTime('');
            setEndTime('');
            setTimeZone('');
        }
    }, [timeOfUse]);

    const disableEnableButton = () => {
        return (
            !(startTime && endTime && timeZone && startTime < endTime) ||
            (startTime === timeOfUse?.startTimeTariff &&
                endTime === timeOfUse?.endTimeTariff &&
                timeZone === timeOfUse?.timezoneLocation)
        );
    };
    return (
        <>
            <TOUFormContainer>
                <ContentWrapper>
                    <ListLabelWrapper>
                        <ListNumberWrapper>
                            <Header size="tiny">{'4.'}</Header>
                        </ListNumberWrapper>
                        <ListTextWrapper>
                            <Header size="tiny">
                                Time-of-Use Load shifting
                            </Header>
                        </ListTextWrapper>
                    </ListLabelWrapper>
                    <InputContainer>
                        <InputContainerBody>
                            <FieldWrapper>
                                <FieldLabelWrapper>
                                    <Header as="h5">Start time:</Header>
                                </FieldLabelWrapper>
                                <DropdownWrapper>
                                    <Dropdown
                                        options={timeArrayOptions().map(
                                            ({ text, value }) => ({
                                                key: value,
                                                value,
                                                text,
                                            }),
                                        )}
                                        search
                                        clearable
                                        required
                                        placeholder="Select Time"
                                        name="start time"
                                        selection
                                        fluid
                                        value={startTime}
                                        onChange={handleOnChangeWith(
                                            setStartTime,
                                        )}
                                        error={
                                            !!startTime &&
                                            !!endTime &&
                                            !(startTime < endTime)
                                        }
                                    />
                                </DropdownWrapper>
                            </FieldWrapper>
                            <FieldWrapper>
                                <FieldLabelWrapper>
                                    <Header as="h5">End time:</Header>
                                </FieldLabelWrapper>
                                <DropdownWrapper>
                                    <Dropdown
                                        options={timeArrayOptions().map(
                                            ({ text, value }) => ({
                                                key: value,
                                                value,
                                                text,
                                            }),
                                        )}
                                        search
                                        clearable
                                        required
                                        placeholder="Select Time"
                                        name="end time"
                                        selection
                                        fluid
                                        value={endTime}
                                        onChange={handleOnChangeWith(
                                            setEndTime,
                                        )}
                                        disabled={!startTime}
                                        error={
                                            !!startTime &&
                                            !!endTime &&
                                            !(startTime < endTime)
                                        }
                                    />
                                </DropdownWrapper>
                            </FieldWrapper>
                            {startTime && endTime && !(startTime < endTime) && (
                                <ErrorMessage>
                                    End time must be later than start time
                                </ErrorMessage>
                            )}
                            <FieldWrapper>
                                <FieldLabelWrapper>
                                    <Header as="h5">Time zone:</Header>
                                </FieldLabelWrapper>
                                <DropdownWrapper>
                                    <Dropdown
                                        options={timezones.map(
                                            ({ text, value }) => ({
                                                key: value,
                                                value,
                                                text,
                                            }),
                                        )}
                                        search
                                        clearable
                                        required
                                        placeholder="Select Time Zone"
                                        name="time zone"
                                        selection
                                        fluid
                                        value={timeZone}
                                        onChange={handleOnChangeWith(
                                            setTimeZone,
                                        )}
                                        disabled={
                                            !(
                                                startTime &&
                                                endTime &&
                                                startTime < endTime
                                            )
                                        }
                                    />
                                </DropdownWrapper>
                            </FieldWrapper>
                        </InputContainerBody>
                        <div style={{ display: 'flex', gap: '35px' }}>
                            <Button
                                onClick={onEnableTOU}
                                fluid
                                color="green"
                                disabled={disableEnableButton()}
                            >
                                Enable Time-of-Use
                            </Button>
                            <Button
                                onClick={onRemoveTOU}
                                fluid
                                color="red"
                                disabled={
                                    !timeOfUse ||
                                    !Object.values(timeOfUse).length
                                }
                            >
                                Remove Time-of-Use
                            </Button>
                        </div>
                    </InputContainer>
                </ContentWrapper>
            </TOUFormContainer>
        </>
    );
};
