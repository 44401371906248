import { useContext, useEffect, useState } from 'react';

import { EnergyLocation } from '../../../../services/energy-location-service';
import { EnergyTimeOfUseService, EnergyTimeOfUseType } from '../../../../services/energy-time-of-use-service';
import { AuthContext } from '../../../../contexts';

const NUMBER_OF_RETRIES = 5;

export const useEnergyTimeOfUse = (energyLocation: EnergyLocation | null) => {
    const { idToken } = useContext(AuthContext);
    const [energyTimeOfUse, setEnergyTimeOfUse] = useState<EnergyTimeOfUseType | null>(
        null,
    );
    const [timeOfUseLoading, setTimeOfUseLoading] = useState<boolean>(
        false,
    );

    const getEnergyTimeOfUse = async (energyLocationUUID: string) => {
        setTimeOfUseLoading(true);
        let timeOfUse = null;

        for (let i = 0; i < NUMBER_OF_RETRIES; i++) {
            const backoff = 1000 * 2 ** i;

            try {
                const results = await EnergyTimeOfUseService.browseTimeOfUse(energyLocationUUID, idToken);
                timeOfUse = results.data;
            } catch (error) {
                console.log(
                    `Error fetching time of use, retrying in ${backoff /
                        1000} seconds`,
                );
            }

            if (timeOfUse) {
                break;
            }

            await new Promise((res) => setTimeout(res, backoff));
        }

        if (timeOfUse.length > 0) {
            setEnergyTimeOfUse(timeOfUse[0]);
        } else {
            setEnergyTimeOfUse(null);
        }
        setTimeOfUseLoading(false);
    };

    useEffect(() => {
        if (!energyLocation) {
            return;
        }

        const { uuid } = energyLocation;
        getEnergyTimeOfUse(uuid);
    }, [energyLocation?.uuid]);

    return { energyTimeOfUse, loading: timeOfUseLoading, setEnergyTimeOfUse };
};
