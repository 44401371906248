import React, { useContext } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { EnergyLCPinType } from 'services/energy-load-controller-service';
import { ModalContext } from '../../../../../contexts';

const StyledScheduleRow = styled.div`
  margin: 8px 0;
`;

export const EnergyLoadControllerCopyModal = ({
  pins,
  handleCopy,
  pinToCopy
} : {
  pins: EnergyLCPinType[];
  handleCopy: (whichPins: boolean[], schedule: EnergyLCPinType['schedule']) => void;
  pinToCopy: EnergyLCPinType
}) => {
  const { hideModal } = useContext(ModalContext);

  const formMethods = useForm({
    mode: 'onChange',
  });

  const onSubmit = formMethods.handleSubmit((data) => {
    const whichPins = Object.keys(data).map((key) => !!data[key]);
    handleCopy(whichPins, pinToCopy.schedule);
    hideModal();
  });

  return (
    <FormContext {...formMethods}>
      <Form onSubmit={onSubmit}>
        {
          pins.map((pin, index) => {
            return (
              <StyledScheduleRow key={index}>
                <Controller
                  as={(
                    <Checkbox
                      key={index + 1}
                      label={`${index + 1}. ${pin.name ||'Equipment'}`}
                      />
                    )}
                  onChange={([, data]) => data.checked}
                  name={`pin-${index+1}`}
                  key={index+1}
                  />
              </StyledScheduleRow>
            )
          })
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px', marginTop: '16px' }}>
          <Button type="submit" color="green" fluid>Confirm</Button>
        </div>
      </Form>
    </FormContext>
  );
};

export default EnergyLoadControllerCopyModal;