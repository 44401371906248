import { useEffect, useState } from 'react';

import { EquipmentType } from '../../../../services/energy-device-service';
import { MonitoringLocation } from '../../../../services/energy-location-service';
import useEnergyDeviceService from './useEnergyDeviceService';

export const useMonitoringEquipments = (
    monitoringLocation: MonitoringLocation | null,
) => {
    const { getEquipment } = useEnergyDeviceService();

    const [equipments, setEquipments] = useState<EquipmentType[] | null>(null);

    const getMonitoringEquipments = async (monitoringLocationUUID: string) => {
        const equipments = await getEquipment(monitoringLocationUUID);

        setEquipments(equipments);
    };

    useEffect(() => {
        if (!monitoringLocation) {
            return;
        }

        const { uuid } = monitoringLocation;

        getMonitoringEquipments(uuid);
    }, [monitoringLocation]);

    return { equipments, setEquipments };
};
