import { Axios } from '.';
import { BaseModel } from './';

export interface DeviceModel extends BaseModel {
    serialNumber: string;
    deviceType: 'dragino' | 'thermabeta';
}

export class DeviceService {
    public static async browse(token: string) {
        const { data: client } = await Axios.request().get(`/device`, {
            headers: { authorization: token },
        });
        return client.data;
    }

    public static async findBySerialNumber(
        serialNumber: string,
        token: string,
    ): Promise<DeviceModel> {
        const { data: device } = await Axios.request().get(
            `/device/${serialNumber}`,
            {
                headers: { authorization: token },
            },
        );
        return device.data;
    }

    public static async add(
        serialNumber: string,
        deviceType: string,
        token: string,
    ): Promise<DeviceModel> {
        const { data: device } = await Axios.request().post(
            `/device`,
            {
                deviceType,
                serialNumber,
            },
            {
                headers: { authorization: token },
            },
        );

        return device.data;
    }

    public static async delete(uuid: string, token: string) {
        const { data: device } = await Axios.request().delete(
            `/device/${uuid}`,
            {
                headers: { authorization: token },
            },
        );
        return device.data;
    }
}
